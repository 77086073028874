import {
  Component, OnInit, AfterViewInit, ViewChild, OnDestroy, NgZone
} from '@angular/core';
import { AgmMap } from '@agm/core';
import { PosicionesService } from '../../posiciones/posiciones.service';
import { MapsService } from '../maps.service';
import { LoginService } from '../../login/login.service';
import { Utils } from '../../utils/utils';
import { Config } from '../../utils/config';
import { AreasService } from '../../areas/areas.service';
import { AreaTipoModel } from '../../areas/modelos/area-tipo-model';
import { AreaMarker } from '../modelos/area-marker';
import { MovilMarker } from '../modelos/movil-marker';
import { RecursosService } from '../../recursos/recursos.service';
import { AreaModel } from 'src/app/areas/modelos/area-model';
import { UsuarioModel } from '../../login/modelos/usuario-model';
import { MovilModel } from '../../recursos/modelos/movil-model';
import { PosicionModel } from '../../posiciones/modelos/posicion-model';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

declare var google: any;
declare var MarkerClusterer: any;

enum Animation {
  BOUNCE = 1,
  DROP = 2
}

@Component({
  selector: 'app-google-map-seg',
  templateUrl: './google-map-seg.component.html',
  styleUrls: ['./google-map-seg.component.css']
})

export class GoogleMapSegComponent implements OnInit, OnDestroy, AfterViewInit {
  public static movilesSeguimiento = new Map<number, MovilModel>();
  @ViewChild('AgmMap') AgmMap: AgmMap;
  @ViewChild('search', { static: false }) search;

  private map: any; // El mapa
  protected geoCoder: any; // Para busquedas sobre el mapa
  private traficLayer: any; // Para ver el estado del tráfico

  private INTERVAL_POS_MOVILES = 10000; // Intervalo de refresco posiciones móviles

  // Usuario actual
  private usuActual: UsuarioModel;

  // Variables para los móviles
  private moviles: MovilModel[] = [];
  private updatePos = false;

  // Variables para posiciones de moviles
  private timerMoviles = null;
  private markersMoviles: MovilMarker[] = [];
  private markersMovilesIndex = new Map<number, number>();

  // Variables para las areas
  private markersAreas: AreaMarker[] = [];
  private markersAreasIndex = new Map<number, number>();
  private tiposAreas: AreaTipoModel[] = [];
  private infoArea: any = null;

  // Variables para el mapa
  public trafic = Config.getTraficMap(false);

  // Cluster para areas
  private clusterAreas: any;

  // Configuración por defecto del mapa
  private mapType = 'roadmap';
  private zoom = environment.zoom;
  private center = {
    lat: environment.lat,
    lng: environment.lng
  };

  constructor(
    private ngZone: NgZone,
    private posicionesService: PosicionesService,
    private mapsService: MapsService,
    private loginService: LoginService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.usuActual = this.loginService.getUser();
    // Obtengo el último encuadre, para ello recupero el último centro y el zoom
    this.center = Config.getCenterMap(this.center);
    this.zoom = Config.getZoomMap(this.zoom);
  }

  ngOnDestroy(): void {
    // finalizo temporizadores
    if (this.timerMoviles !== undefined && this.timerMoviles !== null) {
      clearInterval(this.timerMoviles);
    }
    if (this.markersMoviles !== undefined) {
      // Destruyo objetos del mapa
      this.markersMoviles.forEach(m => {
        m.setVisible(false);
      });
    }
  }

  ngAfterViewInit(): void {
    this.AgmMap.mapReady.subscribe(map => {
      // Obtengo el manejador del mapa
      this.map = map;
      // Quito los puntos de interes del mapa
      this.map.setOptions({
        zoomControlOptions: {
          style: google.maps.ControlPosition.small,
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        styles: [ // Oculto los puntos de interes
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      });
      // Preparo la búsqueda de direcciones en el mapa
      this.geoCoder = new google.maps.Geocoder();
      // Restrinjo el área de busqueda preferido
      const sw = new google.maps.LatLng(environment.latSW, environment.lngSW);
      const ne = new google.maps.LatLng(environment.latNE, environment.lngNE);
      const searchBounds = new google.maps.LatLngBounds(sw, ne);
      const options = {
        bounds: searchBounds,
        types: ['geocode'],
        componentRestrictions: { country: 'es' }
      };
      const autocomplete = new google.maps.places.Autocomplete(
        this.search.nativeElement, options);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          if (place.geometry !== undefined && place.geometry !== null) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            this.setCenter(lat, lng);
            this.search.nativeElement.value = '';
          }
        });
      });
      // Creo la capa de tráfico para poder activar o desactivar
      this.traficLayer = new google.maps.TrafficLayer();
      this.traficLayer.setMap(this.trafic ? this.map : null)
      // Cluster para las areas
      this.clusterAreas = new MarkerClusterer(this.map, null, {
        imagePath: 'assets/images/mapa/cluster/m',
        maxZoom: 18,
        ignoreHidden: true
      });
      // Eventos a los que se subscribe el mapa
      this.subscribeCenterMovil();
      this.subscribeCenterArea();
      this.subscribeShowMoviles();
      this.subscribeShowAreas();
      this.subscribeAddAreas();
      this.subscribeAddArea();
      this.subscribeUpdateArea();
      this.subscribeRemoveArea();
      this.subscribeAddMovilSeguimiento();
      this.subscribeRemoveMovilSeguimiento();
      this.subscribeVerMovilSeguimiento();
      // Pinto las posiciones de los móviles y las areas
      this.showMoviles();
      this.showAreas();
    });
  }

  // Muestra los móviles
  showMoviles() {
    this.markersMovilesIndex = new Map<number, number>();
    this.markersMoviles = [];
    this.timerMoviles = setInterval(() => {
      // Espero a que se hayan cargado todos los móviles
      if (RecursosService.movilesOk) {
        clearInterval(this.timerMoviles);
        // Recupero los móviles en seguimiento
        const movSeg = Config.getMovilesSeguimiento([{}]);
        movSeg.forEach(mov => {
          const movil = RecursosService.moviles.get(mov.id);
          if (movil !== undefined) {
            this.moviles.push(movil);
            GoogleMapSegComponent.movilesSeguimiento.set(movil.id, movil);
          }
        });
        // Timer que actualiza las posiciones de los móviles sobre el mapa
        this.updateLastPositionMovil(null);
        this.timerMoviles = setInterval(() => {
          this.updateLastPositionMovil(null);
        }, this.INTERVAL_POS_MOVILES);
      }
    }, 1000);
  }

  // Actualiza la última posición de los móviles sobre el mapa
  updateLastPositionMovil(movilAdd: MovilModel) {
    try {
      while (this.updatePos) { }
      this.updatePos = true;
      const filter = Config.getMovilesFilter([{ id: -1 }]);
      // Recupero el subfiltro de móviles (-1 = todos mientras no se establece un subfiltro)
      const subFilter = Config.getMovilesSubFilter([{ id: -1 }]);
      this.moviles.forEach(movil => {
        this.posicionesService.getUltimaPosicion(movil.id).then(pos => {
          // Meto las posiciones en la tabla hash
          let index = 0;
          const i = this.markersMovilesIndex.get(pos.movil.id);
          if (i === undefined) {
            const marker = new MovilMarker(pos, this.map, this.mapsService, true);
            index = this.markersMoviles.push(marker) - 1;
            let visible = Boolean(filter.find(s => s.id === pos.movil.id) !== undefined ||
              filter.find(s => s.id === -1));
            if (visible) {
              visible = Boolean(subFilter.find(s => s.id === pos.movil.id) !== undefined ||
                subFilter.find(s => s.id === -1));
            }
            marker.setVisible(visible);
          } else {
            index = i;
            let visible = Boolean(filter.find(s => s.id === pos.movil.id) !== undefined ||
              filter.find(s => s.id === -1));
            if (visible) {
              visible = Boolean(subFilter.find(s => s.id === pos.movil.id) !== undefined ||
                subFilter.find(s => s.id === -1));
            }
            this.markersMoviles[index].setVisible(visible);
            // Con esto pinto la posición sin más
            // this.markersMoviles[index].setPosicion(pos);
            // Con esto se anima el desplazamiento de la posición
            this.animateMovilMarker(movil, this.markersMoviles[index], pos);
          }
          this.markersMovilesIndex.set(pos.movil.id, index);
          // Si se ha añadido un móvil centro el mapa sobre su posición
          if (movilAdd !== null && movilAdd.id === pos.movil.id) {
            this.setCenter(pos.lat, pos.lng);
          }
        },
          err => {
            console.log(err);
          });
      });
    } finally {
      this.updatePos = false;
    }
  }

  animateMovilMarker(movil: MovilModel, marker: MovilMarker, pos: PosicionModel) {
    const deltaLat = (pos.lat - marker.getPosicion().lat) / 10;
    const deltaLng = (pos.lng - marker.getPosicion().lng) / 10;
    let mapsBounds = this.map.getBounds();
    if (deltaLat !== 0 || deltaLng !== 0) {
      let j = 0;
      const auxPos = { ...pos };
      const timer = setInterval(() => {
        if (j < 10) {
          auxPos.lat = marker.getPosicion().lat + deltaLat;
          auxPos.lng = marker.getPosicion().lng + deltaLng;
          marker.setPosicion(auxPos);
          // Si solo hay un móvil en seguimiento intento que siempre esté en pantalla
          if (this.moviles.length === 1 && !mapsBounds.contains(new google.maps.LatLng(auxPos.lat, auxPos.lng))) {
            this.map.panTo(new google.maps.LatLng(auxPos.lat, auxPos.lng));
            mapsBounds = this.map.getBounds();
          }
          j++;
        } else {
          // Si solo hay un móvil en seguimiento intento que siempre esté en pantalla
          if (this.moviles.length === 1 && !mapsBounds.contains(new google.maps.LatLng(pos.lat, pos.lng))) {
            this.map.panTo(new google.maps.LatLng(pos.lat, pos.lng));
          }
          marker.setPosicion(pos);
          clearInterval(timer);
        }
      }, 100);
    } else {
      // Si solo hay un móvil en seguimiento intento que siempre esté en pantalla
      if (this.moviles.length === 1 && !mapsBounds.contains(new google.maps.LatLng(pos.lat, pos.lng))) {
        this.map.panTo(new google.maps.LatLng(pos.lat, pos.lng));
      }
      marker.setPosicion(pos);
    }
  }

  // Permite mostrar u ocultar las posiciones de los moviles
  subscribeShowMoviles(): void {
    this.mapsService.showMovilesEmiter.subscribe(show => {
      // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
      const filter = Config.getMovilesFilter([{ id: -1 }]);
      // Recupero el subfiltro de móviles (-1 = todos mientras no se establece un subfiltro)
      const subFilter = Config.getMovilesSubFilter([{ id: -1 }]);
      this.markersMoviles.forEach(m => {
        if (show) {
          let visible = filter.find(s => s.id === m.getPosicion().movil.id) !== undefined ||
            filter.find(s => s.id === -1);
          if (visible) {
            visible = subFilter.find(s => s.id === m.getPosicion().movil.id) !== undefined ||
              subFilter.find(s => s.id === -1);
          }
          m.setVisible(visible);
        } else {
          m.setVisible(false);
        }
      });
    });
  }

  // Permite centrar el mapa en la posición de un móvil
  subscribeCenterMovil(): void {
    this.mapsService.centerMovilEmiter.subscribe(idMovil => {
      const index = this.markersMovilesIndex.get(idMovil);
      if (index !== undefined) {
        // Hago que el marcador salte durante 2 segundos
        this.setCenter(this.markersMoviles[index].getPosicion().lat, this.markersMoviles[index].getPosicion().lng);
        this.markersMoviles[index].getMarker().setAnimation(Animation.BOUNCE);
        this.markersMoviles[index].getMarker().setZIndex(999);
        setTimeout(() => {
          this.markersMoviles[index].getMarker().setAnimation(null);
        }, 2000);
      }
    });
  }

  // Permite centrar el mapa en la posición de un área
  subscribeCenterArea(): void {
    this.mapsService.centerAreaEmiter.subscribe(area => {
      const index = this.markersAreasIndex.get(area);
      if (index !== undefined) {
        // Hago que el marcador salte durante 2 segundos
        this.setCenter(this.markersAreas[index].getArea().lat, this.markersAreas[index].getArea().lng);
        this.markersAreas[index].getMarker().setAnimation(Animation.BOUNCE);
        this.markersAreas[index].getMarker().setZIndex(999);
        setTimeout(() => {
          this.markersAreas[index].getMarker().setAnimation(null);
        }, 2000);
      }
    });
  }

  // Muestra las areas
  showAreas(): void {
    this.markersAreas = [];
    this.markersAreasIndex = new Map<number, number>();
  }

  // Permite mostrar añadir areas
  subscribeAddAreas(): void {
    this.mapsService.addAreasEmiter.subscribe(areas => {
      areas.forEach(area => {
        if (area.fBaja === null) {
          this.addArea(area);
        }
      });
      this.clusterAreas.repaint();
    });
  }

  addArea(area: AreaModel) {
    // Añado el area a la lista y al cluster si está visible
    const am = new AreaMarker(area, this.map, false);
    this.markersAreas.push(am);
    this.markersAreasIndex.set(area.id, this.markersAreas.length - 1);
    if (area.tipo.visible) {
      this.clusterAreas.addMarker(am.getMarker(), true);
    }
    // Creo el listener para cuando se pincha sobre el area
    google.maps.event.addListener(am.getMarker(), 'click', (event => {
      if (this.infoArea !== null) {
        this.infoArea.close();
      }
      this.infoArea = new google.maps.InfoWindow({
        content:
          '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
          '<b>' + am.getArea().nombre + '</b>' + '<hr style = "color: navy;" />' +
          am.getArea().tipo.nombre +
          '</div>'
      });
      this.infoArea.open(this.map, am.getMarker());
      this.mapsService.clickEventEmit({
        lat: am.getArea().lat,
        lng: am.getArea().lng,
        elemId: am.getArea().id,
        elemNombre: am.getArea().nombre
      });
    }));
  }

  // Permite añadir un móvil a la lista de móviles
  subscribeAddMovilSeguimiento(): void {
    this.mapsService.addMovilSeguimientoEmiter.subscribe(movil => {
      let found = false;
      this.moviles.forEach(mov => {
        if (mov.id === movil.id) {
          found = true;
        }
      });
      if (!found) {
        this.moviles.push(movil);
        this.updateLastPositionMovil(movil);
        GoogleMapSegComponent.movilesSeguimiento.set(movil.id, movil);
        // Almaceno los códigos de móvil en seguimiento
        const moviles: any[] = [];
        this.moviles.forEach(mov => {
          moviles.push({ id: mov.id });
        });
        Config.storeMovilesSeguimiento(moviles);
        this.toastrService.info('Vehículo añadido a seguimiento', 'Información', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
      }
    });
  }

  // Permite quitar un móvil de la lista de móviles
  subscribeRemoveMovilSeguimiento(): void {
    this.mapsService.removeMovilSeguimientoEmiter.subscribe(movil => {
      try {
        while (this.updatePos) { }
        this.updatePos = true;
        // Primero elimino el móvil de la lista de móviles
        let i = 0;
        this.moviles.forEach(mov => {
          if (mov.id === movil.id) {
            this.moviles.splice(i, 1);
            GoogleMapSegComponent.movilesSeguimiento.delete(mov.id);
            // Almaceno los códigos de móvil en seguimiento
            const moviles: any[] = [];
            this.moviles.forEach(mov2 => {
              moviles.push({ id: mov2.id });
            });
            Config.storeMovilesSeguimiento(moviles);
            this.toastrService.info('Vehículo fuera de seguimiento', 'Información', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          }
          i++;
        });
        // Ahora elimino el marcador de la lista de marcadores
        i = 0;
        this.markersMoviles.forEach(marker => {
          if (marker.getPosicion().movil.id === movil.id) {
            marker.setVisible(false);
            this.markersMoviles.splice(i, 1);
          }
          i++;
        });
        // Vuelvo a generar la tabla hash porque las posiciones dentro del
        // array de marcadores han cambiado al borrar un elemento
        i = 0;
        this.markersMovilesIndex.clear();
        this.markersMoviles.forEach(marker => {
          this.markersMovilesIndex.set(marker.getPosicion().movil.id, i);
          i++;
        });
      } finally {
        this.updatePos = false;
      }
    });
  }

  // Centrar sobre la posición de un móvil
  subscribeVerMovilSeguimiento(): void {
    this.mapsService.verMovilSeguimientoEmiter.subscribe(movil => {
      const i = this.markersMovilesIndex.get(movil.id);
      if (i !== undefined) {
        this.setCenter(this.markersMoviles[i].getPosicion().lat,
          this.markersMoviles[i].getPosicion().lng);
        this.markersMoviles[i].getMarker().setAnimation(Animation.BOUNCE);
        this.markersMoviles[i].getMarker().setZIndex(999);
        setTimeout(() => {
          this.markersMoviles[i].getMarker().setAnimation(null);
        }, 2000);
      }
    });
  }

  // Permite añadir un área al mapa
  subscribeAddArea(): void {
    this.mapsService.addAreaEmiter.subscribe(area => {
      this.addArea(area);
      this.clusterAreas.repaint();
    });
  }

  // Permite añadir un área al mapa
  subscribeUpdateArea(): void {
    this.mapsService.updateAreaEmiter.subscribe(usu => {
      this.removeArea(usu);
      this.addArea(usu);
      this.clusterAreas.repaint();
    });
  }

  // Permite borrar un área del mapa
  subscribeRemoveArea(): void {
    this.mapsService.removeAreaEmiter.subscribe(area => {
      this.removeArea(area);
      this.clusterAreas.repaint();
    });
  }

  removeArea(area: AreaModel) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.markersAreas.length; i++) {
      if (this.markersAreas[i].getArea().id === area.id) {
        this.clusterAreas.removeMarker(this.markersAreas[i].marker);
        this.markersAreas[i].circle.setMap(null);
        this.markersAreas.splice(i, 1);
        break;
      }
    }
  }

  // Permite mostrar u ocultar las areas
  subscribeShowAreas(): void {
    this.mapsService.showAreasEmiter.subscribe(show => {
      // Recupero el filtro de areas
      const filter = Config.getAreasFilter([{}]);
      // Meto los tipos en la tabla hash
      this.tiposAreas.forEach(tipo => {
        tipo.visible = filter.find(s => s.id === tipo.id) !== undefined;
        AreasService.areasTipos.set(tipo.id, tipo);
      });
      this.clusterAreas.clearMarkers();
      this.markersAreas.forEach(area => {
        const model = AreasService.areasTipos.get(area.getArea().tipo.id);
        if (model !== undefined && model != null && model.visible) {
          this.clusterAreas.addMarker(area.getMarker(), true);
          area.circle.setVisible(true);
        } else {
          area.circle.setVisible(false);
        }
      });
      this.clusterAreas.repaint();
    });
  }

  // Activa o desactiva la capa de estado del tráfico
  onChangeTrafic(event: any) {
    this.traficLayer.setMap(this.trafic ? this.map : null);
    Config.storeTraficMap(this.trafic);
  }

  // Establece el centro del mapa
  setCenter(lat: number, lng: number) {
    this.center.lat = lat;
    this.center.lng = lng;
    if (this.map !== undefined && this.map !== null) {
      this.map.setCenter(new google.maps.LatLng(lat, lng));
    }
  }

  getCenter() {
    return this.center;
  }

  // Establece el nivel de zoom
  setZoom(zoom: number) {
    this.zoom = zoom;
  }

  getZoom() {
    return this.zoom;
  }

  setMapType(type: string) {
    this.mapType = type;
  }

  getType() {
    return this.mapType;
  }

}


