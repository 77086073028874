import { Component, ViewEncapsulation, OnInit, HostListener } from '@angular/core';
import { MapsService } from './maps/maps.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {
  title = 'Movisat - AmbuSAT';

  constructor(private mapService: MapsService) {
  }

  ngOnInit(): void {
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  @HostListener('keydown.escape')
  fn() {
    this.mapService.escapeKeyPressed();
  }

}
