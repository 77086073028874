import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MapsService } from '../maps/maps.service';
import { PosicionesService } from '../posiciones/posiciones.service';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { MovilModel } from '../recursos/modelos/movil-model';
import { SensoresService } from '../sensores/sensores.service';
import { SensorModel } from '../sensores/modelos/sensor-model';
import { Config } from '../utils/config';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { PosicionModel } from '../posiciones/modelos/posicion-model';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../utils/utils';

declare var google: any;

@Component({
  selector: 'app-reproductor',
  templateUrl: './reproductor.component.html',
  styleUrls: ['./reproductor.component.css']
})

export class ReproductorComponent implements OnInit, AfterViewInit {
  @ViewChild('from') from: jqxDateTimeInputComponent;
  @ViewChild('to') to: jqxDateTimeInputComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('infoRuta') infoRuta: any;

  public movil: MovilModel;
  public fechaIni: Date;
  public fechaFin: Date;
  private posiciones: PosicionModel[] = [];
  private sensores: SensorModel[] = [];
  private paused = false;
  private xmlDocument: XMLDocument;

  constructor(
    private mapService: MapsService,
    private toastrService: ToastrService,
    private posicionesService: PosicionesService,
    private sensoresService: SensoresService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  getReproPos(): any {
    const footer = document.getElementById('footerLayout').clientHeight;
    return {
      x: 0,
      y: document.body.clientHeight - 182 - footer
    };
  }

  init(movil: MovilModel) {
    this.movil = movil;
    this.fechaIni = new Date();
    this.fechaFin = new Date();
    const posIni: PosicionModel = PosicionesService.lastPos.get(movil.id);
    if (posIni !== null) {
      this.fechaIni = new Date(posIni.fecha);
      this.fechaFin = new Date(posIni.fecha);
    }
    this.fechaIni.setHours(0);
    this.fechaIni.setMinutes(0);
    this.fechaIni.setSeconds(0);
    this.fechaFin.setHours(23);
    this.fechaFin.setMinutes(59);
    this.fechaFin.setSeconds(59);
  }

  onPlay(step2step: boolean) {
    if (!this.paused) {
      // Pido posiciones
      this.loader.open();
      this.posiciones = [];
      this.posicionesService.getPosicionesMovil(
        this.movil.id, this.from.getDate(), this.to.getDate()).then(
          pos => {
            this.posiciones = pos;
            if (this.posiciones !== undefined && this.posiciones.length > 0) {
              // Pongo las hora de inicio y fin reales de la ruta
              this.from.setDate(new Date(this.posiciones[0].fecha));
              this.to.setDate(new Date(this.posiciones[this.posiciones.length - 1].fecha));
              // Pido sensores
              this.sensores = [];
              // Compruebo si se require algún sensor
              const select = Config.getSensorFilter([{}]);
              if (select.length > 0) {
                this.sensoresService.getSensoresMovil(
                  this.movil.id, this.from.getDate(), this.to.getDate()).then(
                    response => {
                      if (response !== undefined) {
                        // Me quedo sólo con los sensores filtrados
                        if (select.length > 0) {
                          response.forEach(sensor => {
                            if (select.find(s => s.id === sensor.sensor)) {
                              this.sensores.push(sensor);
                            }
                          });
                        }
                      }
                      // Mando pintar la ruta
                      this.loader.close();
                      this.mapService.showRoute(this.movil, this.from.getDate(),
                        this.to.getDate(), step2step, this.movil.icono, this.posiciones,
                        this.sensores, this.distanceRoute(this.posiciones));
                    },
                    err => {
                      this.loader.close();
                      console.log(err);
                    });
              } else {
                // Mando pintar la ruta
                this.loader.close();
                this.mapService.showRoute(this.movil, this.from.getDate(),
                  this.to.getDate(), step2step, this.movil.icono, this.posiciones,
                  this.sensores, this.distanceRoute(this.posiciones));
              }
            } else {
              this.loader.close();
              this.toastrService.warning('No hay posiciones en ese rango de fechas', 'ATENCIÓN', {
                timeOut: 2000,
                positionClass: 'toast-top-center'
              });
            }
          },
          err => {
            this.loader.close();
            console.log(err);
          });
    } else {
      this.paused = false;
      this.mapService.showRoute(this.movil, this.from.getDate(),
        this.to.getDate(), step2step, this.movil.icono, this.posiciones,
        this.sensores, this.distanceRoute(this.posiciones));
    }
  }

  // Calcula la distancia recorrida en una ruta (en metros)
  distanceRoute(posiciones: PosicionModel[]) {
    let distance = 0;
    let prevPos: PosicionModel = null;
    posiciones.forEach(pos => {
      if (prevPos != null) {
        distance += google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(prevPos.lat, prevPos.lng),
          new google.maps.LatLng(pos.lat, pos.lng)
        );
      }
      prevPos = pos;
    });
    if (posiciones.length > 1) {
      let difSeg = (new Date(posiciones[posiciones.length - 1].fecha).getTime() -
        new Date(posiciones[0].fecha).getTime()) / 1000;
      const h = Math.trunc(difSeg / 3600);
      difSeg -= h * 3600;
      const m = Math.trunc(difSeg / 60);
      let tiempo = '';
      if (h > 0) {
        tiempo += h + (m > 0 ? ' horas y ' : 'horas');
      }
      if (m > 0) {
        tiempo += m + ' min.';
      }
      this.infoRuta.nativeElement.innerHTML = '<sub>' + ((distance / 1000.0).toFixed(2) +
        ' Km, ' + tiempo).replace('.', ',') + '</sub>';
    }
    return distance;
  }

  onPause() {
    if (!this.paused) {
      this.paused = true;
      this.mapService.pauseRoute(this.movil);
    } else {
      this.onPlay(true);
    }
  }

  onStop() {
    this.paused = false;
    this.mapService.removeRoute(this.movil);
  }

  onChangeSpeed(event: any) {
    this.mapService.setRouteInterval(1000 - event.args.value * 10);
  }

  onClose() {
    this.mapService.removeRoute(this.movil);
    // this.mapService.showMoviles(true);
  }

  onKml() {
    // Pido posiciones
    this.loader.open();
    this.posiciones = [];
    this.posicionesService.getPosicionesMovil(
      this.movil.id, this.from.getDate(), this.to.getDate()).then(
        pos => {
          this.posiciones = pos;
          this.loader.close();
          if (this.posiciones !== undefined && this.posiciones.length > 0) {
            this.createAndDownloadKML();
            this.onPlay(false);
          } else {
            this.toastrService.warning('No hay posiciones en ese rango de fechas', 'ATENCIÓN', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          }
        },
        err => {
          this.loader.close();
          console.log(err);
        });
  }

  createAndDownloadKML(): void {
    const textXML = this.createKMLFileFromRoute();
    this.downloadKml(Utils.formatDateAMDhms(new Date()) + '_ruta.kml', textXML);
  }

  downloadKml(filename, xmlDocument) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(xmlDocument));
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  // createKMLFileFromRoute(): string {
  //   this.xmlDocument = document.implementation.createDocument('', '', null);
  //   const kmlNode = this.xmlDocument.createElement('kml');
  //   kmlNode.setAttribute('xmlns', 'http://www.opengis.net/kml/2.2');
  //   const documentNode = this.xmlDocument.createElement('Document');
  //   kmlNode.appendChild(documentNode);
  //   this.xmlDocument.appendChild(kmlNode);
  //   this.posiciones.forEach((pos, i) => {
  //     documentNode.appendChild(this.createKMLPointNode(i, pos.lat, pos.lng));
  //   });
  //   return this.xmlDocumentToString();
  // }

  // createKMLPointNode(name, lat, lng): HTMLElement {
  //   const placemarkNode = this.xmlDocument.createElement('Placemark');
  //   const nameNode = this.xmlDocument.createElement('name');
  //   nameNode.innerHTML = name;
  //   const descriptionNode = this.xmlDocument.createElement('description');
  //   const pointNode = this.xmlDocument.createElement('Point');
  //   const coordinatesNode = this.xmlDocument.createElement('coordinates');
  //   coordinatesNode.innerHTML = `${lng},${lat}`;
  //   placemarkNode.appendChild(nameNode);
  //   placemarkNode.appendChild(descriptionNode);
  //   placemarkNode.appendChild(pointNode);
  //   pointNode.appendChild(coordinatesNode);
  //   return placemarkNode;
  // }

  createKMLFileFromRoute(): string {
    this.xmlDocument = document.implementation.createDocument('', '', null);
    const kmlNode = this.xmlDocument.createElement('kml');
    kmlNode.setAttribute('xmlns', 'http://www.opengis.net/kml/2.2');
    const documentNode = this.xmlDocument.createElement('Document');
    let nameNode = this.xmlDocument.createElement('name');
    nameNode.innerHTML = 'Ruta del vehículo ' + this.movil.nombre + ' (' + this.movil.matricula + ')';
    documentNode.appendChild(nameNode);
    let descriptionNode = this.xmlDocument.createElement('description');
    descriptionNode.innerHTML = 'Desde ' + Utils.formatDateTime(this.from.getDate(), true) +
      ' Hasta ' + Utils.formatDateTime(this.to.getDate(), true);
    documentNode.appendChild(descriptionNode);
    const styleNode = this.xmlDocument.createElement('Style');
    styleNode.setAttribute('id', 'lineColor');
    const lineStyleNode = this.xmlDocument.createElement('LineStyle');
    const colorNode = this.xmlDocument.createElement('color');
    colorNode.innerHTML = 'ff00aaff';
    lineStyleNode.appendChild(colorNode);
    const widthNode = this.xmlDocument.createElement('width');
    widthNode.innerHTML = '8';
    lineStyleNode.appendChild(widthNode);
    styleNode.appendChild(lineStyleNode);
    documentNode.appendChild(styleNode);
    const placemarkNode = this.xmlDocument.createElement('Placemark');
    nameNode = this.xmlDocument.createElement('name');
    nameNode.innerHTML = 'Ruta del vehículo ' + this.movil.nombre + ' (' + this.movil.matricula + ')';
    placemarkNode.appendChild(nameNode);
    descriptionNode = this.xmlDocument.createElement('description');
    descriptionNode.innerHTML = 'Desde ' + Utils.formatDateTime(this.from.getDate(), true) +
      ' Hasta ' + Utils.formatDateTime(this.to.getDate(), true);
    placemarkNode.appendChild(descriptionNode);
    const styleUrlNode = this.xmlDocument.createElement('styleUrl');
    styleUrlNode.innerHTML = '#lineColor';
    placemarkNode.appendChild(styleUrlNode);
    const lineStringNode = this.xmlDocument.createElement('LineString');
    const tessellateNode = this.xmlDocument.createElement('tessellate');
    tessellateNode.innerHTML = '1';
    lineStringNode.appendChild(tessellateNode);
    const altitudeModeNode = this.xmlDocument.createElement('altitudeMode');
    altitudeModeNode.innerHTML = 'absolute';
    lineStringNode.appendChild(altitudeModeNode);
    const coordinatesModeNode = this.xmlDocument.createElement('coordinates');
    coordinatesModeNode.innerHTML = '';
    this.posiciones.forEach(pos => {
      coordinatesModeNode.innerHTML += (pos.lng + ',' + pos.lat + ',' + pos.altura + '\r\n');
    });
    lineStringNode.appendChild(coordinatesModeNode);
    placemarkNode.appendChild(lineStringNode);
    documentNode.appendChild(placemarkNode);
    kmlNode.appendChild(documentNode);
    this.xmlDocument.appendChild(kmlNode);
    return this.xmlDocumentToString();
  }

  createKMLPointNode(name, lat, lng): HTMLElement {
    const placemarkNode = this.xmlDocument.createElement('Placemark');
    const nameNode = this.xmlDocument.createElement('name');
    nameNode.innerHTML = name;
    const descriptionNode = this.xmlDocument.createElement('description');
    const pointNode = this.xmlDocument.createElement('Point');
    const coordinatesNode = this.xmlDocument.createElement('coordinates');
    coordinatesNode.innerHTML = `${lng},${lat}`;
    placemarkNode.appendChild(nameNode);
    placemarkNode.appendChild(descriptionNode);
    placemarkNode.appendChild(pointNode);
    pointNode.appendChild(coordinatesNode);
    return placemarkNode;
  }

  xmlDocumentToString(): string {
    let textXML = new XMLSerializer().serializeToString(this.xmlDocument);
    textXML = '<?xml version="1.0" encoding="UTF-8"?>' + textXML;
    return textXML;
  }

}
