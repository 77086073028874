<div class="main" id="mainLayout">
    <div class="header" id="headerLayout">
        <div class="header" style="float: left;">
            <img width="15%" style="float: left;" src="assets/images/empresa/movisat.png">
            <img width="15%" style="float: left;" src="assets/images/empresa/ambusat.png">
            <img width="15%" style="float: right; margin-right: 30px;" src="assets/images/empresa/gsc.png">
            <img width="10%" style="float: right; margin-right: 10px;" src="assets/images/empresa/cliente.png">
        </div>
    </div>
    <div class="body" id="bodyLayout">
        <div class="centerPanel" id="centerLayout">
            <div class="map" id="mapPanel" style="width: 100%;">
                <app-google-map-visor></app-google-map-visor>
            </div>
        </div>
    </div>
    <div class="footer" id="footerLayout">
        <div style="float: right; margin-right: 10px; ">
            <sub>Powered by <a href="http://www.movisat.com/ ">
                    &copy; MOVISAT TecnoMovilidad</a> Todos los derechos reservados
            </sub>
        </div>
    </div>
</div>