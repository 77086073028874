import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { MapsService } from '../maps/maps.service';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { UsuarioModel } from '../login/modelos/usuario-model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  @ViewChild('tabs') tabs: jqxTabsComponent;

  public user: UsuarioModel = null;
  public imagenUsuario = '';

  constructor(
    private loginService: LoginService,
    private router: Router,
    private mapsService: MapsService
  ) { }

  ngOnInit(): void {
    this.user = this.loginService.getUser();
    if (this.user === null) {
      this.router.navigateByUrl('/');
    } else {
      this.imagenUsuario = 'data:image/png;base64,' + this.user.imagen;
      this.subscribeVerCartoSeguimiento();
    }
  }

  onCloseSession() {
    this.loginService.removeUser();
    this.router.navigateByUrl('/');
    setTimeout(() => {
      location.reload();
    }, 1000);
  }

  onUser() {
    this.mapsService.editUserEventEmit(this.user);
  }

  // Centrar y activar la pestaña de cartografía de seguimiento
  // Esto ocurre cuando se pulsa el botón de ver >>
  subscribeVerCartoSeguimiento(): void {
    this.mapsService.verMovilSeguimientoEmiter.subscribe(movil => {
      this.tabs.selectedItem(1);
    });
  }

  // Permite avisar que ha cambiado la pestaña de cartografía
  onSelecTab(event: any) {
    this.mapsService.changeTabsCarto(event.args.item);
  }

}
