import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PosicionModel } from '../posiciones/modelos/posicion-model';
import { environment } from '../../environments/environment';
import { Utils } from '../utils/utils';
import { RecursosService } from '../recursos/recursos.service';

@Injectable({
  providedIn: 'root'
})
export class PosicionesService {
  public static lastPos = new Map<number, PosicionModel>();

  constructor(private http: HttpClient) {
  }

  getUltimasPosiciones(empresa: number): Promise<PosicionModel[]> {
    const result: any = [];
    return this.http.get(environment.urlApi + '/api/posiciones/ultimas/' + empresa)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach(pos => {
          const movil = RecursosService.moviles.get(pos.Movil);
          if (movil !== undefined && movil.gls !== 'AN') {
            const newPos = new PosicionModel(movil, new Date(pos.Fecha), pos.Lat, pos.Lng, pos.Velocidad,
              pos.Altura, pos.Rumbo * 2, pos.Estado, pos.Ignicion, pos.X, pos.Y, pos.IconoBase64,pos.InfoGeo
            )
            PosicionesService.lastPos.set(pos.Movil, newPos);
            result.push(newPos);
          }
        });
        return result;
      }, error => {
        console.log('getUltimasPosiciones()' + error);
      });
  }

  // Obtiene las ultimas posiciones con información geografica (la calle)
  getUltimasPosicionesV2(empresa: number): Promise<PosicionModel[]> {
    const result: any = [];
    return this.http.get(environment.urlApi + '/api/posiciones/ultimas/v2/' + empresa)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach(pos => {
          const movil = RecursosService.moviles.get(pos.Movil);
          if (movil !== undefined && movil.gls !== 'AN') {
            const newPos = new PosicionModel(movil, new Date(pos.Fecha), pos.Lat, pos.Lng, pos.Velocidad,
              pos.Altura, pos.Rumbo * 2, pos.Estado, pos.Ignicion, pos.X, pos.Y, pos.IconoBase64,pos.InfoGeo
            )
            PosicionesService.lastPos.set(pos.Movil, newPos);
            result.push(newPos);
          }
        });
        return result;
      }, error => {
        console.log('getUltimasPosicionesV2()' + error);
      });
  }

  getPosicionesMovil(movil: number, desde: Date, hasta: Date): Promise<PosicionModel[]> {
    const result: any = [];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    };
    const body = new URLSearchParams();
    body.set('movil', '' + movil);
    body.set('desde', Utils.formatDateTime(desde, false));
    body.set('hasta', Utils.formatDateTime(hasta, false));
    return this.http.post(environment.urlApi + '/api/posiciones/v2', body.toString(), httpOptions)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach(pos => {
          result.push(new PosicionModel(RecursosService.moviles.get(pos.Movil),
            new Date(pos.Fecha), pos.Lat, pos.Lng, pos.Velocidad, pos.Altura,
            pos.Rumbo * 2, false, false, pos.X, pos.Y, '', pos.Direccion
          ));
        });
        return result;
      }, error => {
        console.log('getPosicionesMovil()' + error);
      });
  }

  getUltimaPosicion(movil: number): Promise<PosicionModel> {
    let result: any = null;
    return this.http.get(environment.urlApi + '/api/posiciones/' + movil)
      .toPromise()
      .then(response => {
        const pos = JSON.parse(JSON.stringify(response));
        const mov = RecursosService.moviles.get(pos.Movil);
        if (mov !== undefined) {
          result = new PosicionModel(mov,
            new Date(pos.Fecha), pos.Lat, pos.Lng, pos.Velocidad, pos.Altura, pos.Rumbo * 2,
            mov.conectado, mov.conectado, pos.X, pos.Y, mov.icono
          );
        }
        return result;
      }, error => {
        console.log('getUltimaPosición()' + error);
      });
  }

}
