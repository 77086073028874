declare var google: any;

enum Animation {
    BOUNCE = 1,
    DROP = 2
}

export class BaseMarker {
    public marker: any;
    public map: any;

    constructor(text: string, lat: number, lng: number, icono: string, zIdx: number,
        map: any, drag: boolean) {
        this.map = map;
        this.marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            draggable: drag,
            clickable: true,
            icon: icono,
            title: text,
            visible: true,
            zIndex: zIdx
        });
        this.marker.setMap(map);
    }

    updatePosition(lat: number, lng: number) {
        this.marker.setPosition(new google.maps.LatLng(lat, lng));
    }

    getMarker(): any {
        return this.marker;
    }

    setVisible(visible: boolean) {
        this.marker.setMap(visible ? this.map : null);
    }

    setZIndex(index: number) {
        this.marker.setZIndex(index);
    }

    animate(milisec: number) {
        this.marker.setAnimation(Animation.BOUNCE);
        setTimeout(() => {
            this.marker.setAnimation(null);
        }, milisec);
    }

}
