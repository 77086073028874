export class EstadoMovilGestionModel {
    idMovil: number;
    estado: number;
    fechaEstado: Date;
    numPersonas: number;

    constructor(idMovil: number, estado: number, fechaEstado: Date, numPersonas: number) {
        this.idMovil = idMovil;
        this.estado = estado;
        this.fechaEstado = fechaEstado;
        this.numPersonas = numPersonas;
    }
}
