import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // La petición de token no se intercepta
        if (req.body && req.body.indexOf('grant_type') > -1) {
            return next.handle(req);
        }

        // Sólo las llamadas a la API de EcoSAT lleva este tipo de autenticación
        // if (req.url.indexOf('api/areas') < 0 && req.url.indexOf('api/moviles/asociar') < 0) {
        if (environment.urlApiGes.length < 1 || !req.url.startsWith(environment.urlApiGes)) {
            const request = req.clone({
                setHeaders: { Authorization: `Bearer ${sessionStorage.getItem('tokenGSC')}` }
            });

            return next.handle(request);
        }

        return next.handle(req);
    }
}
