export class SensorModel {
    movil: number;
    sensor: number;
    nombre: string;
    fecha: Date;
    lectura: any;
    icono?: string;
    lat: number;
    lng: number;

    constructor(movil: number, sensor: number, nombre: string, fecha: Date, lectura: any,
        icono: string, lat: number, lng: number) {
        this.movil = movil;
        this.sensor = sensor;
        this.nombre = nombre;
        this.fecha = fecha;
        this.lectura = lectura;
        this.icono = '/assets/images/sensores/' + sensor + '.png';
        this.lat = lat;
        this.lng = lng;
    }
}
