import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MovilModel } from './modelos/movil-model';
import { GrupoModel } from './modelos/grupo-model';
import { Utils } from '../utils/utils';
import { EstadoMovilGestionModel } from './modelos/estado-movil-gestion-model';

@Injectable({
  providedIn: 'root'
})
export class RecursosService {
  public static moviles = new Map<number, MovilModel>();
  public static grupos = new Map<number, GrupoModel>();
  public static estadoGestion = new Map<number, EstadoMovilGestionModel>();
  public static movilesOk = false;

  constructor(private http: HttpClient) {
  }

  // Permite refrescar la lista de móviles, normalmente después de filtrar
  @Output() refreshMovilesEmiter: EventEmitter<void> = new EventEmitter();

  async getMoviles(empresa: number): Promise<MovilModel[]> {
    const result: any = [];
    const response = await this.http.get(environment.urlApi + '/moviles/' + empresa).toPromise();
    if (response) {
      const data = JSON.parse(JSON.stringify(response));
      data.forEach(movil => {
        const newMovil = new MovilModel(movil.Codigo, movil.Descripcion,
          movil.Sim, movil.Imei, movil.TipoGLS, movil.Empresa,
          movil.Icono.Archivo, movil.Matricula, movil.Estado > 0, movil.CodigoExterno, movil.TipoVehiculo);
        RecursosService.moviles.set(newMovil.id, newMovil);
        result.push(newMovil);
      });
    }
    RecursosService.movilesOk = true;
    return result;
  }

  getGrupos(): Promise<GrupoModel[]> {
    const result: any = [];
    return this.http.get(environment.urlApi + '/api/ambusat/grupos')
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        if (data !== undefined) {
          data.forEach(grupo => {
            const newGrupo = new GrupoModel(grupo.Id, grupo.Nombre, grupo.Observ,
              grupo.FAlta, grupo.FMod, grupo.FBaja);
            RecursosService.grupos.set(newGrupo.id, newGrupo);
            if (newGrupo.fBaja === null) {
              result.push(newGrupo);
            }
          });
        }
        return result;
      }, error => {
        console.log(error);
      });
  }

  getGruposMoviles(idGrupo: number): Promise<number[]> {
    const result: any = [];
    return this.http.get(environment.urlApi + '/api/ambusat/grupos/' + idGrupo)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        if (data !== undefined && data.Moviles !== null) {
          data.Moviles.forEach(idMovil => {
            result.push(idMovil);
          });
        }
        return result;
      }, error => {
        console.log(error);
      });
  }

  // Almacena un grupo
  saveGrupo(grupo: GrupoModel): Promise<GrupoModel> {
    let newGrupo: any = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.post(environment.urlApi + '/api/ambusat/grupos',
      Utils.capitalizeJSON(JSON.stringify(grupo)), httpOptions)
      .toPromise()
      .then(response => {
        const grupo0 = JSON.parse(JSON.stringify(response));
        newGrupo = new GrupoModel(grupo0.Id, grupo0.Nombre, grupo0.Observ,
          grupo0.FAlta, grupo0.FMod, grupo0.FBaja);
        // Actualizo los datos en cache
        RecursosService.grupos.set(newGrupo.id, newGrupo);
        return newGrupo;
      }, error => {
        console.log(error);
        return newGrupo;
      });
  }

  // Borra un grupo
  deleteGrupo(idGrupo: number): Promise<void> {
    return this.http.delete(environment.urlApi + '/api/ambusat/grupos/' + idGrupo)
      .toPromise()
      .then(response => {
        // Actualizo los datos en cache
        const grupo = RecursosService.grupos.get(idGrupo);
        grupo.fBaja = new Date();
        RecursosService.grupos.set(idGrupo, grupo);
        return;
      }, error => {
        console.log(error);
      });
  }

  refreshMoviles() {
    this.refreshMovilesEmiter.emit();
  }

  // Asigna un código de movil a una tablet
  setMovilAsociado(idMovil: number, idMovilAsociado: string): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    const params = {
      ApiToken: environment.apiToken,
      IdMovil: idMovil,
      IdMovilAsociado: idMovilAsociado
    };
    const s = environment.urlApiGes;
    return this.http.post(environment.urlApiGes + '/api/moviles/asociar',
      JSON.stringify(params), httpOptions)
      .toPromise()
      .then(response => {
      }, error => {
      });
  }

  // Asigna un código de recurso GSC a un móvil
  setCodigoRecurso(idMovil: number, codrecurso: string): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    const params = {
      ApiToken: environment.apiToken,
      IdMovil: idMovil,
      CodRecurso: codrecurso
    };
    const s = environment.urlApiGes;
    return this.http.post(environment.urlApiGes + '/api/moviles/asignarecurso',
      JSON.stringify(params), httpOptions)
      .toPromise()
      .then(response => {
      }, error => {
      });
  }


  // Recupera el estado de gestión de todos los móviles (estado, personas, etc...)
  getEstadoMovilesGestion(): Promise<EstadoMovilGestionModel[]> {
    const result: any = [];
    let url = '';
    url = environment.urlApiGes + '/api/estadoMoviles?ApiToken=QtS9scSBCFC8kdHHGV7GKBe6OV5zVYDCW0eau2G';
    return this.http.get(url)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach(elem => {
          const newReg = new EstadoMovilGestionModel(elem.IdMovil, elem.UltimoEstado, elem.FechaEstado, elem.NumPersonas);
          RecursosService.estadoGestion.set(newReg.idMovil, newReg);
          result.push(newReg);
        });
        return result;
      }, error => {
        console.log('recursos::getEstadoMovilesGestion()' + error);
      });
  }

}
