import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { RecursosService } from '../recursos/recursos.service';
import { MovilModel } from '../recursos/modelos/movil-model';
import { LoginService } from '../login/login.service';
import { Idioma } from '../utils/idioma';
import { AlarmaModel } from './modelos/alarma-model';
import { Config } from '../utils/config';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { ToastrService } from 'ngx-toastr';
import { MapsService } from '../maps/maps.service';
import { AlarmasService } from './alarmas.service';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { AreasService } from '../areas/areas.service';
import { AreaModel } from '../areas/modelos/area-model';
import { AreaZonaModel } from '../areas/modelos/area-zona-model';
import { GoogleMapComponent } from '../maps/google-map/google-map.component';
import { ConfirmDialogService } from '../utils/confirm-dialog/confirm-dialog.service';
import { Utils } from '../utils/utils';
import { Permission } from '../utils/permission';
import { GrupoModel } from '../recursos/modelos/grupo-model';

declare var google: any;

@Component({
  selector: 'app-alarmas',
  templateUrl: './alarmas.component.html',
  styleUrls: ['./alarmas.component.css']
})
export class AlarmasComponent implements OnInit, AfterViewInit {
  @ViewChild('lbMoviles', { static: false }) lbMoviles: jqxListBoxComponent;
  @ViewChild('formAlarmas') formAlarmas: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('cbTipos') cbTipos: jqxDropDownListComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('ebNombre', { static: false }) ebNombre;
  @ViewChild('horaEntrada') horaEntrada: jqxDateTimeInputComponent;
  @ViewChild('horaSalida') horaSalida: jqxDateTimeInputComponent;
  @ViewChild('cbAreas') cbAreas: jqxDropDownListComponent;
  @ViewChild('cbZonas') cbZonas: jqxDropDownListComponent;
  @ViewChild('cbGrupos') cbGrupos: jqxDropDownListComponent;

  // Subscripciones del componente
  private subscriptionClickMap: any = null;

  private movilSelec: MovilModel = null;
  public dias = {
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false
  };

  // Variables para edición de alarmas
  private moviles: MovilModel[] = [];
  private alarmas: AlarmaModel[] = [];
  public alarmaSelec: AlarmaModel = null;
  public alarmaEdit: AlarmaModel = null;
  private areas: AreaModel[] = [];
  private areasZonas: AreaZonaModel[] = [];
  public tipoAlarma = 0;
  public canEdit = true;
  private grupos: GrupoModel[] = [];
  private updateGroups = false;
  private grupoSelec = 0;
  private movilesFilter: number[] = [];
  private lastMovil = 0;

  // Para visualizar áreas y zonas
  private circle: any = null;
  private polygon: any = null;

  // Variables para mostrar u ocultar las ventanas
  public mostrarEditForm = false;

  // Preparo las columnas del grid de alarmas
  public columnsAlarmas = [
    { text: 'Id', colsumntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60 },
    { text: 'Nombre', columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 200 },
    { text: 'Área o zona', columntype: 'textbox', filtertype: 'textbox', datafield: 'area', width: 150, cellsrenderer: this.renderRow },
    { text: 'Dias', columntype: 'textbox', filtertype: 'textbox', datafield: 'dias', width: 70 },
    { text: 'E', columntype: 'textbox', filtertype: 'textbox', datafield: 'entrada', width: 5, cellsrenderer: this.renderRow },
    { text: 'H.Entrada', columntype: 'textbox', filtertype: 'textbox', datafield: 'horaEntrada', width: 70, cellsrenderer: this.renderRow },
    { text: 'S', columntype: 'textbox', filtertype: 'textbox', datafield: 'salida', width: 5, cellsrenderer: this.renderRow },
    { text: 'H.Salida', columntype: 'textbox', filtertype: 'textbox', datafield: 'horaSalida', width: 70, cellsrenderer: this.renderRow }
  ];

  //  Esto es para que los textos en los controles del grid salgan en español
  public localization = Idioma.getLocalization('es');

  // Variables para el grid de alarmas
  public sourceAlarmas: any = [];
  public dataAdapterAlarmas = new jqx.dataAdapter(this.sourceAlarmas);

  constructor(
    private recursosService: RecursosService,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private mapService: MapsService,
    private alarmasService: AlarmasService,
    private areasService: AreasService,
    private confirmDialogService: ConfirmDialogService
  ) { }

  ngOnInit(): void {
    this.canEdit = Permission.canEditAlarmas(this.loginService.getUser());
  }

  ngAfterViewInit(): void {
    this.getGroups();
    this.updateMoviles();
    this.getAreas();
  }

  // Rellena la lista de móviles
  async updateMoviles() {
    this.lbMoviles.clear();
    const response = await this.recursosService.getMoviles(this.loginService.getEmpresa());
    this.moviles = response;
    if (this.moviles) {
      this.lbMoviles.beginUpdate();
      this.moviles.forEach(movil => {
        if (movil.gls !== 'AN') {
          if (this.grupoSelec === 0 || this.movilesFilter.find(s => s === movil.id)) {
            const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
              'src="data:image/jpg;base64,' + movil.icono + '"/>';
            const item = {
              id: movil.id,
              html: '<div style="height: 20px; float: left;">' + icono +
                '<span style="float: left; font-size: 13px;">' + movil.nombre + '</span></div>'
            };
            this.lbMoviles.addItem(item);
          }
        }
      });
      this.lbMoviles.endUpdate();
    }
  }

  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (columnfield) {
      case 'horaEntrada':
      case 'horaSalida':
        value = (value !== null && value !== '') ? Utils.formatTime(value) : '';
        return '<span style="margin-left: 4px; margin-top: 7px; float: ' + columnproperties.cellsalign + ';">' +
          value + '</span>';
        break;
      case 'entrada':
      case 'salida':
        value = value ? 'X' : '';
        return '<span style="margin-left: 4px; margin-top: 7px; float: ' + columnproperties.cellsalign + ';">' +
          value + '</span>';
        break;
      case 'area':
        value = (value !== null && value !== '') ? value.nombre : '';
        return '<span style="margin-left: 4px; margin-top: 7px; float: ' + columnproperties.cellsalign + ';">' +
          value + '</span>';
        break;
    }
  }

  getAreas() {
    // Recupero las áreas de la caché
    this.areas = [];
    for (const [key, value] of AreasService.areas) {
      if (value.fBaja === null) {
        this.areas.push(value);
      }
    }
  }

  showFormAlarmas() {
    this.alarmas = [];
    this.alarmaSelec = null;
    this.loader.open();
    // Recupero las alarmas
    this.alarmasService.getAlarmas(this.movilSelec.id).then(
      alarmas => {
        this.alarmas = alarmas;
        if (this.alarmas !== undefined && this.alarmas !== null) {
          this.sourceAlarmas = {
            datatype: 'json',
            datafields: [
              { name: 'id', type: 'number' },
              { name: 'nombre', type: 'string' },
              { name: 'area', type: 'string' },
              { name: 'dias', type: 'string' },
              { name: 'entrada', type: 'string' },
              { name: 'horaEntrada', type: 'string' },
              { name: 'salida', type: 'string' },
              { name: 'horaSalida', type: 'string' }
            ],
            localdata: this.alarmas,
            sortcolumn: 'nombre',
            sortdirection: 'asc'
          };
          this.dataAdapterAlarmas = new jqx.dataAdapter(this.sourceAlarmas);
        }
        // Recorro las alarmas que son sobre zonas y relleno las zonas
        this.alarmas.forEach(alarma => {
          if (alarma.areaZona !== null) {
            this.areasService.getZonas(alarma.area.id).then(
              zonas => {
                // Recupero la zona de la cache
                alarma.areaZona = AreasService.zonas.get(alarma.areaZona.id);
              },
              err => {
                console.log(err);
              });
          }
        });
        this.loader.close();
      },
      err => {
        this.loader.close();
        console.log(err);
      });
  }

  // Permite recibir las coordenadas al pinchar sobre el mapa
  subscribeMapClick(): void {
    if (this.subscriptionClickMap != null) {
      this.subscriptionClickMap.unsubscribe();
    }
    this.subscriptionClickMap = this.mapService.clickEmiter.subscribe(params => {
      if (this.circle !== null) {
        this.circle.setMap(null);
        this.circle = null;
      }
      this.alarmaEdit.lat = params.lat;
      this.alarmaEdit.lng = params.lng;
      this.circle = new google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.8,
        fillColor: '#0000FF',
        fillOpacity: 0.08,
        map: GoogleMapComponent.map,
        center: new google.maps.LatLng(this.alarmaEdit.lat, this.alarmaEdit.lng),
        radius: this.alarmaEdit.radio,
        visible: true,
        editable: true
      });
    });
  }

  onRowAlarmaSelect(event: any) {
    this.alarmaSelec = this.alarmas[event.args.rowindex];
  }

  onCrearAlarmaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.movilSelec !== null) {
      // Creo una alarma vacía para rellenar
      this.alarmaEdit = this.getEmptyAlarma();
      this.dias.lunes = false;
      this.dias.martes = false;
      this.dias.miercoles = false;
      this.dias.jueves = false;
      this.dias.viernes = false;
      this.dias.sabado = false;
      this.dias.domingo = false;
      this.mostrarEditForm = true;
      this.formAlarmas.collapse();
      this.formAlarmas.disable();
    } else {
      this.toastrService.error('Seleccione al menos un móvil de la lista', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onEditarAlarmaClick(event: any) {
    if (this.alarmaSelec !== null) {
      this.alarmaEdit = { ...this.alarmaSelec };
      this.dias.lunes = this.alarmaEdit.dias.indexOf('L') > -1;
      this.dias.martes = this.alarmaEdit.dias.indexOf('M') > -1;
      this.dias.miercoles = this.alarmaEdit.dias.indexOf('X') > -1;
      this.dias.jueves = this.alarmaEdit.dias.indexOf('J') > -1;
      this.dias.viernes = this.alarmaEdit.dias.indexOf('V') > -1;
      this.dias.sabado = this.alarmaEdit.dias.indexOf('S') > -1;
      this.dias.domingo = this.alarmaEdit.dias.indexOf('D') > -1;
      this.openEditForm();
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onChangeCtrlEntrada(event: any) {
    if (!this.alarmaEdit.entrada) {
      this.alarmaEdit.hayHoraEntrada = false;
      this.alarmaEdit.horaEntrada = null;
    }
  }

  onChangeCtrlSalida(event: any) {
    if (!this.alarmaEdit.salida) {
      this.alarmaEdit.hayHoraSalida = false;
      this.alarmaEdit.horaSalida = null;
    }
  }

  onChangeHoraEntrada(event: any) {
    if (!this.alarmaEdit.hayHoraEntrada) {
      this.alarmaEdit.horaEntrada = null;
    } else {
      if (this.horaEntrada.getDate() === null) {
        this.alarmaEdit.horaEntrada = new Date();
      }
    }
  }

  onChangeHoraSalida(event: any) {
    if (!this.alarmaEdit.hayHoraSalida) {
      this.alarmaEdit.horaSalida = null;
    } else {
      if (this.horaSalida.getDate() === null) {
        this.alarmaEdit.horaSalida = new Date();
      }
    }
  }

  onChangeRepetir(event: any) {
    if (!this.alarmaEdit.repetir) {
      this.dias.lunes = false;
      this.dias.martes = false;
      this.dias.miercoles = false;
      this.dias.jueves = false;
      this.dias.viernes = false;
      this.dias.sabado = false;
      this.dias.domingo = false;
      this.alarmaEdit.dias = '';
    } else {
      if (this.alarmaEdit.dias === null || this.alarmaEdit.dias.length < 1) {
        this.dias.lunes = true;
        this.dias.martes = true;
        this.dias.miercoles = true;
        this.dias.jueves = true;
        this.dias.viernes = true;
        this.dias.sabado = true;
        this.dias.domingo = true;
        this.alarmaEdit.dias = 'LMXJVSD';
      }
    }
  }

  onCentrarAlarma(event: any) {
    if (this.alarmaSelec !== null) {
      this.alarmaEdit = { ...this.alarmaSelec };
      if (this.alarmaEdit.area !== null) {
        this.formAlarmas.collapse();
        if (this.alarmaEdit.areaZona !== null) {
          this.onVerZonaClick(null);
        } else {
          this.onVerAreaClick(null);
        }
      } else {
        this.onVerPuntoClick(null);
      }
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  // Muestra el formulario de edición de alarmas
  openEditForm() {
    this.mostrarEditForm = true;
    if (this.formAlarmas !== undefined) {
      this.formAlarmas.collapse();
      this.formAlarmas.disable();
    }
  }

  getEmptyAlarma(): AlarmaModel {
    return new AlarmaModel(0, '', this.movilSelec, false, false, null, null, false, '',
      null, null, 20, 0, 0, '', '', null, null, null);
  }

  // Cada vez que se selecciona un móvil en la lista
  onSelectMovil(event: any): void {
    const item = event.args.item;
    if (item !== null) {
      this.movilSelec = (RecursosService.moviles.get(item.value.id));
      this.showFormAlarmas();
      if (this.movilSelec.id !== this.lastMovil) {
        item.checked = !item.checked;
        this.lastMovil = this.movilSelec.id;
      }
    }
  }

  onOpenEditAlarma() {
    setTimeout(() => {
      this.cbTipos.clear();
      this.cbTipos.addItem({ label: 'Sobre un punto en el mapa', value: 0 });
      this.cbTipos.addItem({ label: 'Sobre un área de gestión', value: 1 });
      if (this.alarmaEdit.area === null) {
        this.cbTipos.selectIndex(0);
      } else {
        this.cbTipos.selectIndex(1);
      }
      this.ebNombre.nativeElement.focus();
      this.cbTipos.disabled(!this.canEdit);
    }, 100);
  }

  onChangeTipoAlarma(event: any) {
    this.tipoAlarma = event.args.item.value;
    setTimeout(() => {
      switch (this.tipoAlarma) {
        case 0: // Sobre un punto en el mapa
          this.alarmaEdit.area = null;
          this.alarmaEdit.areaZona = null;
          break;
        case 1: // Sobre un área
          this.cbAreas.clear();
          let i = 0;
          this.areas.forEach(area => {
            this.cbAreas.addItem({ label: area.nombre, value: area.id });
            if (this.alarmaEdit.area !== null && area.id === this.alarmaEdit.area.id) {
              this.cbAreas.selectIndex(i);
              this.alarmaEdit.area = area;
            }
            i++;
          });
          if (this.alarmaEdit.area === null) {
            this.cbAreas.selectIndex(0);
            this.alarmaEdit.area = AreasService.areas.get(this.areas[0].id);
          }
          break;
      }
    }, 100);
  }

  onChangeArea(event: any) {
    this.alarmaEdit.area = AreasService.areas.get(event.args.item.value);
    // Recupero las zonas del área
    this.cbZonas.clear();
    this.cbZonas.addItem({ label: 'Todo el área', value: 0 });
    if (this.alarmaEdit.areaZona === null) {
      this.cbZonas.selectIndex(0);
    }
    this.areasService.getZonas(this.alarmaEdit.area.id).then(
      zonas => {
        this.areasZonas = zonas;
        if (this.areasZonas !== undefined) {
          let i = 1;
          this.areasZonas.forEach(zona => {
            this.cbZonas.addItem({ label: zona.nombre, value: zona.id });
            if (this.alarmaEdit.areaZona !== null && zona.id === this.alarmaEdit.areaZona.id) {
              this.cbZonas.selectIndex(i);
              this.alarmaEdit.areaZona = zona;
            }
            i++;
          });
        }
      },
      err => {
        console.log(err);
      });
    this.cbAreas.disabled(!this.canEdit);
  }

  onChangeZona(event: any) {
    if (event.args.item.value > 0) {
      this.alarmaEdit.areaZona = AreasService.zonas.get(event.args.item.value);
    } else {
      this.alarmaEdit.areaZona = null;
    }
    this.cbZonas.disabled(!this.canEdit);
  }

  onCloseEdit() {
    if (this.subscriptionClickMap != null) {
      this.subscriptionClickMap.unsubscribe();
      this.subscriptionClickMap = null;
    }
    if (this.circle !== null) {
      this.circle.setMap(null);
      this.circle = null;
    }
    if (this.polygon !== null) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
    this.mostrarEditForm = false;
    this.formAlarmas.enable();
    this.formAlarmas.expand();
  }

  onVerAreaClick(event: any) {
    this.mapService.setCenter(new google.maps.LatLng(this.alarmaEdit.area.lat,
      this.alarmaEdit.area.lng));
    this.mapService.setZoom(16);
    if (this.formEdit !== undefined) {
      this.formEdit.collapse();
    }
    if (this.formAlarmas !== undefined) {
      this.formAlarmas.collapse();
    }
  }

  onVerZonaClick(event: any) {
    if (this.alarmaEdit.areaZona !== null) {
      let center: any;
      if (this.circle !== null) {
        this.circle.setMap(null);
      }
      if (this.polygon !== null) {
        this.polygon.setMap(null);
      }
      if (this.alarmaEdit.areaZona.tipo === 0) {
        this.circle = new google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.2,
          strokeWeight: 0.8,
          fillColor: '#0000FF',
          fillOpacity: 0.08,
          map: GoogleMapComponent.map,
          center: new google.maps.LatLng(this.alarmaEdit.areaZona.lat, this.alarmaEdit.areaZona.lng),
          radius: this.alarmaEdit.areaZona.radio,
          visible: true
        });
        center = new google.maps.LatLng(this.alarmaEdit.areaZona.lat, this.alarmaEdit.areaZona.lng);
      } else {
        this.polygon = new google.maps.Polygon({
          strokeColor: '#FF0000',
          strokeOpacity: 0.2,
          strokeWeight: 0.8,
          fillColor: '#0000FF',
          fillOpacity: 0.2,
          clickable: true,
          geodesic: true,
          map: GoogleMapComponent.map
        });
        this.alarmaEdit.areaZona.puntos.forEach(element => {
          this.polygon.getPath().push(new google.maps.LatLng(element.lat, element.lng));
        });
        center = new google.maps.LatLng(this.alarmaEdit.areaZona.puntos[0].lat,
          this.alarmaEdit.areaZona.puntos[0].lng);
      }
      this.mapService.setCenter(center);
      this.mapService.setZoom(18);
      if (this.formEdit !== undefined) {
        this.formEdit.collapse();
      }
      if (this.formAlarmas !== undefined) {
        this.formAlarmas.collapse();
      }
    } else {
      this.onVerAreaClick(null);
    }
  }

  onVerPuntoClick(event: any) {
    let center: any;
    if (this.circle !== null) {
      this.circle.setMap(null);
    }
    this.circle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.2,
      strokeWeight: 0.8,
      fillColor: '#0000FF',
      fillOpacity: 0.08,
      map: GoogleMapComponent.map,
      center: new google.maps.LatLng(this.alarmaEdit.lat, this.alarmaEdit.lng),
      radius: this.alarmaEdit.radio,
      visible: true
    });
    center = new google.maps.LatLng(this.alarmaEdit.lat, this.alarmaEdit.lng);
    this.mapService.setCenter(center);
    this.mapService.setZoom(18);
    if (this.formEdit !== undefined) {
      this.formEdit.collapse();
    }
    if (this.formAlarmas !== undefined) {
      this.formAlarmas.collapse();
    }
  }

  onGuardarAlarmaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.alarmaEdit.nombre === null || this.alarmaEdit.nombre.length < 1) {
      this.toastrService.error('Introduzca un nombre', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (!this.alarmaEdit.entrada && !this.alarmaEdit.salida) {
      this.toastrService.error('Indique si la alarma es de entrada y/o salida', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.alarmaEdit.area === null && this.alarmaEdit.lat === 0 && this.alarmaEdit.lng === 0) {
      this.toastrService.error('No ha posicionado la alarma sobre la cartografía', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.alarmaEdit.dias = '';
    this.alarmaEdit.dias += this.dias.lunes ? 'L' : '';
    this.alarmaEdit.dias += this.dias.martes ? 'M' : '';
    this.alarmaEdit.dias += this.dias.miercoles ? 'X' : '';
    this.alarmaEdit.dias += this.dias.jueves ? 'J' : '';
    this.alarmaEdit.dias += this.dias.viernes ? 'V' : '';
    this.alarmaEdit.dias += this.dias.sabado ? 'S' : '';
    this.alarmaEdit.dias += this.dias.domingo ? 'D' : '';
    if (this.alarmaEdit.repetir && this.alarmaEdit.dias.length < 1) {
      this.toastrService.error('Indique que días de las semana quiere repetir', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (!this.alarmaEdit.hayHoraEntrada) {
      this.alarmaEdit.horaEntrada = null;
    }
    if (!this.alarmaEdit.hayHoraSalida) {
      this.alarmaEdit.horaSalida = null;
    }
    if (this.alarmaEdit.area !== null) {
      this.alarmaEdit.lat = 0;
      this.alarmaEdit.lng = 0;
      this.alarmaEdit.radio = 0;
    } else {
      this.alarmaEdit.areaZona = null;
    }
    this.saveAlarma();
  }

  // Guardo la alarma
  async saveAlarma() {
    const response = await this.alarmasService.saveAlarma(this.alarmaEdit);
    if (response) {
      if (this.alarmaEdit.id === 0) {
        this.alarmas.push(response);
      } else {
        for (let i = 0; i < this.alarmas.length; i++) {
          if (this.alarmas[i].id === this.alarmaEdit.id) {
            this.alarmas[i] = { ...response };
            break;
          }
        }
      }
      // Si es una alarma nueva y hay más de un móvil seleccionado la creo en todos ellos
      if (this.alarmaEdit.id === 0) {
        const rowsSelec = this.lbMoviles.getCheckedItems();
        if (rowsSelec.length > 0) {
          rowsSelec.forEach(async (rowIndex) => {
            if (rowIndex.value.id !== this.alarmaEdit.IdMovil) {
              const alarma = { ...this.alarmaEdit };
              alarma.movil.id = rowIndex.value.id;
              await this.alarmasService.saveAlarma(alarma);
            }
          });
        }
      }
      this.alarmaEdit = { ...response };
      this.alarmaSelec = { ...this.alarmaEdit };
      if (this.grid !== undefined && this.grid !== null) {
        this.grid.updatebounddata('cells');
      }
      this.toastrService.info('Registro almacenado', 'Información', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    } else {
      this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
    this.formEdit.close();
  }

  onBorrarAlarmaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.alarmaSelec !== null) {
      this.deleteAlarma();
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  deleteAlarma() {
    this.confirmDialogService.confirmThis(
      'Seguro que quiere borrar la alarma: ' + this.alarmaSelec.nombre, () => {
        this.alarmasService.deleteAlarma(this.alarmaSelec.id).then(
          response => {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.alarmas.length; i++) {
              if (this.alarmas[i].id === this.alarmaSelec.id) {
                this.alarmas.splice(i, 1);
                break;
              }
            }
            this.alarmaSelec = null;
            this.grid.updatebounddata('cells');
            this.toastrService.info('Registro borrado', 'Información', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          },
          err => {
            this.toastrService.error('No se ha podido realizar la operación', 'Atención', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          });
      }, () => {
      });
  }

  onPosicionarClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.alarmaEdit.radio < 1) {
      this.toastrService.error('Introduzca un radio', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.formEdit.collapse();
    if (this.alarmaEdit.lat !== 0 && this.alarmaEdit.lng !== 0 && this.alarmaEdit.radio > 0) {
      this.mapService.setCenter(new google.maps.LatLng(this.alarmaEdit.lat, this.alarmaEdit.lng));
      this.mapService.setZoom(18);
      if (this.circle !== null) {
        this.circle.setMap(null);
        this.circle = null;
      }
      this.circle = new google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.8,
        fillColor: '#0000FF',
        fillOpacity: 0.08,
        map: GoogleMapComponent.map,
        center: new google.maps.LatLng(this.alarmaEdit.lat, this.alarmaEdit.lng),
        radius: this.alarmaEdit.radio,
        visible: true,
        editable: true
      });
    } else {
      this.toastrService.info('Seleccione un punto sobre la cartografía', 'Por favor', {
        timeOut: 3000,
        positionClass: 'toast-top-center'
      });
      this.subscribeMapClick();
    }
  }

  onExpandEditAlarma() {
    if (this.tipoAlarma === 0 && this.alarmaEdit.lat !== 0 && this.alarmaEdit.lng !== 0 &&
      this.alarmaEdit.radio > 0 && this.circle !== null) {
      this.alarmaEdit.lat = this.circle.getCenter().lat();
      this.alarmaEdit.lng = this.circle.getCenter().lng();
      this.alarmaEdit.radio = Math.round(this.circle.getRadius());
    }
  }

  onTodosClick(event: any) {
    if (this.lbMoviles) {
      this.lbMoviles.checkAll();
    }
  }

  onNingunoClick(event: any) {
    if (this.lbMoviles) {
      this.lbMoviles.uncheckAll();
    }
  }

  // Rellena la lista de grupos
  async getGroups() {
    this.grupos = [];
    const response = await this.recursosService.getGrupos();
    if (response) {
      this.grupos = response;
      this.updateGroups = true;
      this.cbGrupos.clear();
      this.cbGrupos.addItem({ label: 'Todos los vehículos', value: -1 });
      let i = 1;
      this.grupos.forEach(grupo => {
        this.cbGrupos.addItem({
          label: grupo.nombre,
          value: grupo.id
        });
        i++;
      });
      if (this.cbGrupos.getSelectedIndex() < 0) {
        this.cbGrupos.selectIndex(0);
        Config.storeMovilesSubFilter([{ id: -1 }]);
      }
    } else {
      this.cbGrupos.selectIndex(0);
      this.grupoSelec = 0;
    }
  }

  async onChangeGrupos(event: any) {
    if (this.updateGroups) {
      this.updateGroups = false;
    } else {
      if (event.args.item.value > -1) {
        this.grupoSelec = event.args.item.value;
        this.movilesFilter = await this.recursosService.getGruposMoviles(this.grupoSelec);
      } else {
        this.grupoSelec = 0;
      }
      this.updateMoviles();
    }
  }

}
