import { AreaTipoModel } from './area-tipo-model';
export class AreaModel {
    id: number;
    nombre: string;
    tipo: AreaTipoModel;
    lat: number;
    lng: number;
    radio: number;
    observ: string;
    fAlta: Date;
    fMod: Date;
    fBaja: Date;
    // Campos sólo para el endpoint de la API
    IdAreaTipo: number;
    // Campo sólo para autenticación 
    ApiToken: string;

    constructor(id: number, nombre: string, area: AreaTipoModel, latitud: number,
        longitud: number, radio: number, observ: string, fAlta: Date, fMod: Date, fBaja: Date) {
        this.id = id;
        this.nombre = nombre;
        this.tipo = area;
        this.lat = latitud;
        this.lng = longitud;
        this.radio = radio;
        this.observ = observ !== undefined ? observ : null;
        this.fAlta = fAlta !== undefined ? fAlta : null;
        this.fMod = fMod !== undefined ? fMod : null;
        this.fBaja = fBaja !== undefined ? fBaja : null;
    }
}
