import { AgmMap } from '@agm/core';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GoogleMapVisorComponent } from '../maps/google-map-visor/google-map-visor.component';
import { MapsService } from '../maps/maps.service';
import { MovilMarker } from '../maps/modelos/movil-marker';
import { PosicionModel } from '../posiciones/modelos/posicion-model';
import { PosicionesService } from '../posiciones/posiciones.service';
import { MovilModel } from '../recursos/modelos/movil-model';
import { RecursosService } from '../recursos/recursos.service';

declare var google: any;

@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./visor.component.css']
})
export class VisorComponent implements OnInit, AfterViewInit {
  @ViewChild('AgmMap') AgmMap: AgmMap;

  constructor(private posicionesService: PosicionesService,
    private recursorService: RecursosService,
    private mapService: MapsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const matricula = new URLSearchParams(window.location.search).get('m');
    if (matricula) {
      setTimeout(async () => {
        const moviles = await this.recursorService.getMoviles(environment.idEmpresa);
        if (moviles) {
          moviles.forEach(movil => {
            if (movil.matricula === matricula) {
              this.posicionesService.getUltimaPosicion(movil.id).then(pos => {
                if (pos) {
                  this.mapService.setCenter(new google.maps.LatLng(pos.lat, pos.lng));
                  new MovilMarker(pos, GoogleMapVisorComponent.map, this.mapService, false, true);
                }
              },
                err => {
                  console.log(err);
                });
            }
          });
        }
      }, 500);
    }
  }

}
