import {
  Component, OnInit, AfterViewInit, ViewChild, OnDestroy, NgZone
} from '@angular/core';
import { AgmMap, LatLng } from '@agm/core';
import { MouseEvent as AGMMouseEvent } from '@agm/core';
import { PosicionesService } from '../../posiciones/posiciones.service';
import { MapsService } from '../maps.service';
import { LoginService } from '../../login/login.service';
import { Utils } from '../../utils/utils';
import { SensorModel } from '../../sensores/modelos/sensor-model';
import { Config } from '../../utils/config';
import { AreasService } from '../../areas/areas.service';
import { AreaTipoModel } from '../../areas/modelos/area-tipo-model';
import { AreaMarker } from '../modelos/area-marker';
import { MovilMarker } from '../modelos/movil-marker';
import { SensorMarker } from '../modelos/sensor-marker';
import { BaseMarker } from '../modelos/base-marker';
import { StopMarker } from '../modelos/stop-marker';
import { PosicionModel } from '../../posiciones/modelos/posicion-model';
import { RecursosService } from '../../recursos/recursos.service';
import { AreaModel } from 'src/app/areas/modelos/area-model';
import { UsuarioModel } from '../../login/modelos/usuario-model';
import { ConfirmDialogService } from '../../utils/confirm-dialog/confirm-dialog.service';
import { environment } from '../../../environments/environment';

declare var google: any;
declare var MarkerClusterer: any;

enum Animation {
  BOUNCE = 1,
  DROP = 2
}

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})

export class GoogleMapComponent implements OnInit, OnDestroy, AfterViewInit {
  public static map = null;
  @ViewChild('AgmMap') AgmMap: AgmMap;
  @ViewChild('search', { static: false }) search;

  private map: any; // El mapa
  protected geoCoder: any; // Para busquedas sobre el mapa
  private traficLayer: any; // Para ver el estado del tráfico

  private INTERVAL_POS_MOVILES = 30000; // Intervalo de refresco posiciones móviles

  // Usuario actual
  private usuActual: UsuarioModel;

  // Variables para posiciones de moviles
  private timerMoviles = null;
  private markersMoviles: MovilMarker[] = [];
  private markersMovilesIndex = new Map<number, number>();
  private hideMoviles = false;

  // Variables para posiciones de ruta
  private posRoute: PosicionModel[] = [];
  private polyLinesRoute: any = [];
  private polyline = null;
  private markersRuta: BaseMarker[] = [];
  private markerVehicle: MovilMarker = null;
  private infoRoute: any = null;
  private markersStop: BaseMarker[] = [];
  private movilRoute = {
    id: 0,
    name: '',
    icon: ''
  };
  private timeStop = (5 * 60 * 1000); // Tiempo de parada 5 minutos

  // Variables para sensores de ruta
  public markersSensor: SensorMarker[] = [];
  private arraySensores: SensorModel[] = [];
  private posSensorIndex = 0;

  // Marcador para centrar posiciones en el mapa
  private markersPos: BaseMarker;

  // Para reproducir la ruta paso a paso
  private arrayPosRoute: PosicionModel[] = [];
  private posRouteIndex = 0;
  private timerRoute: any;
  private routeInterval = 1000;
  private routePaused = false;
  private routeStoped = true;

  // Variables para las areas
  private markersAreas: AreaMarker[] = [];
  private markersAreasIndex = new Map<number, number>();
  private tiposAreas: AreaTipoModel[] = [];
  private infoArea: any = null;

  // Cluster para areas
  private clusterAreas: any;

  // Configuración por defecto del mapa
  private mapType = 'roadmap';
  private zoom = environment.zoom;
  private center = {
    lat: environment.lat,
    lng: environment.lng
  };

  // Variables para el mapa
  public trafic = Config.getTraficMap(false);

  constructor(
    private ngZone: NgZone,
    private posicionesService: PosicionesService,
    private mapsService: MapsService,
    private loginService: LoginService,
    private areasService: AreasService,
    private confirmDialogService: ConfirmDialogService) {
  }

  ngOnInit(): void {
    this.usuActual = this.loginService.getUser();
    // Obtengo el último encuadre, para ello recupero el último centro y el zoom
    this.center = Config.getCenterMap(this.center);
    this.zoom = Config.getZoomMap(this.zoom);
  }

  ngOnDestroy(): void {
    // finalizo temporizadores
    if (this.timerMoviles !== undefined && this.timerMoviles !== null) {
      clearInterval(this.timerMoviles);
    }
    if (this.timerRoute !== undefined && this.timerRoute !== null) {
      clearInterval(this.timerRoute);
    }
    // Destruyo objetos del mapa
    this.markersMoviles.forEach(m => {
      m.setVisible(false);
    });
    this.clearRoute();
  }

  ngAfterViewInit(): void {
    this.AgmMap.mapReady.subscribe(map => {
      // Obtengo el manejador del mapa
      this.map = map;
      GoogleMapComponent.map = map;
      // Quito los puntos de interes del mapa
      this.map.setOptions({
        zoomControlOptions: {
          style: google.maps.ControlPosition.small,
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        styles: [ // Oculto los puntos de interes
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      });
      // Preparo la búsqueda de direcciones en el mapa
      this.geoCoder = new google.maps.Geocoder();
      // Restrinjo el área de busqueda preferido
      const sw = new google.maps.LatLng(environment.latSW, environment.lngSW);
      const ne = new google.maps.LatLng(environment.latNE, environment.lngNE);
      const searchBounds = new google.maps.LatLngBounds(sw, ne);
      const options = {
        bounds: searchBounds,
        types: ['geocode'],
        componentRestrictions: { country: 'es' }
      };
      const autocomplete = new google.maps.places.Autocomplete(
        this.search.nativeElement, options);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          if (place.geometry !== undefined && place.geometry !== null) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            this.setCenter(lat, lng);
            this.search.nativeElement.value = '';
          }
        });
      });
      // Creo la capa de tráfico para poder activar o desactivar
      this.traficLayer = new google.maps.TrafficLayer();
      this.traficLayer.setMap(this.trafic ? this.map : null);
      // Cluster para las areas
      this.clusterAreas = new MarkerClusterer(this.map, null, {
        imagePath: 'assets/images/mapa/cluster/m',
        maxZoom: 18,
        ignoreHidden: true
      });
      // Eventos a los que se subscribe el mapa
      this.subscribeSetCenter();
      this.subscribeSetZoom();
      this.subscribeCenterMovil();
      this.subscribeCenterPos();
      this.subscribeCenterMoviles();
      this.subscribeCenterArea();
      this.subscribeShowRoute();
      this.subscribeRemoveRoute();
      this.subscribePauseRoute();
      this.subscribeSetRouteInterval();
      this.subscribeShowMoviles();
      this.subscribeShowAreas();
      this.subscribeAddAreas();
      this.subscribeAddArea();
      this.subscribeUpdateArea();
      this.subscribeRemoveArea();
      // Pinto las posiciones de los móviles y las areas
      this.showMoviles();
      this.showAreas();
    });
  }

  // Muestra los móviles
  showMoviles() {
    this.markersMovilesIndex = new Map<number, number>();
    this.markersMoviles = [];
    this.timerMoviles = setInterval(() => {
      // Espero a que se hayan cargado todos los móviles
      if (RecursosService.movilesOk) {
        clearInterval(this.timerMoviles);
        // Timer que actualiza las posiciones de los móviles sobre el mapa
        this.updateLastPositionMovil();
        this.timerMoviles = setInterval(() => {
          this.updateLastPositionMovil();
        }, this.INTERVAL_POS_MOVILES);
      }
    }, 1000);
  }

  // Actualiza la última posición de los móviles sobre el mapa
  updateLastPositionMovil() {
    this.posicionesService.getUltimasPosiciones(this.loginService.getEmpresa()).then(
      response => {
        // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
        const filter = Config.getMovilesFilter([{ id: -1 }]);
        // Recupero el subfiltro de móviles (-1 = todos mientras no se establece un subfiltro)
        const subFilter = Config.getMovilesSubFilter([{ id: -1 }]);
        if (response !== undefined) {
          response.forEach(pos => {
            // Meto las posiciones en la tabla hash
            let index = 0;
            const i = this.markersMovilesIndex.get(pos.movil.id);
            if (i === undefined) {
              const marker = new MovilMarker(pos, this.map, this.mapsService, false);
              index = this.markersMoviles.push(marker) - 1;
              let visible = Boolean(filter.find(s => s.id === pos.movil.id) !== undefined ||
                filter.find(s => s.id === -1));
              if (visible) {
                visible = Boolean(subFilter.find(s => s.id === pos.movil.id) !== undefined ||
                  subFilter.find(s => s.id === -1));
              }
              marker.setVisible(visible && !this.hideMoviles);
            } else {
              index = i;
              this.markersMoviles[index].setPosicion(pos);
              let visible = Boolean(filter.find(s => s.id === pos.movil.id) !== undefined ||
                filter.find(s => s.id === -1));
              if (visible) {
                visible = Boolean(subFilter.find(s => s.id === pos.movil.id) !== undefined ||
                  subFilter.find(s => s.id === -1));
              }
              this.markersMoviles[index].setVisible(visible && !this.hideMoviles);
            }
            this.markersMovilesIndex.set(pos.movil.id, index);
          });
        }
      },
      err => {
        console.log(err);
      });
  }

  // Permite mostrar u ocultar las posiciones de los moviles
  subscribeShowMoviles(): void {
    this.mapsService.showMovilesEmiter.subscribe(show => {
      this.hideMoviles = !show;
      // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
      const filter = Config.getMovilesFilter([{ id: -1 }]);
      // Recupero el subfiltro de móviles (-1 = todos mientras no se establece un subfiltro)
      const subFilter = Config.getMovilesSubFilter([{ id: -1 }]);
      this.markersMoviles.forEach(m => {
        if (show) {
          let visible = filter.find(s => s.id === m.getPosicion().movil.id) !== undefined ||
            filter.find(s => s.id === -1);
          if (visible) {
            visible = subFilter.find(s => s.id === m.getPosicion().movil.id) !== undefined ||
              subFilter.find(s => s.id === -1);
          }
          m.setVisible(visible);
        } else {
          m.setVisible(false);
        }
      });
    });
  }

  // Permite centrar el mapa en la posición de un móvil
  subscribeCenterMovil(): void {
    this.mapsService.centerMovilEmiter.subscribe(idMovil => {
      const index = this.markersMovilesIndex.get(idMovil);
      if (index !== undefined) {
        // Hago que el marcador salte durante 2 segundos
        this.center.lat = this.markersMoviles[index].getPosicion().lat;
        this.center.lng = this.markersMoviles[index].getPosicion().lng;
        this.setCenter(this.center.lat, this.center.lng);
        this.markersMoviles[index].getMarker().setAnimation(Animation.BOUNCE);
        this.markersMoviles[index].getMarker().setZIndex(999);
        setTimeout(() => {
          this.markersMoviles[index].getMarker().setAnimation(null);
        }, 2000);
      }
    });
  }

  // Permite centrar el mapa en la posición dada
  subscribeCenterPos(): void {
    this.mapsService.centerPosEmiter.subscribe(pos => {
      this.setCenter(pos.lat, pos.lng);
      if (this.markersPos) {
        this.markersPos.getMarker().setMap(null);
      }
      this.markersPos = new BaseMarker(Utils.formatDateTime(pos.fecha, true),
        pos.lat, pos.lng, '', 999, this.map, false)
      this.markersPos.getMarker().setAnimation(Animation.BOUNCE);
      this.markersPos.getMarker().setZIndex(999);
      setTimeout(() => {
        this.markersPos.getMarker().setAnimation(null);
      }, 2000);
    });
  }

  // PermiteHacer un zoon de encuadre con los móviles pasados
  subscribeCenterMoviles(): void {
    this.mapsService.centerMovilesEmiter.subscribe(moviles => {
      let lat1 = 180;
      let lng1 = 90;
      let lat2 = -180;
      let lng2 = -90;
      moviles.forEach(movilId => {
        const index = this.markersMovilesIndex.get(movilId);
        if (this.markersMoviles[index].getPosicion().lat < lat1) {
          lat1 = this.markersMoviles[index].getPosicion().lat;
        }
        if (this.markersMoviles[index].getPosicion().lng < lng1) {
          lng1 = this.markersMoviles[index].getPosicion().lng;
        }
        if (this.markersMoviles[index].getPosicion().lat > lat2) {
          lat2 = this.markersMoviles[index].getPosicion().lat;
        }
        if (this.markersMoviles[index].getPosicion().lng > lng2) {
          lng2 = this.markersMoviles[index].getPosicion().lng;
        }
      });
      if (lat1 < 180 && lng1 < 90 && lat2 > -180 && lng2 > -90) {
        this.fitMap(lat1, lng1, lat2, lng2);
      }
    });
  }

  // Permite centrar el mapa en la posición de un área
  subscribeCenterArea(): void {
    this.mapsService.centerAreaEmiter.subscribe(area => {
      const index = this.markersAreasIndex.get(area);
      if (index !== undefined) {
        // Hago que el marcador salte durante 2 segundos
        this.center.lat = this.markersAreas[index].getArea().lat;
        this.center.lng = this.markersAreas[index].getArea().lng;
        this.markersAreas[index].getMarker().setAnimation(Animation.BOUNCE);
        this.markersAreas[index].getMarker().setZIndex(999);
        setTimeout(() => {
          this.markersAreas[index].getMarker().setAnimation(null);
        }, 2000);
      }
    });
  }

  // Muestra las areas
  showAreas(): void {
    this.markersAreas = [];
    this.markersAreasIndex = new Map<number, number>();
    // Recupero los tipos de areas
    this.areasService.getAreasTipos().then(
      tiposAreas => {
        this.tiposAreas = tiposAreas;
        // Recupero el filtro de areas
        const filter = Config.getAreasFilter([{}]);
        // Meto los tipos en la tabla hash
        if (this.tiposAreas !== undefined) {
          this.tiposAreas.forEach(tipos => {
            tipos.visible = filter.find(s => s.id === tipos.id) !== undefined;
          });
        }
        // Recupero las areas
        this.areasService.getAreas().then(
          response => {
            // Como las areas son pesadas se van recuperando por
            // páginas y añadiendo en el método subscribeAddAreas()
            // this.areas = response;

            // response.forEach(area => {
            //   if (area.fBaja === null) {
            //     this.addArea(area);
            //   }
            // });
            // this.clusterAreas.repaint();

          },
          err => {
            console.log(err);
          });
      },
      err => {
        console.log(err);
      });
  }

  // Permite mostrar añadir areas
  subscribeAddAreas(): void {
    this.mapsService.addAreasEmiter.subscribe(areas => {
      if (areas !== undefined) {
        areas.forEach(area => {
          if (area.fBaja === null) {
            this.addArea(area);
          }
        });
      }
      this.clusterAreas.repaint();
    });
  }

  addArea(area: AreaModel) {
    // Añado el area a la lista y al cluster si está visible
    const am = new AreaMarker(area, this.map, true);
    this.markersAreas.push(am);
    this.markersAreasIndex.set(area.id, this.markersAreas.length - 1);
    if (area.tipo.visible) {
      this.clusterAreas.addMarker(am.getMarker(), true);
    }
    // Creo el listener para cuando se pincha sobre el area
    google.maps.event.addListener(am.getMarker(), 'click', (event => {
      if (this.infoArea !== null) {
        this.infoArea.close();
      }
      this.infoArea = new google.maps.InfoWindow({
        content:
          '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
          '<b>' + am.getArea().nombre + '</b>' + '<hr style = "color: navy;" />' +
          am.getArea().tipo.nombre +
          '</div>'
      });
      this.infoArea.open(this.map, am.getMarker());
      this.mapsService.clickEventEmit({
        lat: am.getArea().lat,
        lng: am.getArea().lng,
        elemId: am.getArea().id,
        elemNombre: am.getArea().nombre
      });
    }));
    // Cuando se mueve un área de sitio
    google.maps.event.addListener(am.getMarker(), 'dragend', (event => {
      const area0 = am.getArea();
      // Sólo los administradores pueden mover las áreas
      if (this.usuActual.rol.id === 1) {
        this.confirmDialogService.confirmThis('Quiere mover el área a este punto ?', () => {
          area0.lat = event.latLng.lat();
          area0.lng = event.latLng.lng();
          this.areasService.saveArea(area0);
          this.mapsService.updateArea(area0);
        }, () => {
          am.getMarker().setPosition(new google.maps.LatLng(area0.lat, area0.lng));
          this.clusterAreas.repaint();
        });
      } else {
        am.getMarker().setPosition(new google.maps.LatLng(area0.lat, area0.lng));
        this.clusterAreas.repaint();
      }
    }));
  }

  // Permite añadir un área al mapa
  subscribeAddArea(): void {
    this.mapsService.addAreaEmiter.subscribe(area => {
      this.addArea(area);
      this.clusterAreas.repaint();
    });
  }

  // Permite añadir un área al mapa
  subscribeUpdateArea(): void {
    this.mapsService.updateAreaEmiter.subscribe(area => {
      this.removeArea(area);
      this.addArea(area);
      this.clusterAreas.repaint();
    });
  }

  // Permite borrar un área del mapa
  subscribeRemoveArea(): void {
    this.mapsService.removeAreaEmiter.subscribe(area => {
      this.removeArea(area);
      this.clusterAreas.repaint();
    });
  }

  removeArea(area: AreaModel) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.markersAreas.length; i++) {
      if (this.markersAreas[i].getArea().id === area.id) {
        this.clusterAreas.removeMarker(this.markersAreas[i].marker);
        this.markersAreas[i].circle.setMap(null);
        this.markersAreas.splice(i, 1);
        break;
      }
    }
  }

  // Permite mostrar u ocultar las areas
  subscribeShowAreas(): void {
    this.mapsService.showAreasEmiter.subscribe(show => {
      // Recupero el filtro de areas
      const filter = Config.getAreasFilter([{}]);
      // Meto los tipos en la tabla hash
      this.tiposAreas.forEach(tipo => {
        tipo.visible = filter.find(s => s.id === tipo.id) !== undefined;
        AreasService.areasTipos.set(tipo.id, tipo);
      });
      this.clusterAreas.clearMarkers();
      this.markersAreas.forEach(area => {
        const model = AreasService.areasTipos.get(area.getArea().tipo.id);
        if (model !== undefined && model != null && model.visible) {
          this.clusterAreas.addMarker(area.getMarker(), true);
          area.circle.setVisible(true);
        } else {
          area.circle.setVisible(false);
        }
      });
      this.clusterAreas.repaint();
    });
  }

  // Permite reproducir la ruta de un móvil
  subscribeShowRoute(): void {
    this.mapsService.showRouteEmiter.subscribe(info => {
      if (info.pos !== undefined && info.pos.length > 1) {
        this.movilRoute.id = info.movilId;
        this.movilRoute.name = info.movilName;
        this.movilRoute.icon = info.icon;
        if (!info.step) {
          if (this.timerRoute > 0) {
            clearInterval(this.timerRoute);
            this.timerRoute = 0;
          }
          this.clearRoute();
          // Pinto la ruta de un tirón
          this.posRoute = info.pos;
          this.arraySensores = info.sensor;
          this.routePaused = false;
          this.routeStoped = true;
          this.setIniRuta(this.posRoute[0]);
          this.setFinRuta(this.posRoute[this.posRoute.length - 1]);
          this.showRoute();
          // Pinto los sensores
          this.showSensoresRuta();
          // Hago un zoom de encuadre para que se vea toda la ruta
          this.fitMapToRoute();
        } else {
          if (!this.routePaused) {
            this.clearRoute();
          }
          // Hay que pintar la ruta paso a paso
          this.arrayPosRoute = info.pos;
          this.arraySensores = info.sensor;
          this.showRouteStep2Step();
        }
      }
    });
  }

  // Limpia las líneas de la anterior ruta
  clearRoute() {
    this.polyLinesRoute.forEach(poly => {
      poly.setMap(null);
    });
    this.markersSensor.forEach(m => {
      m.setVisible(false);
    });
    this.markersRuta.forEach(m => {
      m.setVisible(false);
    });
    this.markersStop.forEach(m => {
      m.setVisible(false);
    });
    if (this.markerVehicle != null) {
      this.markerVehicle.setVisible(false);
    }
    this.markerVehicle = null;
    this.markersRuta = [];
    this.markersSensor = [];
    this.markersStop = [];
    this.polyLinesRoute = [];
    this.polyline = null;
  }

  // Pinta la ruta del tirón
  showRoute() {
    let prevPos: PosicionModel = null;
    let oldColor = '';
    let color = 'green';
    let polyline = null;
    this.polyLinesRoute.forEach(poly => {
      poly.setMap(null);
    });
    this.polyLinesRoute = [];
    this.posRoute.forEach(pos => {
      color = this.getRouteColor(pos.velocidad);
      if (oldColor !== color) {
        oldColor = color;
        if (polyline !== null) {
          polyline.getPath().push(new google.maps.LatLng(pos.lat, pos.lng));
        }
        polyline = this.newPolyline(pos);
      }
      polyline.getPath().push(new google.maps.LatLng(pos.lat, pos.lng));
      // Calculo el tiempo que ha pasado respecto a al anterior posición
      // para ver si hay una parada
      if (prevPos !== null) {
        if (pos.fecha.getTime() - prevPos.fecha.getTime() >= this.timeStop) {
          this.markersStop.push(new StopMarker(prevPos.fecha, pos.fecha,
            prevPos.lat, prevPos.lng, 0, this.map, false));
        }
      }
      prevPos = pos;
    });
  }

  // Crea una nueva polilinea para pintar la ruta
  newPolyline(posicion: PosicionModel): any {
    const poly = new google.maps.Polyline({
      strokeColor: this.getRouteColor(posicion.velocidad),
      strokeOpacity: 0.7,
      strokeWeight: 6,
      clickable: true,
      geodesic: true
    });
    poly.setMap(this.map);
    this.polyLinesRoute.push(poly);
    // Creo el listener para cuando se pincha sobre la ruta
    google.maps.event.addListener(poly, 'click', (event => {
      if (this.infoRoute !== null) {
        this.infoRoute.close();
      }
      const pr = this.getRoutePoint(event.latLng, 20);
      if (pr !== null) {
        this.infoRoute = new google.maps.InfoWindow({
          content:
            '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
            '<b>' + Utils.formatDateTime(pr.fecha, true) + '</b>' + '<hr style = "color: navy;" />' +
            pr.velocidad + '  km/h<br>' + pr.altura + ' m.' +
            '</div>'
        });
        this.infoRoute.setPosition(new google.maps.LatLng(pr.lat, pr.lng));
        this.infoRoute.open(this.map);
      }
    }));
    return poly;
  }

  // Devuelve el punto de la ruta más cercano
  getRoutePoint(latLng: any, radio: number) {
    let res: PosicionModel = null;
    let i = 0;
    let index = -1;
    let oldDist = 999999;
    this.posRoute.forEach(pos => {
      const dist = google.maps.geometry.spherical.computeDistanceBetween(
        latLng, new google.maps.LatLng(pos.lat, pos.lng)
      );
      if (dist <= radio && dist < oldDist) {
        oldDist = dist;
        index = i;
      }
      i++;
    });
    if (index > -1) {
      res = this.posRoute[index];
    }
    return res;
  }

  // Encuadra el mapa para que se vea toda la ruta
  fitMapToRoute() {
    // Calculo las coordenadas del cuadro que contiene la ruta
    let lat0 = 90;
    let lng0 = 180;
    let lat1 = -90;
    let lng1 = -180;
    this.posRoute.forEach(pos => {
      if (pos.lat < lat0) {
        lat0 = pos.lat;
      }
      if (pos.lat > lat1) {
        lat1 = pos.lat;
      }
      if (pos.lng < lng0) {
        lng0 = pos.lng;
      }
      if (pos.lng > lng1) {
        lng1 = pos.lng;
      }
    });
    this.fitMap(lat0, lng0, lat1, lng1);
  }

  // Permite encuadrar el mapa
  fitMap(lat0: number, lng0: number, lat1: number, lng1: number) {
    this.map.fitBounds(
      new google.maps.LatLngBounds(
        new google.maps.LatLng(lat0, lng0),
        new google.maps.LatLng(lat1, lng1)
      ));
  }

  // Reproduce una ruta poco a poco
  showRouteStep2Step() {
    if (!this.routePaused) {
      // Inicializo los arrays para las posiciones de ruta y los sensores
      this.posRouteIndex = 0;
      this.posRoute = [];
      this.posSensorIndex = 0;
      this.polyLinesRoute.forEach(poly => {
        poly.setMap(null);
      });
      this.markersSensor.forEach(m => {
        m.setVisible(false);
      });
      this.markersSensor = [];
      this.polyLinesRoute = [];
      this.polyline = null;
      this.setCenter(this.arrayPosRoute[0].lat, this.arrayPosRoute[0].lng);
      this.setIniRuta(this.arrayPosRoute[0]);
    }
    this.routePaused = false;
    this.routeStoped = false;
    this.setRouteInterval();
  }

  // Permite modificar el intervalo entre posiciones
  // a la hora de reproducir paso a paso
  subscribeSetRouteInterval(): void {
    this.mapsService.setRouteIntervalEmiter.subscribe(interval => {
      this.routeInterval = interval;
      if (!this.routePaused && !this.routeStoped) {
        this.setRouteInterval();
      }
    });
  }

  // Temporizador que va pintando poco a poco la ruta
  setRouteInterval() {
    let prevPos: PosicionModel = null;
    if (this.timerRoute > 0) {
      clearInterval(this.timerRoute);
    }
    let mapsBounds = this.map.getBounds();
    let oldColor = '';
    let color = 'green';
    let pos: PosicionModel;
    this.timerRoute = setInterval(() => {
      pos = this.arrayPosRoute[this.posRouteIndex];
      if (this.posRouteIndex < this.arrayPosRoute.length) {
        // Si se sale del area visible centro el mapa
        if (!mapsBounds.contains(new google.maps.LatLng(pos.lat, pos.lng))) {
          this.map.panTo(new google.maps.LatLng(pos.lat, pos.lng));
          mapsBounds = this.map.getBounds();
        }
        // Añado la posición para que se pinte
        this.posRoute.push(pos);
        // Actualizo la posición del coche
        pos.movil.nombre = this.movilRoute.name;
        this.markerVehicle.setPosicion(pos);
        // Obtengo el color de la línea y si ha cambiado creo una polilinea nueva
        color = this.getRouteColor(pos.velocidad);
        if (oldColor !== color) {
          oldColor = color;
          if (this.polyline !== null) {
            this.polyline.getPath().push(new google.maps.LatLng(pos.lat, pos.lng));
          }
          this.polyline = this.newPolyline(pos);
        }
        this.polyline.getPath().push(new google.maps.LatLng(pos.lat, pos.lng));
        // Pinto los sensores que hayan hasta la fecha de esta posición
        while (this.posSensorIndex < this.arraySensores.length &&
          this.arraySensores[this.posSensorIndex].fecha <= pos.fecha) {
          this.markersSensor.push(
            new SensorMarker(this.arraySensores[this.posSensorIndex++], this.map, true));
        }
        // Calculo el tiempo que ha pasado respecto a al anterior posición
        // para ver si hay una parada
        if (prevPos !== null) {
          if (pos.fecha.getTime() - prevPos.fecha.getTime() >= this.timeStop) {
            this.markersStop.push(new StopMarker(prevPos.fecha, pos.fecha,
              prevPos.lat, prevPos.lng, 0, this.map, true));
          }
        }
        prevPos = pos;
        this.posRouteIndex++;
      } else {
        clearInterval(this.timerRoute);
        this.timerRoute = 0;
        this.routeStoped = true;
        this.setFinRuta(this.posRoute[this.posRoute.length - 1]);
        // Pinto los sensores que puedan haber despues de la última posición de ruta
        while (this.posSensorIndex < this.arraySensores.length) {
          this.markersSensor.push(
            new SensorMarker(this.arraySensores[this.posSensorIndex++], this.map, true));
        }
      }
    }, this.routeInterval);
  }

  // Permite borrar una ruta
  subscribeRemoveRoute(): void {
    this.mapsService.removeRouteEmiter.subscribe(info => {
      if (this.timerRoute > 0) {
        clearInterval(this.timerRoute);
        this.timerRoute = 0;
      }
      this.posRoute = [];
      this.routePaused = false;
      this.routeStoped = true;
      this.clearRoute();
    });
  }

  // Permite pausar una ruta
  subscribePauseRoute(): void {
    this.mapsService.pauseRouteEmiter.subscribe(info => {
      if (this.timerRoute > 0) {
        clearInterval(this.timerRoute);
        this.timerRoute = 0;
      }
      this.routePaused = true;
    });
  }

  // Pone el icono de fin deº ruta
  setIniRuta(pos: PosicionModel) {
    this.markersRuta.forEach(m => {
      m.setVisible(false);
    });
    if (this.markerVehicle != null) {
      this.markerVehicle.setVisible(false);
    }
    this.markersRuta = [];
    // Icono del coche
    pos.movil.nombre = this.movilRoute.name;
    pos.icono = this.movilRoute.icon;
    this.markerVehicle = new MovilMarker(pos, this.map, this.mapsService, false);
    this.markerVehicle.setZIndex(999);
    // Bandera de salida
    this.markersRuta.push(new BaseMarker('Inicio ' + Utils.formatTime(pos.fecha)
      , pos.lat, pos.lng, '/assets/images/reproductor/ini-ruta.png', 999, this.map, false));
  }

  // Pone el icono de fin de ruta
  setFinRuta(pos: PosicionModel) {
    // Bandera de fin
    this.markersRuta.push(new BaseMarker('Fin ' + Utils.formatTime(pos.fecha),
      pos.lat, pos.lng, '/assets/images/reproductor/fin-ruta.png', 999, this.map, false));
    // Oculto el marcador del móvil
    this.markerVehicle.setVisible(false);
  }

  // Devuelve el color de la ruta sgún la velocidad
  getRouteColor(velocidad: number): string {
    if (velocidad <= 60) {
      return 'green';
    }
    if (velocidad <= 100) {
      return 'orange';
    }
    return 'red';
  }

  // Pinta los sensores del tirón
  showSensoresRuta() {
    this.markersSensor = [];
    this.arraySensores.forEach(sensor => {
      const marker = new SensorMarker(sensor, this.map, false);
      this.markersSensor.push(marker);
    });
  }

  formatDateTime(date: Date, humanreadable: boolean): string {
    return Utils.formatDateTime(date, true);
  }

  // Activa o desactiva la capa de estado del tráfico
  onChangeTrafic(event: any) {
    this.traficLayer.setMap(this.trafic ? this.map : null);
    Config.storeTraficMap(this.trafic);
  }

  // Permite centrar el mapa en una posición
  subscribeSetCenter(): void {
    this.mapsService.setCenterEmiter.subscribe(pos => {
      this.setCenter(pos.lat(), pos.lng());
    });
  }

  // Permite establecer el nivel de zoom
  subscribeSetZoom(): void {
    this.mapsService.setZoomEmiter.subscribe(zoom => {
      this.setZoom(zoom);
    });
  }

  // Establece el centro del mapa
  setCenter(lat: number, lng: number) {
    this.center.lat = lat;
    this.center.lng = lng;
    this.map.setCenter(new google.maps.LatLng(lat, lng));
  }

  getCenter() {
    return this.center;
  }

  // Establece el nivel de zoom
  setZoom(zoom: number) {
    this.zoom = zoom;
    this.map.setZoom(zoom);
  }

  getZoom() {
    return this.zoom;
  }

  setMapType(type: string) {
    this.mapType = type;
  }

  getType() {
    return this.mapType;
  }

  // Avisa que se ha pulsado sobre el mapa
  onMapClick($event: AGMMouseEvent) {
    this.mapsService.clickEventEmit(
      {
        lat: $event.coords.lat,
        lng: $event.coords.lng,
        elemId: null,
        elemNombre: null
      }
    );
  }

  onBoundsChange(event: any) {
    // recupero las equinas del mapa
    const bound = {
      lat0: event.getSouthWest().lat(),
      lng0: event.getSouthWest().lng(),
      lat1: event.getNorthEast().lat(),
      lng1: event.getNorthEast().lng()
    };
    // Calculo la coordenada central
    const center = {
      lat: bound.lat0 + (bound.lat1 - bound.lat0) / 2,
      lng: bound.lng0 + (bound.lng1 - bound.lng0) / 2
    };
    Config.storeCenterMap(center);
  }

  onZoomChange(event: any) {
    const zoom = event;
    Config.storeZoomMap(zoom);
  }

}
