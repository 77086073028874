export class Idioma {

    static getLocalization(idioma: string): any {
        switch (idioma) {
            case 'es':
                return {
                    percentsymbol: '%',
                    currencysymbol: '$',
                    currencysymbolposition: 'after',
                    decimalseparator: ',',
                    thousandsseparator: '.',
                    pagergotopagestring: 'Ir a pag:',
                    pagershowrowsstring: 'Mostrar filas:',
                    pagerrangestring: ' de ',
                    pagerpreviousbuttonstring: 'anterior',
                    pagernextbuttonstring: 'siguiente',
                    groupsheaderstring: 'Arrastre una columna para agrupar',
                    sortascendingstring: 'Ordenar Acs',
                    sortdescendingstring: 'Ordenar Des',
                    sortremovestring: 'Quitar orden',
                    groupbystring: 'Agrupar por esta columna',
                    groupremovestring: 'Quitar de grupos',
                    filterclearstring: 'Limpiar',
                    filterstring: 'Filtro',
                    filtershowrowstring: 'Mostrar filas donde:',
                    filtershowrowdatestring: 'Mostrar filas donde fecha:',
                    filterorconditionstring: 'O',
                    filterandconditionstring: 'Y',
                    filterselectallstring: '(Seleccionar Todo)',
                    filterchoosestring: 'Por favor seleccione:',
                    filterstringcomparisonoperators: ['vacio', 'no vacio', 'contenga',
                        'contenga(coicidir Mayusculas/Minusculas)',
                        'no contenga', 'no contenga(coincidir Mayusculas/Minusculas)',
                        'inicia con', 'inicia con(coicidir Mayusculas/Minusculas)',
                        'termina con', 'termina con(coicidir Mayusculas/Minusculas)',
                        'igual', 'igual(coicidir Mayusculas/Minusculas)', 'null', 'no null'
                    ],
                    filternumericcomparisonoperators: ['=', '!=', '<', '<=', '>', '>=',
                        'null', 'no null'],
                    filterdatecomparisonoperators: ['=', '!=', '<', '<=', '>', '>=', 'null',
                        'no null'],
                    filterbooleancomparisonoperators: ['=', '!='],
                    validationstring: 'Valor no valido',
                    emptydatastring: 'No hay registros que mostrar',
                    filterselectstring: 'Seleccione un Filtro',
                    loadtext: 'Cargando...',
                    clearstring: 'Limpiar',
                    todaystring: 'hoy',
                    firstDay: 1,
                    days: {
                        names: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves',
                            'Viernes', 'Sabado'],
                        namesAbbr: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
                        namesShort: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
                    },
                    months: {
                        names: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
                            'Augosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', ''],
                        namesAbbr: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago',
                            'Sep', 'Oct', 'Nov', 'Dic', '']
                    },
                    AM: ['AM', 'am', 'AM'],
                    PM: ['PM', 'pm', 'PM'],
                    eras: [
                        { name: 'A.D.', start: null, offset: 0 }
                    ],
                    twoDigitYearMax: 2029,
                    patterns:
                    {
                        d: 'dd/MM/yyyy',
                        D: 'dddd, d. MMMM yyyy',
                        t: 'HH:mm',
                        T: 'HH:mm:ss',
                        f: 'dddd, d. MMMM yyyy HH:mm',
                        F: 'dddd, d. MMMM yyyy HH:mm:ss',
                        M: 'dd MMMM',
                        Y: 'MMMM yyyy'
                    },
                };
                break;
        }
        return '';
    }
}
