<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="'Procesando...'">
</jqxLoader>
<jqxWindow #formAlarmas [width]="888" [height]="532" [zIndex]="50" [autoOpen]="true" [showCloseButton]="true"
    [showCollapseButton]="true" [resizable]="false">
    <div class="formHeader" style="overflow-x: hidden;">
        Gestión de alarmas
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="float: left; width: 200px; height: 440px;">
            <div style="float: left;">
                <jqxDropDownList #cbGrupos style="float:left;" [width]="200" [height]="26" [selectedIndex]="0"
                    (onSelect)="onChangeGrupos($event)"></jqxDropDownList>
            </div>
            <div style="float: left;">
                <jqxButton #btnTodos [width]='80' [height]='25' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/sensores/todos.png"'
                    (onClick)="onTodosClick($event)">
                    Marcar
                </jqxButton>
            </div>
            <div style="float: left; margin-left: 2px;">
                <jqxButton #btnNinguno [width]='100' [height]='25' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/sensores/ninguno.png"'
                    (onClick)="onNingunoClick($event)">
                    Desmarcar
                </jqxButton>
            </div>
            <jqxListBox #lbMoviles [height]="'100%'" [width]="'100%'" [valueMember]="'id'" [checkboxes]="true"
                (onSelect)="onSelectMovil($event)">
            </jqxListBox>
        </div>
        <div style="float:left; height: 440px; margin-left: 5px;">
            <jqxGrid #grid [width]="670" [source]="dataAdapterAlarmas" [pagesize]="10" [columnsresize]="true"
                [localization]="localization" [filterable]="true" [showfilterrow]="true" [columns]="columnsAlarmas"
                [pageable]="true" [autoheight]="true" [sortable]="true" [altrows]="true" [enabletooltips]="true"
                [editable]="false" [scrollmode]="'deferred'" (onRowclick)="onRowAlarmaSelect($event)">
            </jqxGrid>
            <div style="float: left; margin-top: 6px;">
                <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/mapa/ver.png"'
                    (onClick)="onCentrarAlarma($event)">
                    Centrar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"'
                    (onClick)="onCrearAlarmaClick($event)" [hidden]="!canEdit">
                    Crear
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                    [imgSrc]='"/assets/images/editar.png"' (onClick)="onEditarAlarmaClick($event)">
                    Editar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                    [imgSrc]='"/assets/images/borrar.png"' (onClick)="onBorrarAlarmaClick($event)" [hidden]="!canEdit">
                    Borrar
                </jqxButton>
            </div>
        </div>
    </div>
</jqxWindow>

<div *ngIf="mostrarEditForm">
    <jqxWindow #formEdit [width]="453" [height]="460" [zIndex]="100" [autoOpen]="true" [isModal]="false"
        [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onCloseEdit()"
        (onOpen)="onOpenEditAlarma()" (onExpand)="onExpandEditAlarma()">
        <div class="formHeader" style="overflow-x: hidden;">
            Edición de alarmas
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="margin-top: 5px; clear: both;">
                Tipo
                <jqxDropDownList #cbTipos [width]="438" [height]="25" [selectedIndex]="0"
                    (onSelect)="onChangeTipoAlarma($event)">
                </jqxDropDownList>
            </div>
            <div *ngIf="tipoAlarma > 0">
                <div style="margin-top: 5px; clear: both;">
                    Área de gestión
                    <jqxDropDownList #cbAreas style="float:left" [width]="395" [height]="25" [selectedIndex]="0"
                        (onSelect)="onChangeArea($event)">
                    </jqxDropDownList>
                    <jqxButton style="float:left; margin-left: 2px;" [width]='40' [height]='25'
                        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                        [imgSrc]='"/assets/images/mapa/ver.png"' (onClick)="onVerAreaClick($event)">
                    </jqxButton>
                </div>
                <div style="margin-top: px; clear: both;">
                    Zona de control
                    <jqxDropDownList #cbZonas style="float:left" [width]="395" [height]="25" [selectedIndex]="0"
                        (onSelect)="onChangeZona($event)">
                    </jqxDropDownList>
                    <jqxButton style="float:left; margin-left: 2px;" [width]='40' [height]='25'
                        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                        [imgSrc]='"/assets/images/mapa/ver.png"' (onClick)="onVerZonaClick($event)">
                    </jqxButton>
                </div>
            </div>
            <div style="margin-top: 5px; float:left; clear:both;">
                Nombre
            </div>
            <div style="float:left; clear:both;">
                <input #ebNombre [(ngModel)]="alarmaEdit.nombre" type='text' style="width: 430px; "
                    [disabled]="!canEdit" />
            </div>
            <div style="margin-top: 10px; float:left;">
                <input [(ngModel)]="alarmaEdit.entrada" type="checkbox" (change)="onChangeCtrlEntrada($event)"
                    [disabled]="!canEdit" /> Controlar entrada
            </div>
            <div style="float: right; margin-top: 10px; margin-right: 5px;">
                <jqxDateTimeInput #horaEntrada [(ngModel)]="alarmaEdit.horaEntrada" [width]="95" [height]="22"
                    [dropDownVerticalAlignment]="'top'" [formatString]="'HH:mm:ss'" [showTimeButton]="true"
                    [showCalendarButton]="false"
                    [disabled]="!alarmaEdit.entrada || !alarmaEdit.hayHoraEntrada || !canEdit">
                </jqxDateTimeInput>
            </div>
            <div style="margin-top: 10px; float:right; margin-right: 5px;">
                Hora máxima
                <input [(ngModel)]="alarmaEdit.hayHoraEntrada" type="checkbox" (change)="onChangeHoraEntrada($event)"
                    [disabled]="!alarmaEdit.entrada || !canEdit" />
            </div>
            <div style="margin-top: 10px; float:left; clear: both">
                <input [(ngModel)]="alarmaEdit.salida" type="checkbox" (change)="onChangeCtrlSalida($event)"
                    [disabled]="!canEdit" /> Controlar salida
            </div>
            <div style="float: right; margin-top: 10px; margin-right: 5px;">
                <jqxDateTimeInput #horaSalida [(ngModel)]="alarmaEdit.horaSalida" [width]="95" [height]="22"
                    [dropDownVerticalAlignment]="'top'" [formatString]="'HH:mm:ss'" [showTimeButton]="true"
                    [showCalendarButton]="false"
                    [disabled]="!alarmaEdit.salida || !alarmaEdit.hayHoraSalida || !canEdit">
                </jqxDateTimeInput>
            </div>
            <div style="margin-top: 10px; margin-right: 5px; float:right;">
                Hora máxima
                <input [(ngModel)]="alarmaEdit.hayHoraSalida" type="checkbox" (change)="onChangeHoraSalida($event)"
                    [disabled]="!alarmaEdit.salida || !canEdit" />
            </div>
            <div style="margin-top: 14px; float:left; clear: both">
                <input [(ngModel)]="alarmaEdit.repetir" type="checkbox" (change)="onChangeRepetir($event)"
                    [disabled]="!canEdit" /> Repetir
            </div>
            <div style="float: left; margin-left: 15px;">
                <div style="float:left; clear: both;">
                    <div style="float:left; margin-left: 7px;">L</div>
                    <div style="float:left; margin-left: 9px;">M</div>
                    <div style="float:left; margin-left: 9px;">X</div>
                    <div style="float:left; margin-left: 10px;">J</div>
                    <div style="float:left; margin-left: 10px;">V</div>
                    <div style="float:left; margin-left: 9px;">S</div>
                    <div style="float:left; margin-left: 9px;">D</div>
                </div>
                <div style="float:left; clear: both;">
                    <input [(ngModel)]="dias.lunes" type="checkbox" style="float:left;"
                        [disabled]="!alarmaEdit.repetir || !canEdit" />
                    <input [(ngModel)]="dias.martes" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.repetir || !canEdit" />
                    <input [(ngModel)]="dias.miercoles" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.repetir || !canEdit" />
                    <input [(ngModel)]="dias.jueves" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.repetir || !canEdit" />
                    <input [(ngModel)]="dias.viernes" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.repetir || !canEdit" />
                    <input [(ngModel)]="dias.sabado" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.repetir || !canEdit" />
                    <input [(ngModel)]="dias.domingo " type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.repetir || !canEdit" />
                </div>
            </div>
            <div style="margin-top: 5px; float:left; clear:both; ">
                Lista de E-mails (separados por ;)
            </div>
            <div style="float:left; clear: both; ">
                <input [(ngModel)]="alarmaEdit.emails" type='text' style="width: 430px; " [disabled]="!canEdit" />
            </div>
            <div style="margin-top: 5px; float:left; clear:both; ">
                Observaciones
            </div>
            <div style="float:left; clear: both; ">
                <input [(ngModel)]="alarmaEdit.observ" type='text' style="width: 430px;" [disabled]="!canEdit" />
            </div>
            <div *ngIf="tipoAlarma==0">
                <div style="margin-top: 5px; float:left; clear:both; ">
                    Radio de acción (metros)
                </div>
                <div style="float:left; clear: both;">
                    <input [(ngModel)]="alarmaEdit.radio" type='number' style="width: 180px;" min="0" max="50000"
                        [disabled]="!canEdit" />
                </div>
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both;">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"'
                    (onClick)="onGuardarAlarmaClick($event)" [disabled]="!canEdit">
                    Guardar
                </jqxButton>
                <div *ngIf="tipoAlarma==0" style="float:left">
                    <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                        [imgSrc]='"/assets/images/centrar.png"' (onClick)="onPosicionarClick($event)"
                        [disabled]="!canEdit">
                        Posicionar
                    </jqxButton>
                </div>
            </div>
        </div>
    </jqxWindow>
</div>