// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // TSU (Urgencias)
  // tipoGes: 'TSU',
  // urlApi: 'https://geocan.accionaservice.com:6002',
  // urlApiGes: 'https://localhost:44341',
  // idEmpresa: 720,

  // TSNU (Programado)
  // tipoGes: 'TSNU',
  // urlApi: 'https://geocanprog.accionaservice.com:6003',
  // urlApiGes: 'https://geocanprog.accionaservice.com:6004',
  // idEmpresa: 728,

  // Servidor de pruebas
  tipoGes: 'TSNU', // TSU/TSNU
  urlApi: 'https://prueba.ambu.movisat.com:6003',
  urlApiGes: 'https://prueba.ambu.movisat.com:6004',
  idEmpresa: 709,

  // Servidor de pruebas con API local en depuración
  // tipoGes: 'TNSU', // TSU/TSNU
  // urlApi: 'https://prueba.ambu.movisat.com:6003',
  // urlApiGes: 'https://localhost:44341',
  // idEmpresa: 1,

  // Coordenadas donde se centra el mapa por primera vez
  lat: 28.309536,
  lng: -15.593749,
  zoom: 8,

  // Área restringida para búsquedas sobre el mapa
  latSW: 27.545643,
  lngSW: -18.371813,
  latNE: 29.431456,
  lngNE: -13.296130,

  grant_type: 'password',
  client_id: 'MJXGWFRCKI',
  client_secret: '083F07174F49A8D65FF40BA91A622122',
  username: 'android2',
  password: 'api2014Movisat',
  apiKey: 'AIzaSyA5gkvX4XR8KN6KnhW72rMvfrmjkaACehM',
  md5Key: 'Movisat@2021',
  apiToken: 'QtS9scSBCFC8kdHHGV7GKBe6OV5zVYDCW0eau2G'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
