import { Injectable, EventEmitter, Output } from '@angular/core';
import { PosicionModel } from '../posiciones/modelos/posicion-model';
import { MovilModel } from '../recursos/modelos/movil-model';
import { SensorModel } from '../sensores/modelos/sensor-model';
import { AreaModel } from '../areas/modelos/area-model';
import { LatLng } from '@agm/core';
import { UsuarioModel } from '../login/modelos/usuario-model';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  constructor() { }

  // El mapa está subscrito a estos eventos
  @Output() centerMovilEmiter: EventEmitter<number> = new EventEmitter();
  @Output() centerMovilesEmiter: EventEmitter<number[]> = new EventEmitter();
  @Output() centerAreaEmiter: EventEmitter<number> = new EventEmitter();
  @Output() showUsuariosEmiter: EventEmitter<boolean> = new EventEmitter();
  @Output() showRouteEmiter: EventEmitter<any> = new EventEmitter();
  @Output() removeRouteEmiter: EventEmitter<number> = new EventEmitter();
  @Output() pauseRouteEmiter: EventEmitter<number> = new EventEmitter();
  @Output() setRouteIntervalEmiter: EventEmitter<number> = new EventEmitter();
  @Output() showMovilesEmiter: EventEmitter<boolean> = new EventEmitter();
  @Output() showAreasEmiter: EventEmitter<boolean> = new EventEmitter();
  @Output() addAreasEmiter: EventEmitter<AreaModel[]> = new EventEmitter();
  @Output() clickEmiter: EventEmitter<any> = new EventEmitter();
  @Output() addAreaEmiter: EventEmitter<AreaModel> = new EventEmitter();
  @Output() updateAreaEmiter: EventEmitter<AreaModel> = new EventEmitter();
  @Output() removeAreaEmiter: EventEmitter<AreaModel> = new EventEmitter();
  @Output() addMovilSeguimientoEmiter: EventEmitter<MovilModel> = new EventEmitter();
  @Output() removeMovilSeguimientoEmiter: EventEmitter<MovilModel> = new EventEmitter();
  @Output() verMovilSeguimientoEmiter: EventEmitter<MovilModel> = new EventEmitter();
  @Output() changeTabsEmiter: EventEmitter<number> = new EventEmitter();
  @Output() navegarMovilEmiter: EventEmitter<number> = new EventEmitter();
  @Output() borrarItinerarioEmiter: EventEmitter<void> = new EventEmitter();
  @Output() navegarMovilCercanoEmiter: EventEmitter<number[]> = new EventEmitter();
  @Output() setCenterEmiter: EventEmitter<LatLng> = new EventEmitter();
  @Output() setZoomEmiter: EventEmitter<number> = new EventEmitter();
  @Output() escapeKeyPressEmitter: EventEmitter<void> = new EventEmitter();
  @Output() editUsuarioEmiter: EventEmitter<UsuarioModel> = new EventEmitter();
  @Output() centerPosEmiter: EventEmitter<PosicionModel> = new EventEmitter();

  centerMoviles(movil: number[]) {
    this.centerMovilesEmiter.emit(movil);
  }

  centerMovil(movil: number) {
    this.centerMovilEmiter.emit(movil);
  }

  centerArea(areaId: number) {
    this.centerAreaEmiter.emit(areaId);
  }

  showRoute(movil: MovilModel, fromDate: Date, toDate: Date,
    step2step: boolean, iconoMovil: string, arrayPos: PosicionModel[],
    arraySensor: SensorModel[], distance: number) {
    this.showRouteEmiter.emit(
      {
        movilId: movil.id,
        movilName: movil.nombre,
        from: fromDate,
        to: toDate,
        step: step2step,
        icon: iconoMovil,
        pos: arrayPos,
        sensor: arraySensor,
        km: distance
      });
  }

  removeRoute(movil: MovilModel) {
    this.removeRouteEmiter.emit(movil.id);
  }

  pauseRoute(movil: MovilModel) {
    this.pauseRouteEmiter.emit(movil.id);
  }

  setRouteInterval(interval: number) {
    this.setRouteIntervalEmiter.emit(interval);
  }

  showMoviles(show: boolean) {
    this.showMovilesEmiter.emit(show);
  }

  showAreas(show: boolean) {
    this.showAreasEmiter.emit(show);
  }

  addElements(elements: AreaModel[]) {
    this.addAreasEmiter.emit(elements);
  }

  clickEventEmit(params: any) {
    this.clickEmiter.emit(params);
  }

  // Permite añadir una nueva área al mapa
  addArea(area: AreaModel) {
    this.addAreaEmiter.emit(area);
  }

  // Permite modificar los datos del área en el mapa
  updateArea(area: AreaModel) {
    this.updateAreaEmiter.emit(area);
  }

  // Permite borrar un área del mapa
  removeArea(area: AreaModel) {
    this.removeAreaEmiter.emit(area);
  }

  // Permite añadir un móvil a la lista de móviles en seguimiento
  addMovilSegimiento(movil: MovilModel) {
    this.addMovilSeguimientoEmiter.emit(movil);
  }

  // Permite quitar un móvil de la lista de móviles en seguimiento
  removeMovilSegimiento(movil: MovilModel) {
    this.removeMovilSeguimientoEmiter.emit(movil);
  }

  // Permite ver la posición de un móvil en la cartografía de seguimiento
  verMovilSegimiento(movil: MovilModel) {
    this.verMovilSeguimientoEmiter.emit(movil);
  }

  // Permite indicar que ha cambiado la pestaña de cartografía
  changeTabsCarto(tab: number) {
    this.changeTabsEmiter.emit(tab);
  }

  // Permite poner la posición de un movil como origen de un itinerario
  navegarMovil(movil: number) {
    this.navegarMovilEmiter.emit(movil);
  }

  // Permite borrar un itinerario
  borrarItinerario() {
    this.borrarItinerarioEmiter.emit();
  }

  // Permite pasar una lista de móviles para posicionar como origen de un
  // itinerario el más cercano al destino
  navegarMovilCercano(moviles: number[]) {
    this.navegarMovilCercanoEmiter.emit(moviles);
  }

  // Permite establecer el centro del mapa
  setCenter(center: LatLng) {
    this.setCenterEmiter.emit(center);
  }

  // Permite centrar el mapa en una posición
  centerPos(pos: PosicionModel) {
    this.centerPosEmiter.emit(pos);
  }

  // Permite establecer el zoom
  setZoom(zoom: number) {
    this.setZoomEmiter.emit(zoom);
  }

  // Notifica que se ha pulsado la tecla ESC
  escapeKeyPressed() {
    this.escapeKeyPressEmitter.emit();
  }

  // Cada vez que el usuario pincha en botón editar de un usuario
  editUserEventEmit(usuario: UsuarioModel) {
    this.editUsuarioEmiter.emit(usuario);
  }

}
