import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReproductorComponent } from './reproductor.component';
import { ReproductorService } from './reproductor.service';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxSliderModule } from 'jqwidgets-ng/jqxslider';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';

@NgModule({
  declarations: [ReproductorComponent],
  imports: [
    CommonModule,
    jqxWindowModule,
    jqxDateTimeInputModule,
    jqxSliderModule,
    jqxLoaderModule
  ],
  exports: [
    ReproductorComponent
  ],
  providers: [
    ReproductorService
  ]
})
export class ReproductorModule { }
