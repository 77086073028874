import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login/login.service';
import { MapsService } from 'src/app/maps/maps.service';
import { PosicionModel } from 'src/app/posiciones/modelos/posicion-model';
import { PosicionesService } from 'src/app/posiciones/posiciones.service';
import { MovilModel } from 'src/app/recursos/modelos/movil-model';
import { RecursosService } from 'src/app/recursos/recursos.service';
import { SensoresService } from 'src/app/sensores/sensores.service';
import { Config } from 'src/app/utils/config';
import { Idioma } from 'src/app/utils/idioma';
import { PeriodoSelectComponent, PeriodoSelectTipo } from 'src/app/utils/periodo-select/periodo-select.component';
import { Utils } from 'src/app/utils/utils';

declare var google: any;

@Component({
  selector: 'app-informe-detalle',
  templateUrl: './informe-detalle.component.html',
  styleUrls: ['./informe-detalle.component.css']
})
export class InformeDetalleComponent implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('cbMoviles') cbMoviles: jqxDropDownListComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;

  // Variables para filtrado
  public fechaIni: Date;
  public fechaFin: Date;

  // Variables para generar el listado
  private moviles: MovilModel[] = [];
  private movilesInforme: MovilModel[] = [];
  private posiciones: PosicionModel[] = [];
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);
  private rowSelec = -1;

  // Preparo las columnas del grid
  public columns: any[];
  public columngroups = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public localization = Idioma.getLocalization('es');

  constructor(
    private loginService: LoginService,
    private recursosService: RecursosService,
    private posicionesService: PosicionesService,
    private sensoresService: SensoresService,
    private toastrService: ToastrService,
    private mapService: MapsService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.periodoSelect.setPeriodo(PeriodoSelectTipo.DIA);
      this.fechaIni = this.periodoSelect.getFechaIni();
      this.fechaFin = this.periodoSelect.getFechaFin();
    }, 500);
    this.getMoviles();
    this.preparaListado();
  }

  getMoviles() {
    // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
    const filter = Config.getMovilesFilter([{ id: -1 }]);
    let all = false;
    if (filter.find(s => s.id === -1)) {
      all = true;
    }
    const item = {
      id: 0,
      html: '<div style="height: 20px; float: left;">' +
        '<span style="float: left; font-size: 13px;">' + 'Todos' + '</span></div>'
    };
    this.cbMoviles.addItem(item);
    this.cbMoviles.selectIndex(0);
    this.recursosService.getMoviles(this.loginService.getEmpresa()).then(
      response => {
        // this.moviles = response;
        this.movilesInforme = [];
        if (response !== undefined && response != null) {
          response.forEach(movil => {
            if ((all || filter.find(s => s.id === movil.id)) && movil.gls !== 'AN') {
              this.moviles.push(movil);
              this.rellenaMovil(movil);
            }
          });
        }
      },
      err => {
        console.log(err);
      });
  }

  rellenaMovil(movil: MovilModel) {
    this.movilesInforme.push(movil);
    const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
      'src="data:image/jpg;base64,' + movil.icono + '"/>';
    const item = {
      id: movil.id,
      html: '<div style="height: 20px; float: left;">' + icono +
        '<span style="float: left; font-size: 13px;">' + movil.nombre + '</span></div>'
    };
    this.cbMoviles.addItem(item);
  }

  preparaListado() {
    this.columns = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 40, },
      { text: 'Recurso', columntype: 'textbox', filtertype: 'textbox', datafield: 'movil', width: 180 },
      { text: 'Matrícula', columntype: 'textbox', filtertype: 'textbox', datafield: 'matricula', width: 80 },
      { text: 'Tipo de vehículo', columntype: 'textbox', filtertype: 'textbox', datafield: 'tipoVehiculo', width: 150 },
      { text: 'Fecha', columntype: 'date', filtertype: 'date', datafield: 'fecha', cellsformat: 'dd/MM/yy HH:mm:ss', width: 140 },
      { text: 'Localización', columntype: 'textbox', filtertype: 'textbox', datafield: 'direccion', width: 200 },
      { text: 'Latitud', columntype: 'textbox', filtertype: 'textbox', datafield: 'lat', width: 70 },
      { text: 'Longitud', columntype: 'textbox', filtertype: 'textbox', datafield: 'lng', width: 70 },
      { text: 'Vel (km/h)', columntype: 'textbox', filtertype: 'textbox', datafield: 'velocidad', width: 80 },
      { text: 'Altura (m.)', columntype: 'textbox', filtertype: 'textbox', datafield: 'altura', width: 80 },
      { text: 'Rumbo', columntype: 'textbox', filtertype: 'textbox', datafield: 'rumbo', width: 60 },
      { text: 'Ignición', columntype: 'checkbox', filtertype: 'textbox', datafield: 'ignicion', width: 70 },
      { text: 'Sirena', columntype: 'checkbox', filtertype: 'textbox', datafield: 'sirena', width: 60 },
      { text: 'P.Luces', columntype: 'checkbox', filtertype: 'textbox', datafield: 'puenteLuces', width: 60 },
    ];
  }

  onAceptar(event: any) {
    this.fechaIni = this.periodoSelect.getFechaIni();
    this.fechaFin = this.periodoSelect.getFechaFin();
    const moviles: number[] = [];
    if (this.cbMoviles.getSelectedItem().value.id < 1) {
      this.movilesInforme.forEach(movil => {
        moviles.push(movil.id);
      });
    } else {
      moviles.push(this.cbMoviles.getSelectedItem().value.id);
    }
    let periodo = 0;
    switch (this.periodoSelect.getPeriodo()) {
      case 0:
        periodo = 1; // Dia
        break;
      case 1:
        periodo = 2; // Semana
        break;
      case 2:
        periodo = 3; // Mes
        break;
      case 5:
        periodo = 4; // Año
        break;
    }
    this.loader.open();
    this.posiciones = [];
    let flag = false;
    moviles.forEach(async (movilId) => {
      const response = await this.posicionesService.getPosicionesMovil(movilId, this.fechaIni, this.fechaFin);
      if (response) {
        const sensores = await this.sensoresService.getSensoresMovil(movilId, this.fechaIni, this.fechaFin);
        response.forEach(pos => {
          if (sensores) {
            for (let i = 0; i < sensores.length; i++) {
              if (sensores[i].fecha.getTime() > pos.fecha.getTime()) {
                break;
              }
              switch (sensores[i].sensor) {
                case 16:
                  pos.ignicion = sensores[i].lectura > 0;
                  break;
                case 1:
                  pos.sirena = sensores[i].lectura > 0;
                  break;
                case 2:
                  pos.puenteLuces = sensores[i].lectura > 0;
                  break;
              }
            }
          }
          this.posiciones.push(pos);
        });
        if (!flag) {
          this.generaInforme();
          flag = true;
        } else {
          this.grid.updatebounddata('data');
        }
      }
    });
    this.loader.close();
  }

  // Genera el informe con los datos obtenidos
  generaInforme() {
    this.preparaListado();
    this.source = {
      datatype: 'json',
      datafields: [
        { name: 'id', map: 'movil>id', type: 'int' },
        { name: 'movil', map: 'movil>nombre', type: 'string' },
        { name: 'matricula', map: 'movil>matricula', type: 'string' },
        { name: 'tipoVehiculo', map: 'movil>tipoVehiculo', type: 'string' },
        { name: 'fecha', type: 'date' },
        { name: 'direccion', type: 'string' },
        { name: 'lat', type: 'number' },
        { name: 'lng', type: 'number' },
        { name: 'velocidad', type: 'number' },
        { name: 'altura', type: 'number' },
        { name: 'rumbo', type: 'rumbo' },
        { name: 'ignicion', type: 'boolean' },
        { name: 'sirena', type: 'boolean' },
        { name: 'puenteLuces', type: 'boolean' },
      ],
      localdata: this.posiciones
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);
  }

  onExportar(event: any) {
    this.grid.exportdata('xls', Utils.formatDateAMDhms(new Date()) + '_Informe_Movilidad');
  }

  onRowSelect(event: any) {
    this.rowSelec = event.args.rowindex;
  }

  onCentrarClick(event: any) {
    if (this.rowSelec > -1) {
      this.form.collapse();
      this.mapService.centerPos(this.posiciones[this.rowSelec]);
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

}
