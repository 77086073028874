import { AreaModel } from '../../areas/modelos/area-model';
import { AreaZonaModel } from '../../areas/modelos/area-zona-model';
import { MovilModel } from '../../recursos/modelos/movil-model';

export class AlarmaModel {
    id: number;
    nombre: string;           // Descripción de la alarma
    movil: MovilModel;        // Móvil al que se asigna
    entrada: boolean;         // Si es de entrada
    salida: boolean;          // Si es de salida
    hayHoraEntrada: boolean;  // Si hay que controlar la hora de entrada
    hayHoraSalida: boolean;   // Si hay que controlar la hora de salida
    horaEntrada: Date;        // Hora máxima de entrada
    horaSalida: Date;         // Hora máxima de salida
    repetir: boolean;         // Si hay que repetir diariamente o sólo una vez y se borra
    dias: string;             // Los días de la semana que está activa -> LMXJVSD
    area?: AreaModel;         // Si es sobre un área de gestión
    areaZona?: AreaZonaModel; // Si es sobre una zona dentro de un área de gestión
    radio: number;            // Si se trata de un ponto sobre la cartografía
    lat: number;              // Las coordenadas del punto
    lng: number;
    observ: string;           // Observaciones
    emails: string;           // Lista de e-mails separador por punto y coma
    fAlta: Date;
    fMod: Date;
    fBaja: Date;
    // Sólo para llamadas a la API
    IdMovil: number;
    IdArea: number;
    IdAreaZona: number;

    constructor(id: number, nombre: string, movil: MovilModel,
        entrada: boolean, salida: boolean,
        horaEntrada: Date, horaSalida: Date, repetir: boolean, dias: string,
        area: AreaModel, areaZona: AreaZonaModel, radio: number, lat: number,
        lng: number, observ: string, emails: string, fAlta: Date, fMod: Date, fBaja: Date) {
        this.id = id;
        this.movil = movil;
        this.nombre = nombre;
        this.entrada = entrada;
        this.salida = salida;
        this.horaEntrada = entrada ? horaEntrada : null;
        this.horaSalida = salida ? horaSalida : null;
        this.repetir = repetir;
        this.dias = repetir ? dias : '';
        this.area = area !== undefined ? area : null;
        this.areaZona = areaZona !== undefined ? areaZona : null;
        this.radio = radio;
        this.lat = lat;
        this.lng = lng;
        this.observ = observ;
        this.emails = emails;
        this.fAlta = fAlta;
        this.fMod = fMod;
        this.fBaja = fBaja;
        // Estos campos sólo se usan para la interfaz
        this.hayHoraEntrada = (this.entrada && this.horaEntrada !== null);
        this.hayHoraSalida = (this.salida && this.horaSalida !== null);
    }
}
