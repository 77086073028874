import {
  Component, OnInit, AfterViewInit, ViewChild, OnDestroy, NgZone
} from '@angular/core';
import { AgmMap, LatLng } from '@agm/core';
import { MouseEvent as AGMMouseEvent } from '@agm/core';
import { PosicionesService } from '../../posiciones/posiciones.service';
import { MapsService } from '../maps.service';
import { LoginService } from '../../login/login.service';
import { Config } from '../../utils/config';
import { AreasService } from '../../areas/areas.service';
import { AreaTipoModel } from '../../areas/modelos/area-tipo-model';
import { AreaMarker } from '../modelos/area-marker';
import { MovilMarker } from '../modelos/movil-marker';
import { RecursosService } from '../../recursos/recursos.service';
import { AreaModel } from 'src/app/areas/modelos/area-model';
import { UsuarioModel } from '../../login/modelos/usuario-model';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { BaseMarker } from '../modelos/base-marker';
import { MovilesComponent } from 'src/app/recursos/moviles/moviles.component';

declare var google: any;
declare var MarkerClusterer: any;

enum Animation {
  BOUNCE = 1,
  DROP = 2
}

@Component({
  selector: 'app-google-map-iti',
  templateUrl: './google-map-iti.component.html',
  styleUrls: ['./google-map-iti.component.css']
})

export class GoogleMapItiComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('AgmMap') AgmMap: AgmMap;
  @ViewChild('search', { static: false }) search;
  @ViewChild('search2', { static: false }) search2;
  @ViewChild('formMovilesCerca') formMovilesCerca: jqxWindowComponent;
  @ViewChild('lbMoviles') lbMoviles: jqxListBoxComponent;

  private map: any; // El mapa
  protected geoCoder: any; // Para busquedas sobre el mapa
  private directionsService: any;
  private directionsDisplay: any;
  private traficLayer: any; // Para ver el estado del tráfico
  private calculando = false;

  private INTERVAL_POS_MOVILES = 30000; // Intervalo de refresco posiciones móviles

  // Usuario actual
  private usuActual: UsuarioModel;

  // Variables para posiciones de moviles
  private timerMoviles = null;
  private markersMoviles: MovilMarker[] = [];
  private markersMovilesIndex = new Map<number, number>();

  // Variables para calculo de itinerarios
  private origen: any = null;
  private destino: any = null;
  public origenFocused = false;
  public destinoFocused = false;

  // Variables para las areas
  private markersAreas: AreaMarker[] = [];
  private markersAreasIndex = new Map<number, number>();
  private tiposAreas: AreaTipoModel[] = [];
  private infoArea: any = null;

  // Variables para drag & drop
  private static dragover = false;

  // Cluster para areas
  private clusterAreas: any;

  // Variables para calcular el móvil más cercano a un sitio
  private movilMasCerca = {
    semaforo: false,
    movilesTotales: 0,
    movilesCalculados: 0,
    movilSelecionado: 0,
    tiempo: 0,
    distancia: 0
  };

  // Variables para el mapa
  public trafic = Config.getTraficMap(false);

  // Configuración por defecto del mapa
  private mapType = 'roadmap';
  private zoom = environment.zoom;
  private center = {
    lat: environment.lat,
    lng: environment.lng
  };

  constructor(
    private ngZone: NgZone,
    private posicionesService: PosicionesService,
    private mapsService: MapsService,
    private loginService: LoginService,
    private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.usuActual = this.loginService.getUser();
    // Obtengo el último encuadre, para ello recupero el último centro y el zoom
    this.center = Config.getCenterMap(this.center);
    this.zoom = Config.getZoomMap(this.zoom);
  }

  ngOnDestroy(): void {
    // finalizo temporizadores
    if (this.timerMoviles !== undefined && this.timerMoviles !== null) {
      clearInterval(this.timerMoviles);
    }
    // Destruyo objetos del mapa
    this.markersMoviles.forEach(m => {
      m.setVisible(false);
    });
  }

  ngAfterViewInit(): void {
    this.AgmMap.mapReady.subscribe(map => {
      // Obtengo el manejador del mapa
      this.map = map;
      // Quito los puntos de interes del mapa
      this.map.setOptions({
        zoomControlOptions: {
          style: google.maps.ControlPosition.small,
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        styles: [ // Oculto los puntos de interes
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      });
      // Creo la capa de tráfico para poder activar o desactivar
      this.traficLayer = new google.maps.TrafficLayer();
      this.traficLayer.setMap(this.trafic ? this.map : null);
      // Preparo la búsqueda de direcciones en el mapa
      this.geoCoder = new google.maps.Geocoder();
      // Restrinjo el área de busqueda preferido
      const sw = new google.maps.LatLng(environment.latSW, environment.lngSW);
      const ne = new google.maps.LatLng(environment.latNE, environment.lngNE);
      const searchBounds = new google.maps.LatLngBounds(sw, ne);
      const options = {
        bounds: searchBounds,
        types: ['geocode'],
        componentRestrictions: { country: 'es' }
      };
      const autocomplete = new google.maps.places.Autocomplete(
        this.search.nativeElement, options);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          if (place.geometry !== undefined && place.geometry !== null) {
            this.origen = place.geometry.location;
          }
        });
      });
      // Restrinjo el área de busqueda preferido
      const autocomplete2 = new google.maps.places.Autocomplete(
        this.search2.nativeElement, options);
      autocomplete2.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = autocomplete2.getPlace();
          if (place.geometry !== undefined && place.geometry !== null) {
            this.destino = place.geometry.location;
            this.calcRoute(this.origen, this.destino);
          }
        });
      });
      // Cluster para las areas
      this.clusterAreas = new MarkerClusterer(this.map, null, {
        imagePath: 'assets/images/mapa/cluster/m',
        maxZoom: 18,
        ignoreHidden: true
      });

      google.maps.event.addListener(this.map, 'mouseover', (event: any) => {
        if (GoogleMapItiComponent.dragover) {
          if (MovilesComponent.movilSelec > 0) {
            this.origen = PosicionesService.lastPos.get(MovilesComponent.movilSelec);
            this.destino = event.latLng;
            this.calcRoute(this.origen, this.destino);
          }
        }
        GoogleMapItiComponent.dragover = false;
      });

      document.addEventListener("dragstart", (event: any) => {
        GoogleMapItiComponent.dragover = false;
      });

      document.addEventListener("dragover", (event: any) => {
        if (event.currentTarget.activeElement.className.includes('jqx-listbox')) {
          GoogleMapItiComponent.dragover = true;
        }
      });

      // Preparo los servicios de Google para calculo de itinererios
      this.directionsService = new google.maps.DirectionsService();
      this.directionsDisplay = new google.maps.DirectionsRenderer();
      this.directionsDisplay.setMap(this.map);
      // Eventos a los que se subscribe el mapa
      this.subscribeCenterMovil();
      this.subscribeCenterArea();
      this.subscribeShowMoviles();
      this.subscribeShowAreas();
      this.subscribeAddAreas();
      this.subscribeAddArea();
      this.subscribeUpdateArea();
      this.subscribeRemoveArea();
      this.subscribeNavegarMovil();
      this.subscribeBorrarItinerario();
      this.subscribeNavegarMovilCercano();
      // Pinto las posiciones de los móviles y las areas
      this.showMoviles();
      this.showAreas();
    });
  }

  // Situo el formulario en la pate superior para que no estorbe
  getFormPos(): any {
    const mapContainer = document.getElementById('centerLayout').getClientRects();
    const offset = document.getElementById('centerLayout').offsetHeight;
    return {
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 74
    };
  }

  private calcRoute(origen: LatLng, destino: LatLng) {
    if ((this.origen !== null && this.destino !== null) && (this.origen.lat !== this.destino.lat ||
      this.origen.lng !== this.destino.lng)) {
      this.directionsDisplay.setMap(null);
      this.directionsDisplay.setMap(this.map);
      this.directionsDisplay.setPanel(document.getElementById('infoIti'));
      this.directionsService.route({
        origin: origen,
        destination: destino,
        travelMode: google.maps.TravelMode.DRIVING,
      }, (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.directionsDisplay.setDirections(response);
        } else {
          this.toastrService.warning('No se pudo calcular el itinerario. ' + status, 'ATENCIÓN', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        }
      });
    }
  }

  onOrigenFocus(event: any) {
    this.search.nativeElement.value = '';
    this.destinoFocused = false;
    this.origenFocused = true;
  }

  onDestinoFocus(event: any) {
    this.search2.nativeElement.value = '';
    this.origenFocused = false;
    this.destinoFocused = true;
  }

  onRevertDestino(event: any) {
    if (this.origen !== null && this.origen !== undefined &&
      this.destino !== null && this.destino !== undefined) {
      let tmp = this.origen;
      this.origen = this.destino;
      this.destino = tmp;
      tmp = this.search.nativeElement.value;
      this.search.nativeElement.value = this.search2.nativeElement.value;
      this.search2.nativeElement.value = tmp;
      this.calcRoute(this.origen, this.destino);
    } else {
      this.toastrService.warning('Los valores de búsqueda no son válidos', 'ATENCIÓN', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  // Muestra los móviles
  showMoviles() {
    this.markersMovilesIndex = new Map<number, number>();
    this.markersMoviles = [];
    this.timerMoviles = setInterval(() => {
      // Espero a que se hayan cargado todos los móviles
      if (RecursosService.movilesOk) {
        clearInterval(this.timerMoviles);
        // Timer que actualiza las posiciones de los móviles sobre el mapa
        this.updateLastPositionMovil();
        this.timerMoviles = setInterval(() => {
          this.updateLastPositionMovil();
        }, this.INTERVAL_POS_MOVILES);
      }
    }, 1000);
  }

  // Actualiza la última posición de los móviles sobre el mapa
  updateLastPositionMovil() {
    this.posicionesService.getUltimasPosiciones(this.loginService.getEmpresa()).then(
      response => {
        // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
        const filter = Config.getMovilesFilter([{ id: -1 }]);
        // Recupero el subfiltro de móviles (-1 = todos mientras no se establece un subfiltro)
        const subFilter = Config.getMovilesSubFilter([{ id: -1 }]);
        if (response !== undefined) {
          response.forEach(pos => {
            // Meto las posiciones en la tabla hash
            let index = 0;
            const i = this.markersMovilesIndex.get(pos.movil.id);
            if (i === undefined) {
              const marker = new MovilMarker(pos, this.map, this.mapsService, false);
              index = this.markersMoviles.push(marker) - 1;
              let visible = Boolean(filter.find(s => s.id === pos.movil.id) !== undefined ||
                filter.find(s => s.id === -1));
              if (visible) {
                visible = Boolean(subFilter.find(s => s.id === pos.movil.id) !== undefined ||
                  subFilter.find(s => s.id === -1));
              }
              marker.setVisible(visible);
            } else {
              index = i;
              this.markersMoviles[index].setPosicion(pos);
              let visible = Boolean(filter.find(s => s.id === pos.movil.id) !== undefined ||
                filter.find(s => s.id === -1));
              if (visible) {
                visible = Boolean(subFilter.find(s => s.id === pos.movil.id) !== undefined ||
                  subFilter.find(s => s.id === -1));
              }
              this.markersMoviles[index].setVisible(visible);
            }
            this.markersMovilesIndex.set(pos.movil.id, index);
          });
        }
      },
      err => {
        console.log(err);
      });
  }

  // Permite mostrar u ocultar las posiciones de los moviles
  subscribeShowMoviles(): void {
    this.mapsService.showMovilesEmiter.subscribe(show => {
      // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
      const filter = Config.getMovilesFilter([{ id: -1 }]);
      // Recupero el subfiltro de móviles (-1 = todos mientras no se establece un subfiltro)
      const subFilter = Config.getMovilesSubFilter([{ id: -1 }]);
      this.markersMoviles.forEach(m => {
        if (show) {
          let visible = filter.find(s => s.id === m.getPosicion().movil.id) !== undefined ||
            filter.find(s => s.id === -1);
          if (visible) {
            visible = subFilter.find(s => s.id === m.getPosicion().movil.id) !== undefined ||
              subFilter.find(s => s.id === -1);
          }
          m.setVisible(visible);
        } else {
          m.setVisible(false);
        }
      });
    });
  }

  // Permite centrar el mapa en la posición de un móvil
  subscribeCenterMovil(): void {
    this.mapsService.centerMovilEmiter.subscribe(idMovil => {
      const index = this.markersMovilesIndex.get(idMovil);
      if (index !== undefined) {
        // Hago que el marcador salte durante 2 segundos
        this.center.lat = this.markersMoviles[index].getPosicion().lat;
        this.center.lng = this.markersMoviles[index].getPosicion().lng;
        this.setCenter(this.center.lat, this.center.lng);
        this.markersMoviles[index].getMarker().setAnimation(Animation.BOUNCE);
        this.markersMoviles[index].getMarker().setZIndex(999);
        setTimeout(() => {
          this.markersMoviles[index].getMarker().setAnimation(null);
        }, 2000);
      }
    });
  }

  // Permite poner como origen la posición actual de un móvil
  subscribeNavegarMovil(): void {
    this.mapsService.navegarMovilEmiter.subscribe(idMovil => {
      const index = this.markersMovilesIndex.get(idMovil);
      if (index !== undefined) {
        this.center.lat = this.markersMoviles[index].getPosicion().lat;
        this.center.lng = this.markersMoviles[index].getPosicion().lng;
        this.setCenter(this.center.lat, this.center.lng);
        this.search.nativeElement.value = this.markersMoviles[index].getPosicion().movil.nombre;
        this.origen = this.markersMoviles[index].getMarker().position;
        if (this.destino !== null) {
          this.calcRoute(this.origen, this.destino);
        }
      }
    });
  }

  subscribeNavegarMovilCercano(): void {
    this.mapsService.navegarMovilCercanoEmiter.subscribe(moviles => {
      if (this.destino !== null) {
        // Inicializo la estructura para calcular el móvil más cercano
        this.movilMasCerca.semaforo = false;
        this.movilMasCerca.movilesTotales = moviles.length;
        this.movilMasCerca.movilesCalculados = 0;
        this.movilMasCerca.movilSelecionado = 0;
        this.movilMasCerca.tiempo = 0;
        this.movilMasCerca.distancia = 0;
        this.formMovilesCerca.open();
        this.calculando = true;
        let items = [];
        moviles.forEach(idMovil => {
          const index = this.markersMovilesIndex.get(idMovil);
          if (index !== undefined) {
            // Calculo la ruta optima entre dos puntos
            const dist = this.calcOptimalRouteBetween(
              idMovil, this.markersMoviles[index].getMarker().position, this.destino
            );
            const item = {
              id: this.markersMoviles[index].getPosicion().movil.id,
              html: '<div style="height: 20px; float: left;">' + this.markersMoviles[index].getPosicion().movil.nombre + ' a ' +
                (dist < 1000 ? Math.round(dist) + ' m.' : Math.round(dist / 1000) + ' km.') + '</span></div>',
              distance: dist
            };
            items.push(item);
          }
        });
        items = items.sort((a, b) => {
          if (a.distance < b.distance) {
            return -1;
          }
          if (a.dist > b.dist) {
            return 1;
          }
          return 0;
        });
        this.lbMoviles.clear();
        items.forEach(item => {
          this.lbMoviles.addItem(item);
        });
        this.lbMoviles.selectIndex(0);
        this.calculando = false;
      } else {
        this.toastrService.warning('Tiene que seleccionar un destino', 'ATENCIÓN');
      }
    });
  }

  resultadoItinerario(movil: number, tiempo: number, distancia: number) {
    while (this.movilMasCerca.semaforo) { }
    try {
      this.movilMasCerca.semaforo = true;
      this.movilMasCerca.movilesCalculados++;
      if (this.movilMasCerca.movilSelecionado === 0 || tiempo < this.movilMasCerca.tiempo) {
        this.movilMasCerca.movilSelecionado = movil;
        this.movilMasCerca.tiempo = tiempo;
        this.movilMasCerca.distancia = distancia;
      }
      if (this.movilMasCerca.movilesCalculados >= this.movilMasCerca.movilesTotales &&
        this.movilMasCerca.movilSelecionado !== 0) {
        const index = this.markersMovilesIndex.get(this.movilMasCerca.movilSelecionado);
        if (index !== undefined) {
          this.center.lat = this.markersMoviles[index].getPosicion().lat;
          this.center.lng = this.markersMoviles[index].getPosicion().lng;
          this.setCenter(this.center.lat, this.center.lng);
          this.search.nativeElement.value = this.markersMoviles[index].getPosicion().movil.nombre;
          this.origen = this.markersMoviles[index].getMarker().position;
          this.calcRoute(this.origen, this.destino);
        }
      }
    } finally {
      this.movilMasCerca.semaforo = false;
    }
  }

  // Cada vez que se selecciona un móvil de la lista de móviles cercanos
  onSelectMovilCerca(event: any) {
    const item = event.args.item;
    if (item !== null && !this.calculando) {
      const movilId = item.value.id;
      const index = this.markersMovilesIndex.get(movilId);
      if (index !== undefined) {
        this.center.lat = this.markersMoviles[index].getPosicion().lat;
        this.center.lng = this.markersMoviles[index].getPosicion().lng;
        this.setCenter(this.center.lat, this.center.lng);
        this.search.nativeElement.value = this.markersMoviles[index].getPosicion().movil.nombre;
        this.origen = this.markersMoviles[index].getMarker().position;
        this.calcRoute(this.origen, this.destino);
      }
    }
  }

  // Calcula el tiempo de la ruta optima entre dos puntos
  calcOptimalRouteBetween(idMovil: number, origen: any, destino: any) {
    let time = Number.MAX_VALUE;
    let distance = Number.MAX_VALUE;
    try {
      // Calculo la distancia en linea recta pero la devuelvo como tiempo
      // para que el método que llama funcione igual que si lo hiciese con
      // la API de google por tiempo
      time = google.maps.geometry.spherical.computeDistanceBetween(origen, destino);
      this.resultadoItinerario(idMovil, time, distance);
      return time;

      /* Todo esto es usando el calculo de itinerario de Google, si se usa hay que comentar todo lo de arriba
      // Distancia calculando el itinerario más rápido
      this.directionsService.route({
        origin: origen,
        destination: destino,
        travelMode: google.maps.TravelMode.DRIVING,
      }, (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          distance = response.routes[0].legs[0].distance.value;
          time = response.routes[0].legs[0].duration.value;
          this.resultadoItinerario(idMovil, time, distance);
        }
      });*/
    } finally {
    }
  }

  // Permite centrar el mapa en la posición de un área
  subscribeCenterArea(): void {
    this.mapsService.centerAreaEmiter.subscribe(area => {
      const index = this.markersAreasIndex.get(area);
      if (index !== undefined) {
        // Hago que el marcador salte durante 2 segundos
        this.center.lat = this.markersAreas[index].getArea().lat;
        this.center.lng = this.markersAreas[index].getArea().lng;
        this.markersAreas[index].getMarker().setAnimation(Animation.BOUNCE);
        this.markersAreas[index].getMarker().setZIndex(999);
        setTimeout(() => {
          this.markersAreas[index].getMarker().setAnimation(null);
        }, 2000);
      }
    });
  }

  // Muestra las areas
  showAreas(): void {
    this.markersAreas = [];
    this.markersAreasIndex = new Map<number, number>();
  }

  // Permite mostrar añadir areas
  subscribeAddAreas(): void {
    this.mapsService.addAreasEmiter.subscribe(areas => {
      areas.forEach(area => {
        if (area.fBaja === null) {
          this.addArea(area);
        }
      });
      this.clusterAreas.repaint();
    });
  }

  addArea(area: AreaModel) {
    // Añado el area a la lista y al cluster si está visible
    const am = new AreaMarker(area, this.map, false);
    this.markersAreas.push(am);
    this.markersAreasIndex.set(area.id, this.markersAreas.length - 1);
    if (area.tipo.visible) {
      this.clusterAreas.addMarker(am.getMarker(), true);
    }
    // Creo el listener para cuando se pincha sobre el area
    google.maps.event.addListener(am.getMarker(), 'click', (event => {
      if (this.infoArea !== null) {
        this.infoArea.close();
      }
      this.infoArea = new google.maps.InfoWindow({
        content:
          '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
          '<b>' + am.getArea().nombre + '</b>' + '<hr style = "color: navy;" />' +
          am.getArea().tipo.nombre +
          '</div>'
      });
      this.infoArea.open(this.map, am.getMarker());
      this.mapsService.clickEventEmit({
        lat: am.getArea().lat,
        lng: am.getArea().lng,
        elemId: am.getArea().id,
        elemNombre: am.getArea().nombre
      });
      // Compruebo si se ha posicionado el cursor en el origen
      if (this.origenFocused) {
        this.search.nativeElement.value = am.getArea().nombre;
        this.origen = am.getMarker().position;
      } else {
        // Compruebo si se ha posicionado el cursor en el destino
        if (this.destinoFocused) {
          this.search2.nativeElement.value = am.getArea().nombre;
          this.destino = am.getMarker().position;
          this.calcRoute(this.origen, this.destino);
        }
      }
    }));
  }

  // Permite añadir un área al mapa
  subscribeAddArea(): void {
    this.mapsService.addAreaEmiter.subscribe(area => {
      this.addArea(area);
      this.clusterAreas.repaint();
      // this.setCenter(usu.lat, usu.lng);
    });
  }

  // Permite añadir un área al mapa
  subscribeUpdateArea(): void {
    this.mapsService.updateAreaEmiter.subscribe(usu => {
      this.removeArea(usu);
      this.addArea(usu);
      this.clusterAreas.repaint();
      // this.setCenter(usu.lat, usu.lng);
    });
  }

  // Permite borrar un área del mapa
  subscribeRemoveArea(): void {
    this.mapsService.removeAreaEmiter.subscribe(area => {
      this.removeArea(area);
      this.clusterAreas.repaint();
    });
  }

  removeArea(area: AreaModel) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.markersAreas.length; i++) {
      if (this.markersAreas[i].getArea().id === area.id) {
        this.clusterAreas.removeMarker(this.markersAreas[i].marker);
        this.markersAreas[i].circle.setMap(null);
        this.markersAreas.splice(i, 1);
        break;
      }
    }
  }

  // Permite mostrar u ocultar las areas
  subscribeShowAreas(): void {
    this.mapsService.showAreasEmiter.subscribe(show => {
      // Recupero el filtro de areas
      const filter = Config.getAreasFilter([{}]);
      // Meto los tipos en la tabla hash
      this.tiposAreas.forEach(tipo => {
        tipo.visible = filter.find(s => s.id === tipo.id) !== undefined;
        AreasService.areasTipos.set(tipo.id, tipo);
      });
      this.clusterAreas.clearMarkers();
      this.markersAreas.forEach(area => {
        const model = AreasService.areasTipos.get(area.getArea().tipo.id);
        if (model !== undefined && model != null && model.visible) {
          this.clusterAreas.addMarker(area.getMarker(), true);
          area.circle.setVisible(true);
        } else {
          area.circle.setVisible(false);
        }
      });
      this.clusterAreas.repaint();
    });
  }

  // Permite borrar un itinerario
  subscribeBorrarItinerario(): void {
    this.mapsService.borrarItinerarioEmiter.subscribe(() => {
      this.directionsDisplay.setMap(null);
      document.getElementById('infoIti').innerHTML = '';
      this.search.nativeElement.value = '';
      this.search2.nativeElement.value = '';
      this.origen = null;
      this.destino = null;
      this.formMovilesCerca.close();
    });
  }

  // Activa o desactiva la capa de estado del tráfico
  onChangeTrafic(event: any) {
    this.traficLayer.setMap(this.trafic ? this.map : null);
    Config.storeTraficMap(this.trafic);
  }

  // Permite encuadrar el mapa
  fitMap(lat0: number, lng0: number, lat1: number, lng1: number) {
    this.map.fitBounds(
      new google.maps.LatLngBounds(
        new google.maps.LatLng(lat0, lng0),
        new google.maps.LatLng(lat1, lng1)
      ));
  }

  // Establece el centro del mapa
  setCenter(lat: number, lng: number) {
    this.center.lat = lat;
    this.center.lng = lng;
    this.map.setCenter(new google.maps.LatLng(lat, lng));
  }

  getCenter() {
    return this.center;
  }

  // Establece el nivel de zoom
  setZoom(zoom: number) {
    this.zoom = zoom;
  }

  getZoom() {
    return this.zoom;
  }

  setMapType(type: string) {
    this.mapType = type;
  }

  getType() {
    return this.mapType;
  }

  // Avisa que se ha pulsado sobre el mapa
  onMapClick($event: AGMMouseEvent) {
    const lat = $event.coords.lat;
    const lng = $event.coords.lng;
    // Compruebo si se ha posicionado el cursor en el origen
    if (this.origenFocused) {
      this.search.nativeElement.value = 'Punto geográfico (A)';
      this.origen = $event.coords;
    } else {
      // Compruebo si se ha posicionado el cursor en el destino
      if (/*this.destinoFocused && */this.search2.nativeElement.value === '') {
        this.search2.nativeElement.value = 'Punto geográfico (B)';
        this.destino = new google.maps.LatLng($event.coords.lat, $event.coords.lng);
        this.calcRoute(this.origen, this.destino);
      }
    }
  }


}

