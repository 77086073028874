<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="'Procesando...'">
</jqxLoader>
<div class="popup" *ngIf="imagenSelec !== null && imagenSelec.length > 50">
    <img class="popup" style='max-width:500px;max-height:350px;' src='data:image/jpeg;base64,{{imagenSelec}}' />
</div>
<div *ngIf="mostrarMainForm">
    <jqxWindow #form [width]="702" [height]="515" [zIndex]="50" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false">
        <div class="formHeader" style="overflow-x: hidden;">
            Gestión de usuarios
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="float:left; margin-top: 6px; width: 100%;">
                <jqxGrid #grid [width]="'100%'" [source]="dataAdapterUsu" [pagesize]="10" [columnsresize]="true" [localization]="localization" [filterable]="true" [showfilterrow]="true" [columns]="columnsUsu" [pageable]="true" [autoheight]="true" [sortable]="true" [altrows]="true"
                    [enabletooltips]="true" [editable]="false" [scrollmode]="'deferred'" (onRowclick)="onRowUsuarioSelect($event)">
                </jqxGrid>
            </div>
            <div style="float: left; margin-top: 6px;">
                <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"' (onClick)="onCrearUsuarioClick($event)">
                    Crear
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"' (onClick)="onEditarUsuarioClick($event)">
                    Editar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"' (onClick)="onBorrarUsuarioClick($event)">
                    Borrar
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>

<div *ngIf="mostrarEditForm">
    <jqxWindow #formEdit [width]="460" [height]="430" [zIndex]="100" [autoOpen]="true" [isModal]="false" [showCloseButton]="true" [showCollapseButton]="false" [resizable]="false" (onClose)="onCloseEdit()" (onOpen)="onOpenEditUsuario()">
        <div class="formHeader" style="overflow-x: hidden;">
            Edición de usuarios
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="margin-top: 5px; clear: both;">
                Tipo
                <jqxDropDownList #cbTipos [width]="250" [height]="25" [selectedIndex]="0" (onSelect)="onChangeTipoUsuario($event)">
                </jqxDropDownList>
            </div>
            <div style="margin-top: 5px; clear: boot;">
                Nombre
                <input #ebNombre [(ngModel)]="usuarioEdit.nombre" type='text' style="width: 435px;" />
            </div>
            <div style="margin-top: 5px; float:left; width: 245px;  clear:both;">
                E-mail
            </div>
            <div style="margin-top: 0px; float:left;  clear:both;">
                <input [(ngModel)]="usuarioEdit.email " type='text' style="width: 435px;" />
            </div>
            <div style="margin-top: 5px; float:left; width: 245px;  clear:both;">
                Observaciones
            </div>
            <div style="margin-top: 0px; float:left;  clear:both;">
                <input [(ngModel)]="usuarioEdit.observ " type='text' style="width: 435px;" />
            </div>
            <div style="margin-top: 5px; float:left;clear: both; width: 245px; ">
                Contraseña
            </div>
            <div style="margin-top: 5px; float:left;">
                Repita contraseña
            </div>
            <div style="margin-top: 0px; float:left;">
                <input [(ngModel)]="usuarioEdit.password" type='password' style="width: 235px;" (focusout)="onKillFocusPassw($event)" (change)="onChangePassw($event)" />
            </div>
            <div style="margin-top: 0px; float:left ">
                <input [(ngModel)]="password" type='password' style="margin-left: 2px; width: 189px; " (focusout)="onKillFocusPassw2($event)" (change)="onChangePassw($event)" />
            </div>
            <div style="margin-top: 10px; float:left; clear: both;">
                Activo
                <input #cbActivo [(ngModel)]="usuarioEdit.activo" type='checkbox' />
            </div>
            <div style="margin-top: 10px; float: left; clear: both;">Imagen
                <br>
                <input type='file' (change)="readUsuarioImage($event);" />
            </div>
            <div style="margin-top: 10px; float: left;">
                <img *ngIf="usuarioEdit.imagen!==null && usuarioEdit.imagen.indexOf('data:image')>-1" style="margin-left: 30px;" [src]="usuarioEdit.imagen" width="64" height="64" (click)="onClickImagen()" />
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both; ">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarClick($event) ">
                    Guardar
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>