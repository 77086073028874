import { BaseMarker } from './base-marker';
import { SensorModel } from '../../sensores/modelos/sensor-model';
import { Utils } from '../../utils/utils';

declare var google: any;

export class SensorMarker extends BaseMarker {
    private static infoWindow: any = null;
    private sensor: SensorModel;

    constructor(sensor: SensorModel, map: any, animate: boolean) {
        super(sensor.nombre, sensor.lat, sensor.lng, sensor.icono, 0, map, false);
        this.sensor = sensor;
        // Creo el listener para cuando se pincha sobre el marcador
        google.maps.event.addListener(this.getMarker(), 'click', (event => {
            if (SensorMarker.infoWindow !== null) {
                SensorMarker.infoWindow.close();
            }
            SensorMarker.infoWindow = new google.maps.InfoWindow({
                content:
                    '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
                    '<b>' + sensor.nombre + '</b>' + '<hr style = "color: navy;" />' +
                    Utils.formatDateTime(sensor.fecha, true) + '<br>' +
                    'Lectura: ' + sensor.lectura +
                    '</div>'
            });
            SensorMarker.infoWindow.open(map, this.getMarker());
        }));
        if (animate) {
            // Hago que salte el marcador
            this.animate(500);
        }
    }

    getSensor(): SensorModel {
        return this.sensor;
    }

}
