export class SensorMovilidadModel {
    idSensor: number;
    nombre: string;
    activaciones: number;
    distancia: number;
    duracion: string;

    constructor(idSensor: number, nombre: string, activaciones: number,
        distancia: number, duracion: string) {
        this.idSensor = idSensor;
        this.nombre = nombre;
        this.activaciones = activaciones;
        this.distancia = distancia / 1000.0;
        this.duracion = duracion.replace('.', 'd ');
    }
}
