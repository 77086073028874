import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { MapsModule } from './maps/maps.module';
import { MenuModule } from './menu/menu.module';
import { RecursosModule } from './recursos/recursos.module';
import { AuthInterceptor } from './api/api-interceptor';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginModule } from './login/login.module';
import { MainComponent } from './main/main.component';
import { MainModule } from './main/main.module';
import { SensoresModule } from './sensores/sensores.module';
import { AreasModule } from './areas/areas.module';
import { UsuariosModule } from './usuarios/usuarios.module';
import { AlarmasModule } from './alarmas/alarmas.module';
import { InformesModule } from './informes/informes.module';
import { VisorComponent } from './visor/visor.component';
import { VisorModule } from './visor/visor.module';

const rutas: Routes = [
  { path: '', component: LoginComponent },
  { path: 'main', component: MainComponent },
  { path: 'visor', component: VisorComponent }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MainModule,
    RouterModule.forRoot(rutas, { relativeLinkResolution: 'legacy' }),
    MenuModule,
    MapsModule,
    LoginModule,
    RecursosModule,
    SensoresModule,
    AreasModule,
    UsuariosModule,
    AlarmasModule,
    InformesModule,
    VisorModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
