import { BaseMarker } from './base-marker';
import { Utils } from '../../utils/utils';

declare var google: any;

export class StopMarker extends BaseMarker {
    private static infoWindow: any = null;
    private fechaIni: Date;
    private fechaFin: Date;

    constructor(fechaIni: Date, fechaFin: Date, lat: number, lng: number,
        zIndex: number, map: any, animate: boolean) {
        let difSeg = (fechaFin.getTime() - fechaIni.getTime()) / 1000;
        const d = Math.trunc(difSeg / 86400);
        difSeg -= d * 86400;
        const h = Math.trunc(difSeg / 3600);
        difSeg -= h * 3600;
        const m = Math.trunc(difSeg / 60);
        let tiempo = '';
        if (d > 0) {
            tiempo += (d + ' dias, ');
        }
        if (h > 0) {
            tiempo += h + ' horas, ';
        }
        if (m > 0) {
            tiempo += m + ' minutos';
        }
        super('Parada ' + tiempo, lat, lng, '/assets/images/rutas/stop.png', zIndex, map, false);
        this.fechaIni = fechaIni;
        this.fechaFin = fechaFin;
        // Creo el listener para cuando se pincha sobre el marcador
        google.maps.event.addListener(this.getMarker(), 'click', (event => {
            if (StopMarker.infoWindow !== null) {
                StopMarker.infoWindow.close();
            }
            StopMarker.infoWindow = new google.maps.InfoWindow({
                content:
                    '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
                    '<b>Parada</b>' + '<hr style = "color: navy;" />' +
                    'Desde ' + Utils.formatTime(this.fechaIni) + ' ' +
                    'Hasta ' + Utils.formatTime(this.fechaFin) + '<br>' +
                    tiempo +
                    '</div>'
            });
            StopMarker.infoWindow.open(map, this.getMarker());
        }));
        if (animate) {
            // Hago que salte en marcador
            this.animate(500);
        }
    }

}
