import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { PeriodoSelectComponent } from './periodo-select.component';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PeriodoSelectComponent],
  imports: [
    CommonModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule,
    BrowserModule,
    FormsModule
  ],
  exports: [
    PeriodoSelectComponent
  ]
})
export class PeriodoSelectModule { }
