<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="'Procesando...'">
</jqxLoader>
<jqxWindow #form [width]=1165 [height]="530" [zIndex]="99" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false">
    <div class="formHeader" style="overflow-x: hidden;">
        Informe de flota
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="float:left; margin-top: 6px; width: 100%;">
            <jqxGrid #grid [width]="'100%'" [source]="dataAdapter" [pagesize]="10" [columnsresize]="true" [localization]="localization" [filterable]="true" [showfilterrow]="true" [columns]="columns" [pageable]="true" [autoheight]="true" [sortable]="true" [altrows]="true"
                [enabletooltips]="true" [editable]="false" [scrollmode]="'deferred'" (onRowclick)="onRowSelect($event)">
            </jqxGrid>
        </div>
        <div style="float: left; margin-top: 6px;">
            <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mapa/ver.png"' (onClick)="onCentrarClick($event)">
                Centrar
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/exportar.png"' (onClick)="onExportarClick($event)">
                Exportar
            </jqxButton>
        </div>
    </div>
</jqxWindow>