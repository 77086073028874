import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { MovilModel } from 'src/app/recursos/modelos/movil-model';
import { Idioma } from 'src/app/utils/idioma';
import { LoginService } from 'src/app/login/login.service';
import { RecursosService } from 'src/app/recursos/recursos.service';
import { Config } from 'src/app/utils/config';

@Component({
  selector: 'app-flota',
  templateUrl: './flota.component.html',
  styleUrls: ['./flota.component.css']
})
export class FlotaComponent implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;

  // Variables para controlar el grid
  private moviles: MovilModel[] = [];

  // Preparo las columnas del grid de grupos
  public columns: any[] = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public localization = Idioma.getLocalization('es');

  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);

  constructor(
    private recursosService: RecursosService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // Recupero los móviles de la flota
      this.getFlota();
    }, 100);
  }

  public getFlota() {
    this.columns = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', editable: false, width: 60 },
      { text: 'Nombre', columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', editable: false, width: 150 },
      { text: 'Matrícula', columntype: 'textbox', filtertype: 'textbox', datafield: 'matricula', editable: false, width: 80 },
      { text: 'Cod. Recurso', columntype: 'textbox', filtertype: 'textbox', datafield: 'codigoExt', width: 110 }
    ];
    // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
    const filter = Config.getMovilesFilter([{ id: -1 }]);
    let all = false;
    if (filter.find(s => s.id === -1)) {
      all = true;
    }
    this.loader.open();
    this.recursosService.getMoviles(this.loginService.getEmpresa()).then(
      response => {
        this.moviles = [];
        if (response !== undefined) {
          response.forEach(movil => {
            if ((all || filter.find(s => s.id === movil.id)) && movil.gls !== 'AN') {
              this.moviles.push(movil);
            }
          });
        }
        this.updateList();
        this.loader.close();
      },
      err => {
        this.loader.close();
        console.log(err);
      });
  }

  updateList() {
    this.grid.beginupdate();
    this.source = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'nombre', type: 'string' },
        { name: 'matricula', type: 'string' },
        { name: 'codigoExt', type: 'string' }
      ],
      localdata: this.moviles,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.grid.endupdate();
  }

  onCellEdit(event: any) {
    if (event.args.value !== event.args.oldvalue) {
      this.recursosService.setCodigoRecurso(event.args.row.id, event.args.value);
      // Actualizo la información en caché
      const movilSelec = RecursosService.moviles.get(event.args.row.id);
      movilSelec.codigoExt = event.args.value;
      RecursosService.moviles.set(event.args.row.id, movilSelec);
    }
  }
}
