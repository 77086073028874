<div class="moviles-component" #movilesContainer>
    <div class="moviles-component-header">
        <span><b>FLOTA</b></span> <span style="margin-left: 10px">({{this.tipoGes}})</span>
    </div>
    <div class="moviles-component-body" >
        <jqxDropDownList #cbGrupos [width]="'100%'" [height]="25" [selectedIndex]="0"
            (onSelect)="onChangeGrupos($event)">
        </jqxDropDownList>
        <div style="float: left;">
            <jqxButton #btnTodos [width]='80' [height]='25' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/sensores/todos.png"' (onClick)="onTodosClick($event)">
                Todos
            </jqxButton>
        </div>
        <div style="float: left; margin-left: 2px;">
            <jqxButton #btnNinguno [width]='80' [height]='25' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/sensores/ninguno.png"'
                (onClick)="onNingunoClick($event)">
                Ninguno
            </jqxButton>
        </div>
        <jqxListBox #jqxListBox [height]="'95%'" [width]="'100%'" [checkboxes]="true" [theme]="'Artic'"
            [allowDrag]="true" [dragStart]="dragStart" (onDragStart)="onDragStart($event)" (onSelect)="onSelect($event)"
            (onCheckChange)="onCheck($event)" (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)"
            (mouseout)="onMouseOut($event)" (mouseenter)="onMouseEnter($event)">
        </jqxListBox>
    </div>
    <div class="moviles-component-footer">
        <ng-container [ngSwitch]="cartoActiva">
            <div *ngSwitchCase="0">
                <div style="float:left;">
                    <jqxButton #btnRuta [width]='70' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/menu/rutas/ruta.png"'
                        (onClick)="onRutaClick($event)">
                        Ruta
                    </jqxButton>
                </div>
                <div style="float:left; margin-left: 5px;">
                    <jqxButton #btnSeguir [width]='80' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/menu/mapa-seg.png"'
                        (onClick)="onSeguirClick($event)">
                        Seguir
                    </jqxButton>
                </div>
                <div style="float:left; margin-left: 5px;">
                    <jqxButton #btnCentrar [width]='80' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/centrar.png"'
                        (onClick)="onCentrarClick($event)">
                        Centrar
                    </jqxButton>
                </div>
            </div>
            <div *ngSwitchCase="1">
                <div style="float:left;">
                    <jqxButton #btnNoSeguir [width]='130' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/menu/mapa-no-seg.png"'
                        (onClick)="onNoSeguirClick($event)">
                        Dejar de seguir
                    </jqxButton>
                </div>
            </div>
            <div *ngSwitchCase="2">
                <div style="float:left;">
                    <jqxButton #btnNavegar [width]='120' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/menu/itinerario.png"'
                        (onClick)="onNavegarClick($event)">
                        Navegar a...
                    </jqxButton>
                </div>
                <div style="float:left; margin-left: 5px;">
                    <jqxButton #btnSeguir [width]='80' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/menu/limpiar.png"'
                        (onClick)="onLimpiarClick($event)">
                        Limpiar
                    </jqxButton>
                </div>
                <div style="float:left; padding-top: 2px; clear: both;">
                    <jqxButton #btnNavegarCercano [width]='206' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/menu/itinerario.png"'
                        (onClick)="onNavegarCercanoClick($event)">
                        Navegar más cercano a...
                    </jqxButton>
                </div>
            </div>
        </ng-container>
    </div>
</div>