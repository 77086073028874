import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SensorModel } from '../modelos/sensor-model';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { SensoresService } from '../sensores.service';
import { Config } from '../../utils/config';

@Component({
  selector: 'app-sensor-filter',
  templateUrl: './sensor-filter.component.html',
  styleUrls: ['./sensor-filter.component.css']
})

export class SensorFilterComponent implements OnInit, AfterViewInit {
  @ViewChild('lbSensores', { static: false }) lbSensores: jqxListBoxComponent;
  private sensores: SensorModel[] = [];

  constructor(private sensoresService: SensoresService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updateContent();
  }

  getPos(): any {
    const footer = document.getElementById('headerLayout').clientHeight;
    return {
      x: 0,
      y: document.getElementById('headerLayout').clientHeight
    };
  }

  // Rellena la lista de sensores
  updateContent() {
    this.sensores.push(new SensorModel(1, 1, 'Sirena', null, '', '', 0, 0));
    this.sensores.push(new SensorModel(1, 2, 'Puente de luces', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 3, 'Entrada digital3', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 4, 'Entrada digital4', null, '', '', 0, 0));
    this.sensores.push(new SensorModel(1, 16, 'Ignición', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 17, 'Id. Conductor', null, '', '', 0, 0));
    this.sensores.push(new SensorModel(1, 19, 'Kilómetros', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 21, 'Cobertura GSM', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 22, 'Cobertura GPS', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 23, 'Bruto', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 35, 'Tara', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 42, 'Neto', null, '', '', 0, 0));
    this.sensores.push(new SensorModel(1, 24, 'R.P.M.', null, '', '', 0, 0));
    this.sensores.push(new SensorModel(1, 25, 'Velocidad', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 26, 'Consumo', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 36, 'Id. Contenedor', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 44, 'Peso en vertedero', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 45, 'Nivel de combustible', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 46, 'Temp. Líquido refrigerante', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 47, 'Horas de servicio', null, '', '', 0, 0));
    // this.sensores.push(new SensorModel(1, 48, 'Km. Próxima revisión', null, '', '', 0, 0));
    this.sensores.push(new SensorModel(1, 91, 'Conexión / Desconexión', null, '', '', 0, 0));

    // Recupero la selección anterior
    const select = Config.getSensorFilter([{}]);

    this.lbSensores.beginUpdate();
    this.sensores.forEach(elem => {
      const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
        'src="' + elem.icono + '"/>';
      const item = {
        id: elem.sensor,
        html: '<div style="height: 20px; float: left;">' + icono +
          '<span style="float: left; font-size: 13px;">' + elem.nombre + '</span></div>',
        checked: select.find(s => s.id === elem.sensor) !== undefined
      };
      this.lbSensores.addItem(item);
    });
    this.lbSensores.endUpdate();
  }

  onTodosClick(event: any) {
    if (this.lbSensores !== undefined) {
      this.lbSensores.checkAll();
    }
  }

  onNingunoClick(event: any) {
    if (this.lbSensores !== undefined) {
      this.lbSensores.uncheckAll();
    }
  }

  onClose() {
    if (this.lbSensores !== undefined) {
      // Almaceno los sensores seleccionados
      const sensores: any[] = [];
      const selection: any[] = this.lbSensores.getCheckedItems();
      selection.forEach(elem => {
        sensores.push({ id: elem.value.id });
      });
      Config.storeSensorFilter(sensores);
    }
  }

}
