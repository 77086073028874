import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { RecursosService } from '../../recursos/recursos.service';
import { Idioma } from '../../utils/idioma';
import { MovilModel } from '../../recursos/modelos/movil-model';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { LoginService } from '../../login/login.service';
import { Config } from '../../utils/config';
import { ToastrService } from 'ngx-toastr';
import { MapsService } from '../../maps/maps.service';
import { SensoresService } from '../../sensores/sensores.service';
import { EstadoMovilModel } from '../../recursos/modelos/estado-movil-model';
import { Utils } from '../../utils/utils';
import { EstadoMovilGestionModel } from 'src/app/recursos/modelos/estado-movil-gestion-model';
import { environment } from '../../../environments/environment';
import { PosicionesService } from 'src/app/posiciones/posiciones.service';

@Component({
  selector: 'app-informe-flota',
  templateUrl: './informe-flota.component.html',
  styleUrls: ['./informe-flota.component.css']
})
export class InformeFlotaComponent implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;

  // Variables para controlar el grid
  private moviles: MovilModel[] = [];
  private movilSelec: MovilModel = null;

  // Preparo las columnas del grid de grupos
  public columns: any[] = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public localization = Idioma.getLocalization('es');

  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);

  constructor(
    private recursosService: RecursosService,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private mapsService: MapsService,
    private sensoresService: SensoresService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // Recupero los móviles de la flota
      this.getFlota();
    }, 100);
  }

  public getFlota() {
    this.columns = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60 },
      { text: 'Nombre', columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 150 },
      { text: 'Matrícula', columntype: 'textbox', filtertype: 'textbox', datafield: 'matricula', width: 80 },
      { text: 'Tipo de vehículo', columntype: 'textbox', filtertype: 'textbox', datafield: 'tipoVehiculo', width: 150 },
      { text: 'Conectado', columntype: 'checkbox', filtertype: 'textbox', datafield: 'conexion', width: 80 },
      { text: 'Ignición', columntype: 'checkbox', filtertype: 'textbox', datafield: 'ignicion', width: 70 },
      { text: 'Sirena', columntype: 'checkbox', filtertype: 'textbox', datafield: 'sirena', width: 60 },
      { text: 'P.Luces', columntype: 'checkbox', filtertype: 'textbox', datafield: 'puente', width: 60 },
      { text: 'Pacientes', columntype: 'textbox', filtertype: 'textbox', datafield: 'personas', width: 80 },
      //{ text: 'Localización', columntype: 'textbox', filtertype: 'textbox', datafield: 'direccion', width: 200 },
      { text: 'Latitud', columntype: 'textbox', filtertype: 'textbox', datafield: 'lat', width: 70 },
      { text: 'Longitud', columntype: 'textbox', filtertype: 'textbox', datafield: 'lng', width: 70 },
      { text: 'Vel (km/h)', columntype: 'textbox', filtertype: 'textbox', datafield: 'vel', width: 80 },
      { text: 'Altura (m.)', columntype: 'textbox', filtertype: 'textbox', datafield: 'alt', width: 80 },
      { text: 'Rumbo', columntype: 'textbox', filtertype: 'textbox', datafield: 'rumbo', width: 60 },
    ];
    // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
    const filter = Config.getMovilesFilter([{ id: -1 }]);
    let all = false;
    if (filter.find(s => s.id === -1)) {
      all = true;
    }
    this.loader.open();
    this.recursosService.getMoviles(this.loginService.getEmpresa()).then(
      response => {
        this.moviles = [];
        if (response !== undefined) {
          response.forEach(movil => {
            if ((all || filter.find(s => s.id === movil.id)) && movil.gls !== 'AN') {
              this.moviles.push(movil);
            }
          });
        }
        // Recupero el estado de los sensores y luego se actualiza la lista de móviles
        this.getSensors();
        this.loader.close();
      },
      err => {
        this.loader.close();
        console.log(err);
      });
  }

  // Obtiene los últimos valores de los sensores  de cada móvil
  getSensors() {
    this.moviles.forEach(async movil => {
      const response = await this.sensoresService.getUltimosSensoresMovil(movil.id);
    });
    // Actualizo el estado de conexión de todos los móviles
    this.updateList();
  }

  updateList() {
    if (this.moviles !== undefined) {
      this.moviles.forEach(movil => {
        movil.estado = SensoresService.estadoMovil.get(movil.id);
        if (movil.estado === undefined) {
          movil.estado = new EstadoMovilModel(movil.id, false, false, false, false, null);
        }
        movil.estado.lastPos = PosicionesService.lastPos.get(movil.id);
        if (environment.tipoGes === 'TSNU') {
          movil.estadoGes = RecursosService.estadoGestion.get(movil.id);
          if (movil.estadoGes === undefined) {
            movil.estadoGes = new EstadoMovilGestionModel(movil.id, 0, null, 0);
          }
        } else {
          movil.estadoGes = new EstadoMovilGestionModel(movil.id, 0, null, 0);
        }
      });
    }
    this.grid.beginupdate();
    this.source = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'nombre', type: 'string' },
        { name: 'matricula', type: 'string' },
        { name: 'tipoVehiculo', type: 'string' },
        { name: 'conexion', map: 'estado>conex', type: 'boolean' },
        { name: 'ignicion', map: 'estado>igni', type: 'boolean' },
        { name: 'sirena', map: 'estado>sirena', type: 'boolean' },
        { name: 'puente', map: 'estado>puenteLuces', type: 'boolean' },
        { name: 'personas', map: 'estadoGes>numPersonas', type: 'number' },
        //{ name: 'direccion', map: 'estado>lastPos>direccion', type: 'string' },
        { name: 'lat', map: 'estado>lastPos>lat', type: 'number' },
        { name: 'lng', map: 'estado>lastPos>lng', type: 'number' },
        { name: 'vel', map: 'estado>lastPos>velocidad', type: 'number' },
        { name: 'alt', map: 'estado>lastPos>altura', type: 'number' },
        { name: 'rumbo', map: 'estado>lastPos>rumbo', type: 'rumbo' },
      ],
      localdata: this.moviles,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.grid.endupdate();
  }

  onRowSelect(event: any) {
    this.movilSelec = this.moviles[event.args.rowindex];
  }

  onCentrarClick(event: any) {
    if (this.movilSelec !== null) {
      this.form.collapse();
      this.mapsService.centerMovil(this.movilSelec.id);
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onExportarClick(event: any) {
    this.grid.exportdata('xls', Utils.formatDateAMDhms(new Date()) + '_recursos');
  }
}
