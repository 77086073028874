import { EstadoMovilModel } from './estado-movil-model';
import { EstadoMovilGestionModel } from './estado-movil-gestion-model';
export class MovilModel {
    id: number;
    nombre: string;
    sim?: string;
    imei: string;
    gls: string;
    idEmpresa: number;
    icono?: string;
    matricula?: string;
    conectado?: boolean;
    codigoExt?: string;
    tipoVehiculo?: string;
    // Sólo para los informes
    estado: EstadoMovilModel; // Sensores e ignición
    estadoGes: EstadoMovilGestionModel; // Estado de gestión (estado, personas, etc...)

    constructor(id: number, nombre: string, sim: string, imei: string, gls: string, idEmpresa: number,
        icono: string, matricula: string, conectado: boolean, codigoExt: string, tipoVehiculo: string) {
        this.id = id;
        this.nombre = nombre;
        this.sim = sim;
        this.imei = imei;
        this.gls = gls;
        this.idEmpresa = idEmpresa;
        this.icono = icono;
        this.matricula = matricula;
        this.conectado = conectado;
        this.codigoExt = codigoExt;
        this.tipoVehiculo = tipoVehiculo;
    }
}
