import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioModel } from './modelos/usuario-model';
import { LoginService } from './login.service';
import { Md5 } from 'ts-md5/dist/md5';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api/api-service';
import { ConfirmDialogService } from '../utils/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('passEditBox', { static: false }) passEditBox;

  private usuarios: UsuarioModel[];
  private timerUsuarios: any;
  public usuarioSelec: string;
  public passwordSelec: string;
  public ready = false;
  public intentos = 0;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private confirmDialogService: ConfirmDialogService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    let i = 0;
    // Recupero los usuarios en el momento en que tengo el token
    this.timerUsuarios = setInterval(() => {
      if (ApiService.hayToken) {
        clearInterval(this.timerUsuarios);
        this.getUsuarios();
      }
      i++;
      if ((i % 10) === 0) {
        this.intentos++;
      }
    }, 100);
  }

  async getUsuarios() {
    const rols = await this.loginService.getRols();
    if (rols) {
      const response = await this.loginService.getUsuarios();
      if (response) {
        this.usuarios = response;
        this.ready = true;
      }
    }
  }

  encodePassword(password: string): string {
    const md5 = new Md5();
    return md5.appendStr(password + environment.md5Key).end().toString();
  }

  onUserEnter() {
    this.passEditBox.nativeElement.focus();
  }

  onPasswEnter() {
    this.onClick(null);
  }

  onClick(event: any) {
    let validado = false;
    if (this.usuarios !== undefined && this.usuarios !== null) {
      this.usuarios.forEach(usu => {
        if (usu.email === this.usuarioSelec &&
          usu.password === this.encodePassword(this.passwordSelec)) {
          if (usu.activo && usu.fBaja === null) {
            this.gotoMainForm(usu);
            validado = true;
          }
        }
      });
      if (!validado) {
        this.toastrService.error('Acceso denegado', 'Atención', {
          timeOut: 1000,
          positionClass: 'toast-top-center'
        });
        setTimeout(() => {
          this.toastrService.error('Compruebe que los datos introducidos son correctos ' +
            'y póngase en contacto con el administrador si el problema persiste', 'Atención', {
            timeOut: 4000,
            positionClass: 'toast-top-center'
          });
        }, 2000);
      }
    }
  }

  gotoMainForm(user: UsuarioModel) {
    this.loginService.setUser(new UsuarioModel(user.id, user.nombre,
      user.email, '', user.rol, true, user.imagen, '', null, null, null));
    this.router.navigateByUrl('/main');
  }

  verPassword() {
    if (this.passEditBox.nativeElement.type === 'password') {
      this.passEditBox.nativeElement.type = 'text';
    } else {
      this.passEditBox.nativeElement.type = 'password';
    }
  }

  onRecuperarPassw() {
    if (this.usuarioSelec !== undefined || this.usuarioSelec !== null) {
      if (this.usuarioSelec.indexOf('@') < 1 || this.usuarioSelec.indexOf('.') < 1) {
        this.toastrService.error('Introduzca un e-mail válido', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
      } else {
        this.confirmDialogService.confirmThis(
          '¿Quiere recuperar la contraseña para el usuario ' + this.usuarioSelec + ' ?', () => {
            LoginService.usuarios.forEach((usu, key) => {
              if (usu.email === this.usuarioSelec) {
                this.loginService.forgotPassw(usu.email).then(
                  response => {
                    if (response) {
                      this.toastrService.info('Revise la bandeja de entrada de su correo electrónico, ' +
                        'pronto recibirá un mensaje con su contraseña',
                        'Atención', {
                        timeOut: 5000,
                        positionClass: 'toast-top-center'
                      });
                      // Recupero de nuevo los usuarios para que se cargue la nueva contraseña generada
                      this.getUsuarios();
                    } else {
                      this.toastrService.error('El e-mail introducido no pertenece a un usuario de la aplicación',
                        'Atención', {
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                      });
                    }
                  },
                  err => {
                    console.log(err);
                    this.toastrService.error('No se ha podido realizar la operación', 'Atención', {
                      timeOut: 2000,
                      positionClass: 'toast-top-center'
                    });
                  });
                return;
              }
            });
          }, () => {
          });
      }
    } else {
      this.toastrService.error('Introduzca su e-mail', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }
}


