import { AreaModel } from './area-model';
import { AreaZonaPuntosModel } from './area-zona-puntos-model';

export class AreaZonaModel {
    id: number;
    nombre: string;
    area: AreaModel;
    tipo: number;
    lat: number;
    lng: number;
    radio: number;
    observ: string;
    fAlta: Date;
    fMod: Date;
    fBaja: Date;
    puntos: AreaZonaPuntosModel[];
    // Sólo para los formualrios
    public tipoNombre: string;
    // Sólo para llamadas a la API
    IdArea: number;

    constructor(id: number, nombre: string, area: AreaModel, tipo: number, lat: number,
        lng: number, radio: number, observ: string, puntos: AreaZonaPuntosModel[],
        fAlta: Date, fMod: Date, fBaja: Date) {
        this.id = id;
        this.nombre = nombre;
        this.area = area;
        this.tipo = tipo;
        this.lat = lat;
        this.lng = lng;
        this.radio = radio;
        this.observ = observ;
        this.puntos = puntos;
        this.fAlta = fAlta;
        this.fMod = fMod;
        this.fBaja = fBaja;
        // Sólo para los formularios
        this.tipoNombre = this.tipo > 0 ? 'Polígono' : 'Círculo';
    }
}
