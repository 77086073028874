import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { Idioma } from '../../../utils/idioma';
import { RecursosService } from '../../recursos.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from '../../../utils/confirm-dialog/confirm-dialog.service';
import { GrupoModel } from '../../modelos/grupo-model';
import { environment } from '../../../../environments/environment';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { Permission } from '../../../utils/permission';
import { LoginService } from '../../../login/login.service';

@Component({
  selector: 'app-moviles-grupo',
  templateUrl: './moviles-grupo.component.html',
  styleUrls: ['./moviles-grupo.component.css']
})
export class MovilesGroupComponent implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('ebNombre', { static: false }) ebNombre;
  @ViewChild('lbMoviles', { static: false }) lbMoviles: jqxListBoxComponent;
  @ViewChild('lbMoviles2', { static: false }) lbMoviles2: jqxListBoxComponent;

  // Variables para edición de grupos
  private grupos: GrupoModel[] = [];
  public grupoSelec: GrupoModel = null;
  public grupoEdit: GrupoModel = null;
  public mostrarEditForm = false;
  public movilesAsig = new Map<number, number>();
  public canEdit = true;

  // Preparo las columnas del grid de grupos
  public columnsGrupo: any[] = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public localization = Idioma.getLocalization('es');

  // Variables para el grid de grupos
  public sourceGrupos: any = [];
  public dataAdapterGrupos = new jqx.dataAdapter(this.sourceGrupos);

  constructor(
    private recursosService: RecursosService,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private confirmDialogService: ConfirmDialogService
  ) { }

  ngOnInit(): void {
    this.canEdit = Permission.canEditAgrupaciones(this.loginService.getUser());
  }

  ngAfterViewInit(): void {
    // Creo un grupo vacio para rellenar
    this.grupoEdit = this.getEmptyGrupo();
    setTimeout(() => {
      // Recupero los grupos
      this.getGrupos();
    }, 100);
  }

  getGrupos() {
    this.columnsGrupo = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60 },
      { text: 'Nombre', columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 430 }
    ];
    this.grupos = [];
    this.grupoSelec = null;
    this.loader.open();
    this.recursosService.getGrupos().then(
      response => {
        this.grupos = response;
        if (this.grupos !== undefined) {
          this.grid.beginupdate();
          this.sourceGrupos = {
            datatype: 'json',
            datafields: [
              { name: 'id', type: 'number' },
              { name: 'nombre', type: 'string' }
            ],
            localdata: this.grupos,
            sortcolumn: 'nombre',
            sortdirection: 'asc'
          };
          this.dataAdapterGrupos = new jqx.dataAdapter(this.sourceGrupos);
          this.grid.endupdate();
        }
        this.loader.close();
      },
      err => {
        this.loader.close();
        console.log(err);
      });
  }

  getEmptyGrupo(): GrupoModel {
    // Creo un grupo sin datos, por defecto del tipo que tengamos seleccionado en el grid
    return new GrupoModel(0, '', '', null, null, null);
  }

  onRowGrupoSelect(event: any) {
    this.grupoSelec = this.grupos[event.args.rowindex];
  }

  // Crear un nuevo grupo
  onCrearGrupoClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    // Creo un grupo vacio para rellenar
    this.grupoEdit = this.getEmptyGrupo();
    this.mostrarEditForm = true;
    this.form.collapse();
    this.form.disable();
  }

  // Editar el grupo seleccionado
  onEditarGrupoClick(event: any) {
    if (this.grupoSelec !== null) {
      this.grupoEdit = { ...this.grupoSelec };
      if (this.form !== undefined) {
        this.form.collapse();
        this.form.disable();
      }
      this.mostrarEditForm = true;
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  // Borrar el grupo seleccionado
  onBorrarGrupoClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.grupoSelec !== null) {
      this.deleteGrupo();
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onGuardarClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.grupoEdit.nombre === null || this.grupoEdit.nombre.length < 1) {
      this.toastrService.error('Introduzca un nombre', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    // Recupero los móviles asignados al grupo
    const items = this.lbMoviles2.getItems();
    if (items !== undefined) {
      items.forEach(item => {
        this.grupoEdit.moviles.push(item.value.id);
      });
    }
    this.saveGrupo();
  }

  // Guardo el grupo
  saveGrupo() {
    this.recursosService.saveGrupo(this.grupoEdit).then(
      response => {
        if (response !== undefined && response !== null) {
          if (this.grupoEdit.id === 0) {
            this.grupos.push(response);
          } else {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.grupos.length; i++) {
              if (this.grupos[i].id === this.grupoEdit.id) {
                this.grupos[i] = { ...response };
                break;
              }
            }
          }
          // Añado el grupo a la cache
          RecursosService.grupos.set(response.id, response);
          this.grupoEdit = { ...response };
          this.grupoSelec = { ...this.grupoEdit };
          if (this.grid !== undefined && this.grid !== null) {
            this.grid.updatebounddata('cells');
          }
          this.toastrService.info('Registro almacenado', 'Información', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        }
      },
      err => {
        this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
      });
    this.formEdit.close();
  }

  deleteGrupo() {
    this.confirmDialogService.confirmThis(
      'Seguro que quiere borrar el grupo: ' + this.grupoSelec.nombre, () => {
        this.recursosService.deleteGrupo(this.grupoSelec.id).then(
          response => {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.grupos.length; i++) {
              if (this.grupos[i].id === this.grupoSelec.id) {
                this.grupos.splice(i, 1);
                break;
              }
            }
            this.grupoSelec = null;
            this.grid.updatebounddata('cells');
            this.toastrService.info('Registro borrado', 'Información', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          },
          err => {
            this.toastrService.error('No se ha podido realizar la operación', 'Atención', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          });
      }, () => {
      });
  }

  onOpenEditGrupo() {
    setTimeout(() => {
      this.ebNombre.nativeElement.focus();
      this.updateListaDisponibles();
    }, 0);
  }

  onCloseEdit() {
    this.mostrarEditForm = false;
    if (this.form !== undefined) {
      this.form.enable();
      this.form.expand();
    }
  }

  updateListaDisponibles() {
    this.movilesAsig.clear();
    this.recursosService.getGruposMoviles(this.grupoEdit.id).then(
      resAsig => {
        if (resAsig !== undefined) {
          resAsig.forEach(idMovil => {
            this.movilesAsig.set(idMovil, idMovil);
          });
        }
        this.recursosService.getMoviles(environment.idEmpresa).then(
          response => {
            if (response !== undefined) {
              this.lbMoviles.beginUpdate();
              this.lbMoviles2.beginUpdate();
              response.forEach(elem => {
                if (elem.gls !== 'AN') {
                  const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
                    'src="data:image/jpg;base64,' + elem.icono + '"/>';
                  const item = {
                    id: elem.id,
                    // tslint:disable-next-line: max-line-length
                    html: '<div style="height: 20px; float: left;">' + icono +
                      '<span style="float: left; font-size: 13px;">' + elem.nombre + '</span></div>',
                    checked: false
                  };
                  if (this.movilesAsig.get(elem.id) === undefined) {
                    this.lbMoviles.addItem(item);
                  } else {
                    this.lbMoviles2.addItem(item);
                  }
                }
              });
              this.lbMoviles.endUpdate();
              this.lbMoviles2.endUpdate();
            }
          },
          err => {
            console.log(err);
          });
      },
      err => {
        console.log(err);
      });
  }

  onMasClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    const items = this.lbMoviles.getCheckedItems();
    items.forEach(item => {
      this.lbMoviles.removeItem(item);
      this.lbMoviles2.addItem(item);
    });
  }

  onMenosClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    const items = this.lbMoviles2.getCheckedItems();
    items.forEach(item => {
      this.lbMoviles2.removeItem(item);
      this.lbMoviles.addItem(item);
    });
  }

  onTodosClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.lbMoviles !== undefined) {
      this.lbMoviles.checkAll();
    }
  }

  onNingunoClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.lbMoviles !== undefined) {
      this.lbMoviles.uncheckAll();
    }
  }

  onTodosClick2(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.lbMoviles2 !== undefined) {
      this.lbMoviles2.checkAll();
    }
  }

  onNingunoClick2(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.lbMoviles2 !== undefined) {
      this.lbMoviles2.uncheckAll();
    }
  }

}
