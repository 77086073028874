import {
  Component, OnInit, AfterViewInit, ViewContainerRef, ViewChild,
  ComponentFactoryResolver
} from '@angular/core';
import { AreasFilterComponent } from '../../areas/areas-filter/areas-filter.component';
import { AreasGestionComponent } from '../../areas/areas-gestion/areas-gestion.component';
import { SensorFilterComponent } from '../../sensores/sensor-filter/sensor-filter.component';
import { MovilFilterComponent } from '../../recursos/moviles/movil-filter/movil-filter.component';
import { UsuariosComponent } from '../../usuarios/usuarios.component';
import { LoginService } from '../../login/login.service';
import { MovilesGroupComponent } from '../../recursos/moviles/moviles-group/moviles-grupo.component';
import { AlarmasComponent } from '../../alarmas/alarmas.component';
import { InformeFlotaComponent } from 'src/app/informes/informe-flota/informe-flota.component';
import { InformeMovilidadComponent } from '../../informes/informe-movilidad/informe-movilidad.component';
import { TabletsComponent } from '../../recursos/moviles/tablets/tablets.component';
import { environment } from '../../../environments/environment';
import { ComponentRef } from '@angular/core';
import { FlotaComponent } from 'src/app/recursos/moviles/flota/flota/flota.component';
import { InformeDetalleComponent } from 'src/app/informes/informe-detalle/informe-detalle.component';
import { InformeProgComponent } from 'src/app/informes/informe-prog/informe-prog.component';

enum MenuOption {
  MENU_OPTION_USER_GESTION,
  MENU_OPTION_AREAS_FILTER,
  MENU_OPTION_SENSOR_FILTER,
  MENU_OPTION_MOVIL_FILTER,
  MENU_OPTION_MOVIL_GRUPOS,
  MENU_OPTION_MOVIL_TABLETS,
  MENU_OPTION_AREAS_GESTION,
  MENU_OPTION_ALARMAS_GESTION,
  MENU_OPTION_INFORME_FLOTA,
  MENU_OPTION_INFORME_FLOTA_DETALLE,
  MENU_OPTION_INFORME_MOVILIDAD,
  MENU_OPTION_FLOTA,
  MENU_OPTION_INFORMES_PROGRAMADOS
}

enum Rol {
  ADMIN = 1,
  PERFIL_1 = 2,
  PERFIL_2 = 3
}

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})

export class MainMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('mainMenuContainer', { read: ViewContainerRef }) mainMenuContainer;

  // Referencia a componentes que se crean desde el principio
  private editUserComponent: ComponentRef<UsuariosComponent> = null;

  public options: any[] = [];

  constructor(
    private loginService: LoginService,
    private resolver: ComponentFactoryResolver
  ) {
    const user = this.loginService.getUser();
    if (user.rol.id === Rol.ADMIN) {
      this.options.push(
        {
          html: '<img src="assets/images/menu/usuarios.png"/><span style="position: relative; left: 3px; top: -2px;">Usuarios</span>',
          items: [
            {
              html: '<div id="' + MenuOption.MENU_OPTION_USER_GESTION + '">Gestionar</div>'
            }
          ]
        }
      );
    }
    let subItems = [];
    if (user.rol.id === Rol.ADMIN || user.rol.id === Rol.PERFIL_1) {
      if (environment.tipoGes === 'TSU') {
        subItems.push({
          html: '<div id="' + MenuOption.MENU_OPTION_FLOTA + '">Asignación de recursos</div>'
        });
      }
    }
    if (user.rol.id === Rol.ADMIN || user.rol.id === Rol.PERFIL_1) {
      if (environment.tipoGes === 'TSNU') {
        subItems.push({
          html: '<div id="' + MenuOption.MENU_OPTION_MOVIL_TABLETS + '">Asociación de tabletas</div>'
        });
      }
    }
    subItems.push({
      html: '<div id="' + MenuOption.MENU_OPTION_MOVIL_GRUPOS + '">Agrupaciones</div>'
    });
    subItems.push({
      html: '<div id="' + MenuOption.MENU_OPTION_MOVIL_FILTER + '">Filtrar</div>'
    });
    this.options.push(
      {
        html: '<img src="assets/images/menu/recursos/moviles.png"/>' +
          '<span style="position: relative; left: 3px; top: -2px;">Flota</span>',
        items: subItems
      }
    );
    subItems = [];
    subItems.push({
      html: '<div id="' + MenuOption.MENU_OPTION_AREAS_GESTION + '">Gestionar</div>'
    });
    subItems.push({
      html: '<div id="' + MenuOption.MENU_OPTION_AREAS_FILTER + '">Filtrar</div>'
    });
    this.options.push(
      {
        html: '<img src="assets/images/menu/areas/areas.png"/>' +
          '<span style="position: relative; left: 3px; top: -2px;">Áreas de gestión</span>',
        items: subItems
      }
    );
    this.options.push(
      {
        html: '<img src="assets/images/menu/sensores.png"/>' +
          '<span style="position: relative; left: 3px; top: -2px;">Sensores</span>',
        items: [
          {
            html: '<div id="' + MenuOption.MENU_OPTION_SENSOR_FILTER + '">Filtrar</div>'
          }
        ]
      }
    );
    this.options.push(
      {
        html: '<img src="assets/images/menu/alarma.png"/>' +
          '<span style="position: relative; left: 3px; top: -2px;">Alarmas</span>',
        items: [
          {
            html: '<div id="' + MenuOption.MENU_OPTION_ALARMAS_GESTION + '">Gestión</div>'
          }
        ]
      }
    );
    subItems = [];
    subItems.push({
      html: '<div id="' + MenuOption.MENU_OPTION_INFORME_FLOTA + '">Flota</div>'
    });
    subItems.push({
      html: '<div id="' + MenuOption.MENU_OPTION_INFORME_FLOTA_DETALLE + '">Detalle de movilidad</div>'
    });
    subItems.push({
      html: '<div id="' + MenuOption.MENU_OPTION_INFORME_MOVILIDAD + '">Resumen de movilidad</div>'
    });
    if (user.rol.id === Rol.ADMIN) {
      subItems.push({
        html: '<div id="' + MenuOption.MENU_OPTION_INFORMES_PROGRAMADOS + '">Informes programados</div>'
      });
    }
    this.options.push(
      {
        html: '<img src="assets/images/menu/informes.png"/>' +
          '<span style="position: relative; left: 3px; top: -2px;">Informes</span>',
        items: subItems
      }
    );
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // Creo el formulario de edición de usuarios
      this.editUserComponent = this.mainMenuContainer.createComponent(
        this.resolver.resolveComponentFactory(UsuariosComponent));
    }, 1000);
  }

  onItemClick(event: any) {
    switch (parseInt(event.target.id, 10)) {
      case MenuOption.MENU_OPTION_MOVIL_FILTER:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(MovilFilterComponent));
        break;
      case MenuOption.MENU_OPTION_MOVIL_TABLETS:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(TabletsComponent));
        break;
      case MenuOption.MENU_OPTION_FLOTA:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(FlotaComponent));
        break;
      case MenuOption.MENU_OPTION_MOVIL_GRUPOS:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(MovilesGroupComponent));
        break;
      case MenuOption.MENU_OPTION_USER_GESTION:
        // this.mainMenuContainer.createComponent(
        //   this.resolver.resolveComponentFactory(UsuariosComponent));
        if (this.editUserComponent !== null) {
          this.editUserComponent.instance.open();
        }
        break;
      case MenuOption.MENU_OPTION_AREAS_GESTION:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(AreasGestionComponent));
        break;
      case MenuOption.MENU_OPTION_AREAS_FILTER:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(AreasFilterComponent));
        break;
      case MenuOption.MENU_OPTION_SENSOR_FILTER:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(SensorFilterComponent));
        break;
      case MenuOption.MENU_OPTION_ALARMAS_GESTION:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(AlarmasComponent));
        break;
      case MenuOption.MENU_OPTION_INFORME_FLOTA:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(InformeFlotaComponent));
        break;
      case MenuOption.MENU_OPTION_INFORME_FLOTA_DETALLE:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(InformeDetalleComponent));
        break;
      case MenuOption.MENU_OPTION_INFORME_MOVILIDAD:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(InformeMovilidadComponent));
        break;
      case MenuOption.MENU_OPTION_INFORMES_PROGRAMADOS:
        this.mainMenuContainer.createComponent(
          this.resolver.resolveComponentFactory(InformeProgComponent));
        break;
    }
  }

}
