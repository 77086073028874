import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';

import { GoogleMapComponent } from './google-map/google-map.component';
import { GoogleMapSegComponent } from './google-map-seg/google-map-seg.component';
import { GoogleMapItiComponent } from './google-map-iti/google-map-iti.component';
import { GoogleMapVisorComponent } from './google-map-visor/google-map-visor.component';
import { MapsService } from './maps.service';

import { environment } from '../../environments/environment';
import { ReproductorModule } from '../reproductor/reproductor.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { jqxSplitterModule } from 'jqwidgets-ng/jqxsplitter';
import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';

@NgModule({
  declarations: [GoogleMapComponent, GoogleMapSegComponent, GoogleMapItiComponent, GoogleMapVisorComponent],
  imports: [
    CommonModule,
    FormsModule,
    jqxSplitterModule,
    jqxListBoxModule,
    jqxWindowModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ['places', 'drawing', 'geometry', 'visualization'],
      region: 'es',
      //language: 'es'
    }),
    ReproductorModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  exports: [
    GoogleMapComponent, GoogleMapSegComponent, GoogleMapItiComponent, GoogleMapVisorComponent
  ],
  providers: [
    MapsService
  ]
})
export class MapsModule { }
