<jqxSplitter #splitter [panels]="[{ size: '75%' }, { size: '25%' }]" [height]="'100%'" [width]="'100%'"
    [orientation]="'horizontal'">
    <div class="splitter-panel">
        <div #mapContainer style="width: 100%; height: 100%">
            <div class="search-group">
                <input #search type="text" (keydown.enter)="$event.preventDefault()" placeholder="Introduzca origen..."
                    autocorrect="off" autocapitalize="off" spellcheck="off" on-focus="onOrigenFocus($event)">
            </div>
            <div class="search-group3">
                <input #revert type="image" [height]="18" [width]=18 src="../../../assets/images/revert.png"
                    tabindex="-1" (click)="onRevertDestino($event)">
            </div>
            <div class="search-group2">
                <input #search2 type="text" (keydown.enter)="$event.preventDefault()"
                    placeholder="Introduzca destino..." autocorrect="off" autocapitalize="off" spellcheck="off"
                    on-focus="onDestinoFocus($event)">
            </div>
            <div class="trafic">
                <input [(ngModel)]="trafic" type="checkbox" (change)="onChangeTrafic($event)" />Tráfico
            </div>
            <jqxWindow #formMovilesCerca [position]="getFormPos()" [width]="250" [height]="400" [zIndex]="99"
                [autoOpen]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false">
                <div class="formHeader" style="overflow-x: hidden;">
                    Móviles cerca
                </div>
                <div class="form" style="float:left; overflow-x: hidden;">
                    <jqxListBox #lbMoviles [height]="'100%'" [width]="'100%'" [valueMember]="'id'"
                        (onSelect)="onSelectMovilCerca($event)">
                    </jqxListBox>
                </div>
            </jqxWindow>
            <agm-map #AgmMap id="gMap" [clickableIcons]="false" [mapTypeId]="getType()" [mapTypeControl]="true"
                [latitude]="getCenter().lat" [longitude]="getCenter().lng" [zoom]="getZoom()"
                (mapClick)="onMapClick($event)">
            </agm-map>
        </div>
    </div>
    <div class="splitter-panel" style="height: 100%; overflow-y: auto;">
        <div style="overflow-y: auto;">
            <div id="infoIti" class="info-iti">
            </div>
        </div>
    </div>
</jqxSplitter>