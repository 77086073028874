import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensoresService } from './sensores.service';
import { SensorFilterComponent } from 'src/app/sensores/sensor-filter/sensor-filter.component';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';

@NgModule({
  declarations: [SensorFilterComponent],
  imports: [
    CommonModule,
    jqxWindowModule,
    jqxListBoxModule,
    jqxButtonModule
  ],
  exports: [
    SensorFilterComponent
  ],
  providers: [
    SensoresService
  ]
})
export class SensoresModule { }
