<jqxWindow [width]="500" [showCloseButton]="true" [height]="400" [resizable]="false" (onClose)="onClose()">
    <div style="overflow-x: hidden; ">
        Filtro de áreas de gestión
    </div>
    <div style=" float:left; overflow-x: hidden; background-color:lightgrey; ">
        <div style="height: 8%;">
            <div style="float: left;">
                <jqxButton #btnTodos [width]='80' [height]='25' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/sensores/todos.png"' (onClick)="onTodosClick($event)">
                    Todos
                </jqxButton>
            </div>
            <div style="float: left; margin-left: 2px;">
                <jqxButton #btnNinguno [width]='80' [height]='25' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/sensores/ninguno.png"' (onClick)="onNingunoClick($event)">
                    Ninguno
                </jqxButton>
            </div>
        </div>
        <jqxListBox #lbTipos [height]="'91%'" [width]="'100%'" [checkboxes]="true" [valueMember]="'id'">
        </jqxListBox>
    </div>
</jqxWindow>