import { Injectable } from '@angular/core';
import { InformeMovilidadModel } from './modelos/informe-movilidad-model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Utils } from '../utils/utils';
import { environment } from '../../environments/environment';
import { RecursosService } from '../recursos/recursos.service';
import { SensorMovilidadModel } from './modelos/sensor-movilidad-model';
import { InformeModel, InformeMovilModel, InformeUsuarioModel } from './modelos/informe-prog-model';

@Injectable({
  providedIn: 'root'
})
export class InformesService {

  constructor(private http: HttpClient) { }

  public getInformeMovilidad(periodo: number, moviles: number[], fechaIni: Date, fechaFin: Date):
    Promise<InformeMovilidadModel[]> {
    const informe: any = [];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    const filtro = {
      Moviles: moviles,
      FechaIni: Utils.formatDate(fechaIni, false),
      FechaFin: Utils.formatDate(fechaFin, false),
      Periodo: periodo
    };
    return this.http.post(environment.urlApi + '/api/listado/movilidad',
      Utils.capitalizeJSON(JSON.stringify(filtro)), httpOptions)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach(row => {
          const sirena = new SensorMovilidadModel(1, 'Sirena', 0, 0, '');
          const pLuces = new SensorMovilidadModel(2, 'Puente de luces', 0, 0, '');
          if (row.EntradasDigitales !== undefined) {
            row.EntradasDigitales.forEach(s => {
              switch (s.EntradaDigital) {
                case 1:
                  sirena.activaciones = s.NumeroActivaciones;
                  sirena.distancia = s.Distancia / 1000.0;
                  sirena.duracion = s.Duracion.replace('.', 'd ');
                  break;
                case 2:
                  pLuces.activaciones = s.NumeroActivaciones;
                  pLuces.distancia = s.Distancia / 1000.0;
                  pLuces.duracion = s.Duracion.replace('.', 'd ');
                  break;
              }
            });
          }
          informe.push(
            new InformeMovilidadModel(row.Periodicidad,
              RecursosService.moviles.get(row.Movil), new Date(row.FechaInicio),
              new Date(row.FechaFin), row.DistanciaTotal, row.TiempoIgnicionActiva,
              row.TiempoIgnicionInactiva, row.TiempoTotal,
              row.TiempoRalenti, row.VelocidadMaxima, row.VelocidadMedia, sirena, pLuces));
        });
        return informe;
      }, error => {
        console.log(error);
        return informe;
      });
  }

  public async getInformes(): Promise<InformeModel[]> {
    let response = null;
    try {
      response = await this.http.get<InformeModel[]>(environment.urlApiGes + '/api/informes?ApiToken=' + environment.apiToken).toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  public async saveInforme(informe: InformeModel): Promise<InformeModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    try {
      informe.Moviles = [];
      informe.movilesId.forEach(movilId => {
        informe.Moviles.push(new InformeMovilModel(informe.Id, movilId));
      });
      informe.Usuarios = [];
      informe.usuariosId.forEach(usuarioId => {
        informe.Usuarios.push(new InformeUsuarioModel(informe.Id, usuarioId));
      });
      return await this.http.post<InformeModel>(environment.urlApiGes + '/api/informes/save', JSON.stringify(informe), httpOptions).toPromise();
    } catch (e) {
      return null;
    }
  }

  public async deleteInforme(informeId: number): Promise<void> {
    try {
      await this.http.get(environment.urlApiGes + '/api/informes/delete?informeId=' + informeId).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

}
