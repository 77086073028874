export class RolModel {
    public id: number;
    public nombre: string;
    public fAlta: Date;
    public fMod: Date;
    public fBaja: Date;

    constructor(id: number, nombre: string, fAlta: Date, fMod: Date, fBaja: Date) {
        this.id = id;
        this.nombre = nombre;
        this.fAlta = fAlta;
        this.fMod = fMod;
        this.fBaja = fBaja;
    }
}
