import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlarmasComponent } from './alarmas.component';
import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';

@NgModule({
  declarations: [AlarmasComponent],
  imports: [
    CommonModule,
    jqxListBoxModule,
    jqxButtonModule,
    jqxWindowModule,
    jqxLoaderModule,
    jqxGridModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule,
    jqxDropDownListModule,
    HttpClientModule,
    BrowserModule,
    FormsModule
  ]
})
export class AlarmasModule { }
