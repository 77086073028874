import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login/login.service';
import { GrupoModel } from 'src/app/recursos/modelos/grupo-model';
import { RecursosService } from 'src/app/recursos/recursos.service';
import { ConfirmDialogService } from 'src/app/utils/confirm-dialog/confirm-dialog.service';
import { Idioma } from 'src/app/utils/idioma';
import { Permission } from 'src/app/utils/permission';
import { environment } from 'src/environments/environment';
import { InformesService } from '../informes-service';
import { InformeModel, InformeMovilModel, InformeUsuarioModel } from '../modelos/informe-prog-model';

@Component({
  selector: 'app-informe-prog',
  templateUrl: './informe-prog.component.html',
  styleUrls: ['./informe-prog.component.css']
})
export class InformeProgComponent implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('ebNombre', { static: false }) ebNombre;
  @ViewChild('lbMoviles', { static: false }) lbMoviles: jqxListBoxComponent;
  @ViewChild('lbUsuarios', { static: false }) lbUsuarios: jqxListBoxComponent;
  @ViewChild('cbTipoInforme') cbTipoInforme: jqxDropDownListComponent;
  @ViewChild('chbDiario', { static: false }) chbDiario;
  @ViewChild('chbSemanal', { static: false }) chbSemanal;
  @ViewChild('chbMensual', { static: false }) chbMensual;
  @ViewChild('cbGrupos') cbGrupos: jqxDropDownListComponent;

  // Variables para edición de grupos
  private informes: InformeModel[] = [];
  public informeSelec: InformeModel = null;
  public informeEdit: InformeModel = null;
  public mostrarEditForm = false;
  public canEdit = true;
  private grupos: GrupoModel[] = [];
  private updateGroups = false;
  private grupoSelec = 0;
  private movilesFilter: number[] = [];
  private lastMovil = 0;
  private movilesAsig = new Map<number, any>();

  // Preparo las columnas del grid
  public columns: any[] = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public localization = Idioma.getLocalization('es');

  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);

  constructor(private loginService: LoginService,
    private recursosService: RecursosService,
    private informesService: InformesService,
    private recursorService: RecursosService,
    private toastrService: ToastrService,
    private confirmDialogService: ConfirmDialogService
  ) { }

  ngOnInit(): void {
    this.canEdit = Permission.canEditAgrupaciones(this.loginService.getUser());
  }

  ngAfterViewInit(): void {
    // Creo un informe vacio para rellenar
    this.informeEdit = this.getEmptyInforme();
    setTimeout(() => {
      // Recupero los informes
      this.getInformes();
    }, 100);
  }

  async getInformes() {
    this.columns = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'Id', width: 60 },
      { text: 'Nombre', columntype: 'textbox', filtertype: 'textbox', datafield: 'Nombre', width: 250 },
      { text: 'Tipo', columntype: 'textbox', filtertype: 'textbox', datafield: 'Tipo', width: 160, cellsrenderer: this.renderRow },
      { text: 'Diario', columntype: 'checkbox', filtertype: 'textbox', datafield: 'Diario', width: 50 },
      { text: 'Semanal', columntype: 'checkbox', filtertype: 'textbox', datafield: 'Semanal', width: 70 },
      { text: 'Mensual', columntype: 'checkbox', filtertype: 'textbox', datafield: 'Mensual', width: 65 },
      { text: 'Observaciones', columntype: 'textbox', filtertype: 'textbox', datafield: 'Observaciones', width: 500 }
    ];
    this.informes = [];
    this.informeSelec = null;
    this.loader.open();
    this.informes = await this.informesService.getInformes();
    if (this.informes) {
      this.grid.beginupdate();
      this.source = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'number' },
          { name: 'Nombre', type: 'string' },
          { name: 'Tipo', type: 'number' },
          { name: 'Diario', type: 'boolean' },
          { name: 'Semanal', type: 'boolean' },
          { name: 'Mensual', type: 'boolean' },
          { name: 'Observaciones', type: 'string' }
        ],
        localdata: this.informes,
        sortcolumn: 'Nombre',
        sortdirection: 'asc'
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);
      this.grid.endupdate();
    } else {
      this.informes = [];
    }
    this.loader.close();
  }


  renderRow(row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (value) {
      case 1:
        value = 'Informe de flota';
        break;
      case 2:
        value = 'Detalle de movilidad';
        break;
      case 3:
        value = 'Resumen de movilidad';
        break;
    }
    return '<div style="text-align: ' + columnproperties.cellsalign + '; margin-left:4px;">' + value + '</div>';
  }
  getEmptyInforme(): InformeModel {
    // Creo un informe sin datos
    return new InformeModel(0, '', 0, false, false, false, '');
  }

  onRowSelect(event: any) {
    this.informeSelec = this.informes[event.args.rowindex];
  }

  // Crear un nuevo informe
  onCrearClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    // Creo un informe vacio para rellenar
    this.informeEdit = this.getEmptyInforme();
    this.mostrarEditForm = true;
    this.form.collapse();
    this.form.disable();
  }

  // Editar el informe seleccionado
  onEditarClick(event: any) {
    if (this.informeSelec !== null) {
      this.informeEdit = { ...this.informeSelec };
      if (this.form !== undefined) {
        this.form.collapse();
        this.form.disable();
      }
      this.mostrarEditForm = true;
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  async onOpenEdit() {
    this.movilesAsig = new Map<number, any>();
    const usuariosAsig = new Map<number, any>();
    if (this.informeEdit && this.informeEdit.Moviles) {
      this.informeEdit.Moviles.forEach(movil => {
        this.movilesAsig.set(movil.MovilId, movil);
      });
    }
    if (this.informeEdit && this.informeEdit.Usuarios) {
      this.informeEdit.Usuarios.forEach(usuario => {
        usuariosAsig.set(usuario.UsuarioId, usuario);
      });
    }
    const moviles = await this.recursorService.getMoviles(environment.idEmpresa);
    const usuarios = await this.loginService.getUsuarios();
    setTimeout(() => {
      // Relleno el combo de tipos de zonas
      this.cbTipoInforme.clear();
      this.cbTipoInforme.addItem({ label: 'Informe de flota', value: 1 });
      this.cbTipoInforme.addItem({ label: 'Detalle de movilidad', value: 2 });
      this.cbTipoInforme.addItem({ label: 'Resumen de movilidad', value: 3 });
      this.cbTipoInforme.selectIndex(this.informeEdit.Tipo > 0 ? this.informeEdit.Tipo - 1 : 0);
      this.ebNombre.nativeElement.focus();
      // Relleno el combo de grupos
      this.getGroups();
      this.updateMoviles();
      this.lbUsuarios.beginUpdate();
      usuarios.forEach(usuario => {
        const item = {
          id: usuario.id,
          html: '<div style="height: 20px; float: left;">' +
            '<span style="float: left; font-size: 13px;">' + usuario.nombre + '</span></div>',
          checked: usuariosAsig.get(usuario.id) !== undefined
        };
        this.lbUsuarios.addItem(item);
      });
      this.lbUsuarios.endUpdate();
    }, 0);
  }

  // Rellena la lista de móviles
  async updateMoviles() {
    this.lbMoviles.clear();
    const moviles = await this.recursosService.getMoviles(this.loginService.getEmpresa());
    if (moviles) {
      this.lbMoviles.beginUpdate();
      moviles.forEach(movil => {
        if (movil.gls !== 'AN') {
          if (this.grupoSelec === 0 || this.movilesFilter.find(s => s === movil.id)) {
            const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
              'src="data:image/jpg;base64,' + movil.icono + '"/>';
            const item = {
              id: movil.id,
              html: '<div style="height: 20px; float: left;">' + icono +
                '<span style="float: left; font-size: 13px;">' + movil.nombre + '</span></div>',
              checked: this.movilesAsig.get(movil.id) !== undefined
            };
            this.lbMoviles.addItem(item);
          }
        }
      });
      this.lbMoviles.endUpdate();
    }
  }

  onCloseEdit() {
    this.mostrarEditForm = false;
    if (this.form !== undefined) {
      this.form.enable();
      this.form.expand();
    }
  }

  // Borrar el informe seleccionado
  onBorrarClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.informeSelec !== null) {
      this.deleteInforme();
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onGuardarClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (!this.informeEdit.Nombre || this.informeEdit.Nombre.length < 1) {
      this.toastrService.error('Introduzca un nombre', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (!this.informeEdit.Diario && !this.informeEdit.Semanal && !this.informeEdit.Mensual) {
      this.toastrService.error('Indique la periodicidad del informe', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    // Recupero los móviles asignados al informe
    this.informeEdit.movilesId = [];
    let items = this.lbMoviles.getCheckedItems();
    if (items !== undefined) {
      if (items.length < 1) {
        this.toastrService.error('Seleccione al menos un móvil de la lista', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
        return;
      }
      items.forEach(item => {
        this.informeEdit.movilesId.push(item.value.id);
      });
    }
    // Recupero los usuarios asignados al informe
    this.informeEdit.usuariosId = [];
    items = this.lbUsuarios.getCheckedItems();
    if (items !== undefined) {
      if (items.length < 1) {
        this.toastrService.error('Seleccione al menos un usuario de la lista', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
        return;
      }
      items.forEach(item => {
        this.informeEdit.usuariosId.push(item.value.id);
      });
    }
    this.saveInforme();
  }

  // Guardo el informe
  async saveInforme() {
    const response = await this.informesService.saveInforme(this.informeEdit);
    if (response) {
      if (this.informeEdit.Id === 0) {
        this.informes.push(response);
      } else {
        for (let i = 0; i < this.informes.length; i++) {
          if (this.informes[i].Id === this.informeEdit.Id) {
            this.informes[i] = { ...response };
            break;
          }
        }
      }
      this.informeEdit = { ...response };
      this.informeSelec = { ...this.informeEdit };
      if (this.grid !== undefined && this.grid !== null) {
        this.grid.updatebounddata('cells');
      }
      this.toastrService.info('Registro almacenado', 'Información', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    } else {
      this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
    this.formEdit.close();
  }

  async deleteInforme() {
    this.confirmDialogService.confirmThis(
      'Seguro que quiere borrar el informe: ' + this.informeSelec.Nombre, () => {
        const response = this.informesService.deleteInforme(this.informeSelec.Id);
        if (response) {
          for (let i = 0; i < this.informes.length; i++) {
            if (this.informes[i].Id === this.informeSelec.Id) {
              this.informes.splice(i, 1);
              break;
            }
          }
          this.informeSelec = null;
          this.grid.updatebounddata('cells');
          this.toastrService.info('Registro borrado', 'Información', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastrService.error('No se ha podido realizar la operación', 'Atención', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        }
      }, () => {
      });
  }

  onTodosClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.lbMoviles !== undefined) {
      this.lbMoviles.checkAll();
    }
  }

  onNingunoClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.lbMoviles !== undefined) {
      this.lbMoviles.uncheckAll();
    }
  }

  onTodosClick2(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.lbUsuarios !== undefined) {
      this.lbUsuarios.checkAll();
    }
  }

  onNingunoClick2(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.lbUsuarios !== undefined) {
      this.lbUsuarios.uncheckAll();
    }
  }

  onSelectTipoInforme(event: any) {
    this.informeEdit.Tipo = event.args.item.value;
    switch (this.informeEdit.Tipo) {
      case 1:
      case 2:
        this.informeEdit.Semanal = false;
        this.informeEdit.Mensual = false;
    }
  }

  // Rellena la lista de grupos
  async getGroups() {
    this.grupos = [];
    const response = await this.recursosService.getGrupos();
    if (response) {
      this.grupos = response;
      this.updateGroups = true;
      this.cbGrupos.clear();
      this.cbGrupos.addItem({ label: 'Todos los vehículos', value: -1 });
      let i = 1;
      this.grupos.forEach(grupo => {
        this.cbGrupos.addItem({
          label: grupo.nombre,
          value: grupo.id
        });
        i++;
      });
      if (this.cbGrupos.getSelectedIndex() < 0) {
        this.cbGrupos.selectIndex(0);
      }
    } else {
      this.cbGrupos.selectIndex(0);
      this.grupoSelec = 0;
    }
  }

  async onChangeGrupos(event: any) {
    if (this.updateGroups) {
      this.updateGroups = false;
    } else {
      if (event.args.item.value > -1) {
        this.grupoSelec = event.args.item.value;
        this.movilesFilter = await this.recursosService.getGruposMoviles(this.grupoSelec);
      } else {
        this.grupoSelec = 0;
      }
      this.updateMoviles();
    }
  }

}
