<div class="main" id="mainLayout">
    <div class="header" id="headerLayout">
        <div class="header" style="float: left;">
            <img width="15%" style="float: left;" src="assets/images/empresa/movisat.png">
            <img width="15%" style="float: left;" src="assets/images/empresa/ambusat.png">
            <div style="float: right; margin: 10px;">
                <input type=image src="assets/images/salir.png" width="37" height="40" (click)="onCloseSession()">
            </div>
            <div style="float: right; margin: 10px;">
                <div *ngIf="user.imagen !== null; else sinImagen">
                    <input type=image src='{{imagenUsuario}}' width="24" height="24" (click)="onUser()">
                </div>
                <div>
                    <ng-template #sinImagen>
                        <input type=image src='assets/images/usuario.png' width="24" height="24" (click)="onUser()">
                    </ng-template>
                </div>
                <sup>{{user.nombre}}</sup>
            </div>
            <img width="15%" style="float: right; margin-right: 30px;" src="assets/images/empresa/gsc.png">
            <img width="10%" style="float: right; margin-right: 10px;" src="assets/images/empresa/cliente.png">
        </div>
    </div>
    <div class="body" id="bodyLayout">
        <app-confirm-dialog></app-confirm-dialog>
        <div class="menu" id="menuPanel">
            <app-main-menu></app-main-menu>
        </div>
        <div class="centerPanel" id="centerLayout">
            <jqxTabs #tabs [height]="'100%'" (onSelected)="onSelecTab($event)">
                <ul style=" margin-left: 10px; ">
                    <li>
                        <div style="float: left; ">
                            <img width="16" height="16" src="assets/images/menu/mapa.png" />
                        </div>
                        <div style="float: left; margin-left: 6px; ">
                            Principal
                        </div>
                    </li>
                    <li>
                        <div style="float: left; ">
                            <img width="16" height="16" src="assets/images/menu/mapa-seg.png" />
                        </div>
                        <div style="float: left; margin-left: 6px; ">
                            En seguimiento
                        </div>
                    </li>
                    <li>
                        <div style="float: left; ">
                            <img width="16" height="16" src="assets/images/menu/mapa-iti.png" />
                        </div>
                        <div style="float: left; margin-left: 6px; ">
                            Itinerarios
                        </div>
                    </li>
                </ul>
                <div class="map" id="mapPanel">
                    <app-google-map></app-google-map>
                </div>
                <div class="map" id="mapPanelSeg">
                    <app-google-map-seg></app-google-map-seg>
                </div>
                <div class="map" id="mapPanelIti">
                    <app-google-map-iti></app-google-map-iti>
                </div>
            </jqxTabs>
        </div>
        <div class="resources" id="resourcesPanel">
            <app-moviles></app-moviles>
        </div>
    </div>
    <div class="footer" id="footerLayout">
        <div style="float: right; margin-right: 10px; ">
            <sub>Powered by <a href="http://www.movisat.com/ ">
          &copy; MOVISAT TecnoMovilidad</a> Todos los derechos reservados
      </sub>
        </div>
    </div>
</div>