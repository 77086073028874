<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="'Procesando...'">
</jqxLoader>
<jqxWindow #form [width]="502" [height]="515" [zIndex]="50" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false">
    <div class="formHeader" style="overflow-x: hidden;">
        Agrupaciones de flota
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="float:left; margin-top: 6px; width: 100%;">
            <jqxGrid #grid [width]="'100%'" [source]="dataAdapterGrupos" [pagesize]="10" [columnsresize]="true" [localization]="localization" [filterable]="true" [showfilterrow]="true" [columns]="columnsGrupo" [pageable]="true" [autoheight]="true" [sortable]="true"
                [altrows]="true" [enabletooltips]="true" [editable]="false" [scrollmode]="'deferred'" (onRowclick)="onRowGrupoSelect($event)">
            </jqxGrid>
        </div>
        <div style="float: left; margin-top: 6px;">
            <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"' (onClick)="onCrearGrupoClick($event)" [hidden]="!canEdit">
                Crear
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"' (onClick)="onEditarGrupoClick($event)">
                Editar
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"' (onClick)="onBorrarGrupoClick($event)" [hidden]="!canEdit">
                Borrar
            </jqxButton>
        </div>
    </div>
</jqxWindow>

<div *ngIf="mostrarEditForm">
    <jqxWindow #formEdit [width]="605" [height]="546" [zIndex]="100" [autoOpen]="true" [isModal]="false" [showCloseButton]="true" [showCollapseButton]="false" [resizable]="false" (onClose)="onCloseEdit()" (onOpen)="onOpenEditGrupo()">
        <div class="formHeader" style="overflow-x: hidden;">
            Edición de grupos de flota
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="float: left;  width: 100%;">
                <div style="margin-top: 5px; clear: boot;">
                    Nombre<br>
                    <input #ebNombre [(ngModel)]="grupoEdit.nombre" type='text' style="width: 584px;" [disabled]="!canEdit" />
                </div>
                <div style="margin-top: 5px; float:left; width: 245px;  clear:both;">
                    Observaciones<br>
                    <input [(ngModel)]="grupoEdit.observ " type='text' style="width: 584px;" [disabled]="!canEdit" />
                </div>
            </div>
            <div style="float: left; margin-top: 5px; clear: both;">
                <div style="float: left; width: 250px; height: 340px;">
                    <div style="float:left;">
                        <b>Disponibles</b><br>
                        <jqxButton style="float:left;" [width]='80' [height]='25' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgPosition]='"right"' [imgSrc]='"/assets/images/sensores/todos.png"' (onClick)="onTodosClick($event)" [disabled]="!canEdit">
                            Todos
                        </jqxButton>
                        <jqxButton style="float:left; margin-left: 2px;" [width]='80' [height]='25' [textImageRelation]='"imageBeforeText"' [textPosition]='"right"' [imgPosition]='"left"' [imgSrc]='"/assets/images/sensores/ninguno.png"' (onClick)="onNingunoClick($event)" [disabled]="!canEdit">
                            Ninguno
                        </jqxButton>
                    </div>
                    <jqxListBox #lbMoviles [height]="'100%'" [width]="'100%'" [checkboxes]="true" [valueMember]="'id'" [disabled]="!canEdit">
                    </jqxListBox>
                </div>
                <div style="float: left; margin-left: 7px; margin-top: 150px;">
                    <jqxButton style="float:left; clear: both;" [width]='80' [height]='26' [textImageRelation]='"imageAfterText"' [textPosition]='"left"' [imgPosition]='"right"' [imgSrc]='"/assets/images/recursos/derecha.png"' (onClick)="onMasClick($event)" [disabled]="!canEdit">
                        Añadir
                    </jqxButton>
                    <jqxButton style="float:left; margin-top: 5px; clear: both;" [width]='80' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"right"' [imgPosition]='"left"' [imgSrc]='"/assets/images/recursos/izquierda.png"' (onClick)="onMenosClick($event)"
                        [disabled]="!canEdit">
                        Quitar
                    </jqxButton>
                </div>
                <div style="float: left; margin-left: 7px; width: 250px; height: 340px;">
                    <b>Asignados al grupo</b><br>
                    <div style="float:left;">
                        <jqxButton style="float:left;" [width]='80' [height]='25' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgPosition]='"right"' [imgSrc]='"/assets/images/sensores/todos.png"' (onClick)="onTodosClick2($event)" [disabled]="!canEdit">
                            Todos
                        </jqxButton>
                        <jqxButton style="float:left; margin-left: 2px;" [width]='80' [height]='25' [textImageRelation]='"imageBeforeText"' [textPosition]='"right"' [imgPosition]='"left"' [imgSrc]='"/assets/images/sensores/ninguno.png"' (onClick)="onNingunoClick2($event)" [disabled]="!canEdit">
                            Ninguno
                        </jqxButton>
                    </div>
                    <jqxListBox #lbMoviles2 [height]="'100%'" [width]="'100%'" [checkboxes]="true" [valueMember]="'id'" [disabled]="!canEdit">
                    </jqxListBox>
                </div>
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both;">
                <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarClick($event)" [disabled]="!canEdit">
                    Guardar
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>