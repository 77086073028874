import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { MenuModule } from '../menu/menu.module';
import { MapsModule } from '../maps/maps.module';
import { RecursosModule } from '../recursos/recursos.module';
import { RouterModule } from '@angular/router';
import { jqxTabsModule } from 'jqwidgets-ng/jqxtabs';
import { ConfirmDialogModule } from '../utils/confirm-dialog/confirm-dialog.module';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    jqxTabsModule,
    MenuModule,
    RecursosModule,
    MapsModule,
    RouterModule,
    ConfirmDialogModule
  ]
})
export class MainModule { }
