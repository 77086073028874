import { Injectable } from '@angular/core';
import { AreaTipoModel } from './modelos/area-tipo-model';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AreaModel } from './modelos/area-model';
import { MapsService } from '../maps/maps.service';
import { Utils } from '../utils/utils';
import { Config } from '../utils/config';
import { AreaZonaModel } from './modelos/area-zona-model';
import { AreaZonaPuntosModel } from './modelos/area-zona-puntos-model';

@Injectable({
  providedIn: 'root'
})
export class AreasService {
  public static areasTipos = new Map<number, AreaTipoModel>();
  public static areas = new Map<number, AreaModel>();
  public static zonas = new Map<number, AreaZonaModel>();

  private NUMREG = 1000; // Número de registros por página

  constructor(private http: HttpClient, private mapService: MapsService) { }

  getAreasTipos(): Promise<AreaTipoModel[]> {
    const result: any = [];
    let url = '';
    if (environment.tipoGes !== 'TSNU') {
      url = environment.urlApi + '/api/ambusat/areas/tipos/gestion';
    } else {
      url = environment.urlApiGes + '/api/areastipo?ApiToken=' + environment.apiToken;
    }
    return this.http.get(url)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        // Recupero el filtro de areas
        const filter = Config.getAreasFilter([{}]);
        // Icono por defecto para las áreas que no tienen imagen
        const defImage = 'iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAA' +
          'lwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANxSURBVEi' +
          'JpZPtb1NVHMe/59xz723H3ZMwXpCJjrgsuhjxAWdkS9oNUiROaMQlm0QlxpjoC/6FvveNL/UFMZQhySLt1mUd' +
          'jsZ2Up8iMIhRCYkJGNEERzrD3W3vvefBF7DatR3t8Pvq3N/5nu/n/M45l6CBTiZmL3geHyAA27Z9+yoBbkOpj' +
          'w6NDH7SaC0A0OrCZDrd9vncuQNr35zLp6UQupSKALAUsEsR8iIATE1NaTOZ/P5sNhtoCjCZyuxxbe+vuyv2/K' +
          'np+fcBwLT0nZZlDZuBtm1B37aEwuPe8q33AMDs2nFUUbLwjzLzZ7Pfd9cDkMrwknP3OyGExhjzgh3BZyYikWs' +
          'Pan/2fH4npyRDCHoB/KEp8tLoyMu3ajpIJpMdbsnOCSE0XTdWWgyzr1E4AIzuH/xdN+QAgK8BdAuo2U8vXtRr' +
          'AKukZR/3eQtjzAvq+rPj0VduNApf06tDQwWmy8MA+QUE/V133M66xsnEuQ8npzNPNRtcrZl8vnV6cfHRh13/0' +
          'CLxRHqBC39PwG/rnZgIL/+fsETmh62U8isAEoeH9x4HAOZ6bkgKobNWvx/AYjNBkZMHL+vt5k92e+HdXDjH/5' +
          'vxtgK0G0D5P6JSSh0ALG7/2uxOORc90hVvWYXOM6FsiJXrGv/z/nBHGdBsaLV8xweIOlIJGQuHbQLkQbC05mM' +
          'apb4QQl+lW54AcLtZgHAFJFegDEesQidC2dB4Lpzjh4b3DlX6qBEwzuimcYO3G5c32wUv+vcGVZ1UilQXmtHI' +
          'iUhB+aqDECDYFQTI/RhFvrA7C+OVF08BIBbLslPJuY/jMwsvbAakFOA7omK7tZ1QANi1u/SB4xSPe0X723gq1' +
          'bMZiFg7pg0gFABaVSmuMd0RQup+SS5tBiKFgnDF+mIFhAJANBpdCQS3hKhGBfd5u+eIa6fPzj3WLMR3/NriPc' +
          'iJ8lkdfW3fj6eTXw6U3OIFLkWQa1ovgJvxVKqHaUYfW5WXxsYO/l23C09CcrlEGb2zjkFUad2zejMaufRZNvs' +
          'Is+2+t0cPXAUA1xHXi8JmhFDMfPVNvvqdA4BmsivpN1LP1YPXvNtj4XAJwNWyQWc/S+BJCeiKQFX7qaldP/9O' +
          '+nkcqxdfB1ADfH1090Zz1NB+G7w50J8h83IjT0NA3WBCl2FgucOy+mOxGH+Q919tLXep6q2dtQAAAABJRU5Er' +
          'kJggg==';
        if (environment.tipoGes === 'TSNU') {
          // Creo un área con Id -99 para las áreas que no tienen tipo asignado
          // cuando se trata de áreas que vienen de la APP de gestión
          result.push(new AreaTipoModel(-99, 'Desconocido',
            defImage, '', null, null, null));
          AreasService.areasTipos.set(-99, new AreaTipoModel(-99, 'Desconocido',
            defImage, '', null, null, null));
        }
        data.forEach(model => {
          const newTipo = new AreaTipoModel(model.Id, model.Nombre,
            model.Imagen !== null ? model.Imagen : defImage, model.Observ,
            model.FAlta, model.FMod, model.FBaja);
          newTipo.visible = filter.find(s => s.id === newTipo.id) !== undefined;
          AreasService.areasTipos.set(newTipo.id, newTipo);
          result.push(newTipo);
        });
        return result;
      }, error => {
        console.log('recursos::getAreasTipos()' + error);
      });
  }

  getAreas(): Promise<AreaModel[]> {
    const result: any = [];
    let url = '';
    if (environment.tipoGes !== 'TSNU') {
      url = environment.urlApi + '/api/ambusat/areas/gestion/1/' + this.NUMREG;
    } else {
      url = environment.urlApiGes + '/api/areas?ApiToken=' + environment.apiToken;
    }
    return this.http.get(url)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        const totalReg = data[0] !== undefined ? data[0].TotalRegistros : 0;
        let numReg = 0;
        data.forEach(elem => {
          const newElem = new AreaModel(elem.Id, elem.Nombre,
            AreasService.areasTipos.get(elem.IdAreaTipo > 0 ? elem.IdAreaTipo : -99),
            elem.Lat, elem.Lng, elem.Radio, elem.Observ, elem.FAlta, elem.FMod, elem.FBaja);
          AreasService.areas.set(newElem.id, newElem);
          result.push(newElem);
          numReg++;
        });
        this.mapService.addElements(result);
        // Cuando son areas de la APP de gestion no se piden por páginas
        if (environment.tipoGes !== 'TSNU') {
          if (numReg < totalReg) {
            const elemPag = (totalReg - numReg) > this.NUMREG ? this.NUMREG : totalReg - numReg;
            return this.getAreasPagina(2, numReg, elemPag, result);
          }
        }
        return result;
      }, error => {
        console.log('recursos::getAreas()' + error);
      });
  }

  private getAreasPagina(pagina: number, numReg: number, elemPag: number,
    resAcum: any[]): Promise<AreaModel[]> {
    const result: any = resAcum;
    return this.http.get(environment.urlApi + '/api/ambusat/areas/gestion/' + pagina + '/' + elemPag)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        const totalReg = data[0].TotalRegistros;
        const elements: AreaModel[] = [];
        data.forEach(elem => {
          const newArea = new AreaModel(elem.Id, elem.Nombre,
            AreasService.areasTipos.get(elem.IdAreaTipo),
            elem.Lat, elem.Lng, elem.Radio, elem.Observ, elem.FAlta, elem.FMod, elem.FBaja);
          AreasService.areas.set(newArea.id, newArea);
          elements.push(newArea);
          result.push(newArea);
          numReg++;
        });
        this.mapService.addElements(elements);
        if (numReg < totalReg) {
          const ep = (totalReg - numReg) > this.NUMREG ? this.NUMREG : totalReg - numReg;
          return this.getAreasPagina(pagina + 1, numReg, ep, result);
        }
        return result;
      }, error => {
        console.log('recursos::getAreasPagina()' + error);
      });
  }

  // Almacena un área
  saveArea(area: AreaModel): Promise<AreaModel> {
    let newArea: any = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    area.IdAreaTipo = area.tipo.id;
    let url = '';
    if (environment.tipoGes !== 'TSNU') {
      url = environment.urlApi + '/api/ambusat/areas/gestion';
    } else {
      url = environment.urlApiGes + '/api/areas';
      area.ApiToken = environment.apiToken;
    }
    return this.http.post(url, Utils.capitalizeJSON(JSON.stringify(area)), httpOptions)
      .toPromise()
      .then(response => {
        const area2 = JSON.parse(JSON.stringify(response));
        newArea = new AreaModel(area2.Id, area2.Nombre, AreasService.areasTipos.get(area2.IdAreaTipo),
          area2.Lat, area2.Lng, area2.Radio, area2.Observ, new Date(area2.FAlta),
          new Date(area2.FMod), null);
        // Actualizo los datos en cache
        AreasService.areas.set(newArea.id, newArea);
        return newArea;
      }, error => {
        console.log(error);
        return newArea;
      });
  }

  // Borra un área
  deleteArea(area: number): Promise<void> {
    return this.http.delete(environment.urlApi + '/api/ambusat/areas/gestion/' + area)
      .toPromise()
      .then(response => {
        // Modifico la fecha de baja del registro en la cache
        const reg = AreasService.areas.get(area);
        if (reg !== undefined) {
          reg.fBaja = new Date();
        }
        AreasService.areas.set(area, reg);
        return;
      }, error => {
        console.log(error);
      });
  }

  getZonas(areaId: number): Promise<AreaZonaModel[]> {
    const result: any = [];
    return this.http.get(environment.urlApi + '/api/ambusat/areas/zonas/' + areaId)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach(elem => {
          let puntosZona = null;
          if (elem.Puntos !== null && elem.Puntos.length > 0) {
            puntosZona = [];
            elem.Puntos.forEach(p => {
              puntosZona.push(new AreaZonaPuntosModel(p.Id, p.IdZona, p.Orden, p.Lat, p.Lng));
            });
          }
          const newZona = new AreaZonaModel(elem.Id, elem.Nombre,
            AreasService.areas.get(elem.IdArea), elem.Tipo, elem.Lat, elem.Lng, elem.Radio,
            elem.Observ, puntosZona, elem.FAlta, elem.FMod, elem.FBaja);
          AreasService.zonas.set(newZona.id, newZona);
          result.push(newZona);
        });
        return result;
      }, error => {
        console.log('recursos::getZonas()' + error);
      });
  }

  // Almacena una zona de control
  saveZona(zona: AreaZonaModel): Promise<AreaZonaModel> {
    let newZona: any = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    zona.IdArea = zona.area.id;
    const s = Utils.capitalizeJSON(JSON.stringify(zona));
    return this.http.post(environment.urlApi + '/api/ambusat/areas/zonas', Utils.capitalizeJSON(JSON.stringify(zona)), httpOptions)
      .toPromise()
      .then(response => {
        const zona2 = JSON.parse(JSON.stringify(response));
        let puntosZona = null;
        if (zona2.Puntos !== null && zona2.Puntos.length > 0) {
          puntosZona = [];
          zona2.Puntos.forEach(p => {
            puntosZona.push(new AreaZonaPuntosModel(p.Id, p.IdZona, p.Orden, p.Lat, p.Lng));
          });
        }
        newZona = new AreaZonaModel(zona2.Id, zona2.Nombre, AreasService.areas.get(zona2.IdArea),
          zona2.Tipo, zona2.Lat, zona2.Lng, zona2.Radio, zona2.Observ, puntosZona,
          new Date(zona2.FAlta), new Date(zona2.FMod), null);
        // Actualizo los datos en cache
        AreasService.zonas.set(newZona.id, newZona);
        return newZona;
      }, error => {
        console.log(error);
        return newZona;
      });
  }

  // Borra una zona
  deleteZona(zona: number): Promise<void> {
    return this.http.delete(environment.urlApi + '/api/ambusat/areas/zonas/' + zona)
      .toPromise()
      .then(response => {
        // Elimino el registro de la cache
        AreasService.zonas.delete(zona);
        return;
      }, error => {
        console.log(error);
      });
  }
}
