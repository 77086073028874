<jqxWindow [width]="520" [showCloseButton]="true" [height]="402" [resizable]="false">
    <div style="overflow-x: hidden; ">
        Asociación de tabletas a vehículos
    </div>
    <div style="float:left; overflow-x: hidden; background-color:lightgrey; ">
        <div style="float: left; width: 49%; height: 358px;">
            <jqxListBox #lbTablets [height]="'100%'" [width]="'100%'" [checkboxes]="false" [valueMember]="'id'" (onSelect)="onSelectTablet($event)">
            </jqxListBox>
        </div>
        <div style="float: left; width: 49%; margin-left: 10px; height: 358px;">
            <jqxListBox #lbMoviles [height]="'100%'" [width]="'100%'" [checkboxes]="true" [valueMember]="'id'" (onCheckChange)="onSelectMovil($event)">
            </jqxListBox>
        </div>
    </div>
</jqxWindow>