import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreasFilterComponent } from './areas-filter/areas-filter.component';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { AreasService } from './areas.service';
import { AreasGestionComponent } from './areas-gestion/areas-gestion.component';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AreasFilterComponent, AreasGestionComponent],
  imports: [
    CommonModule,
    jqxWindowModule,
    jqxListBoxModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxDropDownListModule,
    jqxGridModule,
    BrowserModule,
    FormsModule
  ],
  exports: [
    AreasFilterComponent
  ],
  providers: [
    AreasService
  ]
})
export class AreasModule { }
