export class AreaTipoModel {
    id: number;
    nombre: string;
    imagen: string;
    observ: string;
    visible: boolean;
    fAlta: Date;
    fMod: Date;
    fBaja: Date;

    constructor(id: number, nombre: string, imagen: string, observ: string,
        fAlta: Date, fMod: Date, fBaja: Date) {
        this.id = id;
        this.nombre = nombre;
        this.imagen = imagen;
        this.observ = observ;
        this.fAlta = fAlta;
        this.fMod = fMod;
        this.fBaja = fBaja;
        this.visible = false;
    }

}
