<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="'Procesando...'">
</jqxLoader>
<jqxWindow #form [width]="760" [height]="530" [zIndex]="50" [autoOpen]="true" [showCloseButton]="true"
    [showCollapseButton]="true" [resizable]="false">
    <div class="formHeader" style="overflow-x: hidden;">
        Informes programados
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="float:left; margin-top: 6px; width: 100%;">
            <jqxGrid #grid [width]="'100%'" [source]="dataAdapter" [pagesize]="10" [columnsresize]="true"
                [localization]="localization" [filterable]="true" [showfilterrow]="true" [columns]="columns"
                [pageable]="true" [autoheight]="true" [sortable]="true" [altrows]="true" [enabletooltips]="true"
                [editable]="false" [scrollmode]="'deferred'" (onRowclick)="onRowSelect($event)">
            </jqxGrid>
        </div>
        <div style="float: left; margin-top: 6px;">
            <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"' (onClick)="onCrearClick($event)"
                [hidden]="!canEdit">
                Crear
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
                (onClick)="onEditarClick($event)">
                Editar
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"'
                (onClick)="onBorrarClick($event)" [hidden]="!canEdit">
                Borrar
            </jqxButton>
        </div>
    </div>
</jqxWindow>

<div *ngIf="mostrarEditForm">
    <jqxWindow #formEdit [width]="605" [height]="586" [zIndex]="100" [autoOpen]="true" [isModal]="false"
        [showCloseButton]="true" [showCollapseButton]="false" [resizable]="false" (onClose)="onCloseEdit()"
        (onOpen)="onOpenEdit()">
        <div class="formHeader" style="overflow-x: hidden;">
            Edición de informes programados
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="float: left;  width: 100%;">
                <div style="margin-top: 5px; clear: boot;">
                    Nombre<br>
                    <input #ebNombre [(ngModel)]="informeEdit.Nombre" type='text' style="width: 584px;"
                        [disabled]="!canEdit" />
                </div>
                <div style="float: left; margin-top: 4px;">
                    Tipo de informe<br>
                    <jqxDropDownList #cbTipoInforme style="float:left; margin-right: 10px;" [width]="200" [height]="25"
                        [selectedIndex]="0" [enableBrowserBoundsDetection]="'true'"
                        (onSelect)="onSelectTipoInforme($event)">
                    </jqxDropDownList>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Diario
                    <input #chbDiario [(ngModel)]="informeEdit.Diario" type='checkbox' />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Semanal
                    <input #chbSemanal [(ngModel)]="informeEdit.Semanal" type='checkbox'
                        [disabled]="informeEdit.Tipo==1 || informeEdit.Tipo==2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mensual
                    <input #chbMensual [(ngModel)]="informeEdit.Mensual" type='checkbox'
                        [disabled]="informeEdit.Tipo==1 || informeEdit.Tipo==2" />
                </div>
                <div style=" margin-top: 5px; float:left; width: 245px; clear:both;">
                    Observaciones<br>
                    <input [(ngModel)]="informeEdit.Observaciones" type='text' style="width: 584px;"
                        [disabled]="!canEdit" />
                </div>
            </div>
            <div style="float: left; margin-top: 5px; clear: both;">
                <div style="float: left; width: 250px; height: 340px;">
                    <div style="float:left;">
                        <b>Móviles</b><br>
                        <jqxDropDownList #cbGrupos style="float:left;" [width]="250" [height]="26" [selectedIndex]="0"
                            (onSelect)="onChangeGrupos($event)"></jqxDropDownList><br>
                        <jqxButton style="float:left;" [width]='80' [height]='25'
                            [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgPosition]='"right"'
                            [imgSrc]='"/assets/images/sensores/todos.png"' (onClick)="onTodosClick($event)"
                            [disabled]="!canEdit">
                            Todos
                        </jqxButton>
                        <jqxButton style="float:left; margin-left: 2px;" [width]='80' [height]='25'
                            [textImageRelation]='"imageBeforeText"' [textPosition]='"right"' [imgPosition]='"left"'
                            [imgSrc]='"/assets/images/sensores/ninguno.png"' (onClick)="onNingunoClick($event)"
                            [disabled]="!canEdit">
                            Ninguno
                        </jqxButton>
                    </div>
                    <jqxListBox #lbMoviles [height]="'312'" [width]="'100%'" [checkboxes]="true" [valueMember]="'id'"
                        [disabled]="!canEdit">
                    </jqxListBox>
                </div>
                <div style="float: left; margin-left: 7px; width: 335px; height: 340px;">
                    <b>Usuarios</b><br>
                    <div style="float:left;">
                        <jqxButton style="float:left;" [width]='80' [height]='25'
                            [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgPosition]='"right"'
                            [imgSrc]='"/assets/images/sensores/todos.png"' (onClick)="onTodosClick2($event)"
                            [disabled]="!canEdit">
                            Todos
                        </jqxButton>
                        <jqxButton style="float:left; margin-left: 2px;" [width]='80' [height]='25'
                            [textImageRelation]='"imageBeforeText"' [textPosition]='"right"' [imgPosition]='"left"'
                            [imgSrc]='"/assets/images/sensores/ninguno.png"' (onClick)="onNingunoClick2($event)"
                            [disabled]="!canEdit">
                            Ninguno
                        </jqxButton>
                    </div>
                    <jqxListBox #lbUsuarios [height]="'100%'" [width]="'100%'" [checkboxes]="true" [valueMember]="'id'"
                        [disabled]="!canEdit">
                    </jqxListBox>
                </div>
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both;">
                <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarClick($event)"
                    [disabled]="!canEdit">
                    Guardar
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>