<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="'Procesando...'">
</jqxLoader>
<jqxWindow #form [width]="1100" [height]="574" [zIndex]="99" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false">
    <div class="formHeader" style="overflow-x: hidden;">
        Resumen de movilidad
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="float: left; margin-top: 2px; clear: both;">
            <div style="float:left;">Periodo</div>
            <div style="float:left;"></div>
            <div style="float:left; margin-left: 296px;">Grupos</div>
            <div style="float:left; margin-left: 190px;">Recursos</div>
            <div style="float: left; clear: both;">
                <app-periodo-select #periodoSelect></app-periodo-select>
                <jqxDropDownList #cbGrupos style="float:left; margin-left: 4px;" [width]="230" [height]="26" [selectedIndex]="0" (onSelect)="onChangeGrupos($event)">
                </jqxDropDownList>
                <jqxDropDownList #cbMoviles style="float:left; margin-left: 4px;" [width]="230" [height]="26" [selectedIndex]="0">
                </jqxDropDownList>
                <jqxButton style="float:left; margin-left: 18px;" [width]='100' [height]='27' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onAceptar($event)">
                    Aceptar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='27' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/exportar.png"' (onClick)="onExportar($event)">
                    Exportar
                </jqxButton>
            </div>
        </div>
        <div style="float:left; margin-top: 6px; width: 100%;">
            <jqxGrid #grid [width]="'100%'" [source]="dataAdapter" [pagesize]="10" [columnsresize]="true" [localization]="localization" [filterable]="true" [showfilterrow]="true" [columns]="columns" [pageable]="true" [autoheight]="true" [sortable]="true" [altrows]="true"
                [enabletooltips]="true" [editable]="false" [scrollmode]="'deferred'" [columngroups]="columngroups">
            </jqxGrid>
        </div>
    </div>
</jqxWindow>