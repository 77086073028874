import { AreaModel } from '../../areas/modelos/area-model';
import { BaseMarker } from './base-marker';
import { AreasService } from '../../areas/areas.service';

declare var google: any;

export class AreaMarker extends BaseMarker {
    private area: AreaModel;
    public circle: any;

    constructor(area: AreaModel, map0: any, drag: boolean) {
        // En este caso no le asigno el mapa porque estos marcadores se
        // gestionan a través de un cluster
        super(area.nombre, area.lat, area.lng,
            'data:image/jpg;base64,' + area.tipo.imagen, 0, null, drag);
        this.area = area;
        this.circle = new google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.2,
            strokeWeight: 0.5,
            fillColor: '#FF0000',
            fillOpacity: 0.08,
            map: map0,
            center: this.marker.getPosition(),
            radius: area.radio,
            visible: AreasService.areasTipos.get(area.tipo.id).visible
        });
        google.maps.event.addListener(this.circle, 'click', (event => {
            const mev = {
                stop: null,
                latLng: event.latLng
            };
            google.maps.event.trigger(map0, 'click', mev);
        }));
    }

    getArea(): AreaModel {
        return this.area;
    }
}
