<div class="main" id="mainLayout">
    <div class="header" id="headerLayout">
        <div class="header" style="float: left; ">
            <img width="15%" style="float: left;" src="assets/images/empresa/movisat.png ">
            <img width="15%" style="float: left;" src="assets/images/empresa/ambusat.png">
            <img width="14%" style="float: left; margin-left: 300px;" src="assets/images/empresa/cliente.png">
        </div>
    </div>
    <div class="body" id="bodyLayout">
        <app-confirm-dialog></app-confirm-dialog>
        <div class="login" style="float:left">
            <div style="margin: 10px;float: right;">
                <br>
                <div style="float: right;">
                    Usuario
                    <input [(ngModel)]="usuarioSelec" (keyup.enter)="onUserEnter()" type='text' style="float: right; margin-left: 5px; height: 17px; width: 175px; border: 1px solid #aaa;" />
                </div>
                <br><br>
                <div style="float: right;">
                    Contraseña
                    <input #passEditBox [(ngModel)]="passwordSelec" (keyup.enter)="onPasswEnter()" type='password' style="float: right; margin-left: 5px; height: 17px; width: 175px; border: 1px solid #aaa;" />
                </div>
                <br>
                <div style="float: right;">
                    <sub><input type="checkbox" (click)="verPassword()">Ver contraseña</sub>
                </div>
                <br><br>
                <div style="float: right;">
                    <jqxButton (click)="onClick($event)" style="display: block; margin-top: 5px;" [disabled]="!ready">
                        Acceder >
                    </jqxButton>
                </div>
                <div style="float: right; clear: both;">
                    <br>
                    <sub><a href="#" (click)="onRecuperarPassw();">¿Olvidó su contraseña?</a></sub>
                </div>
                <div #sinConexion style="float:left; color: red;" [hidden]="ready || intentos<3">
                    <sub>Sin conexión con el servidor!</sub><br>
                    <sub>Seguimos intentando... {{intentos}}</sub><br>
                </div>
            </div>
        </div>
        <div class="fondo " style="float:left; margin-left: 20px;">
            <img width="100% " height="100% " src="assets/images/fondo-login.png ">
        </div>
    </div>
    <div class="footer" id="footerLayout">
        <div style="float: right; margin-right: 10px; ">
            <sub>Powered by <a href="http://www.movisat.com/ ">
          &copy; MOVISAT TecnoMovilidad</a> Todos los derechos reservados
      </sub>
        </div>
    </div>
</div>