import { UsuarioModel } from '../login/modelos/usuario-model';
import { environment } from '../../environments/environment';

enum Rol {
    ADMIN = 1,
    PERFIL_1 = 2,
    Perfil_2 = 3
}

export class Permission {

    public static canEditAgrupaciones(usuario: UsuarioModel): boolean {
        switch (usuario.rol.id) {
            case Rol.ADMIN:
            case Rol.PERFIL_1:
                return true;
        }
        return false;
    }

    public static canEditAreasGestion(usuario: UsuarioModel): boolean {
        switch (usuario.rol.id) {
            case Rol.ADMIN:
            case Rol.PERFIL_1:
                return true;
        }
        return false;
    }

    public static canEditAlarmas(usuario: UsuarioModel): boolean {
        switch (usuario.rol.id) {
            case Rol.ADMIN:
            case Rol.PERFIL_1:
                return true;
        }
        return false;
    }

}
