import { MovilModel } from '../../recursos/modelos/movil-model';
export class PosicionModel {
    movil: MovilModel;
    fecha: Date;
    lat: number;
    lng: number;
    velocidad: number;
    altura: number;
    rumbo?: number;
    estado?: boolean;
    ignicion?: boolean;
    x?: number;
    y?: number;
    icono?: string;
    direccion?: string;

    // Sólo para los informes
    sirena: boolean;
    puenteLuces: boolean;

    constructor(movil: MovilModel, fecha: Date, lat: number, lng: number, velocidad: number,
        altura: number, rumbo: number, estado: boolean, ignicion: boolean, x: number, y: number, icono: string, direccion: string = '') {
        this.movil = movil;
        this.fecha = fecha;
        this.lat = lat;
        this.lng = lng;
        this.velocidad = velocidad;
        this.altura = altura;
        this.rumbo = rumbo;
        this.estado = estado;
        this.ignicion = ignicion;
        this.x = x;
        this.y = y;
        this.icono = icono;
        this.direccion = direccion;
    }
}
