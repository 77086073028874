export class GrupoModel {
    id: number;
    nombre: string;
    observ: string;
    fAlta: Date;
    fMod: Date;
    fBaja: Date;
    // Sólo a efectos de envío de información a la API
    moviles: number[];

    constructor(id: number, nombre: string, observ: string,
        fAlta: Date, fMod: Date, fBaja: Date) {
        this.id = id;
        this.nombre = nombre;
        this.observ = observ;
        this.fAlta = fAlta;
        this.fMod = fMod;
        this.fBaja = fBaja;
        this.moviles = [];
    }
}
