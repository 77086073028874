import { UsuarioModel } from "src/app/login/modelos/usuario-model";
import { MovilModel } from "src/app/recursos/modelos/movil-model";

export class InformeModel {
    Id: number;
    Nombre: string;
    Tipo: number;
    Diario: boolean;
    Semanal: boolean;
    Mensual: boolean;
    Observaciones: string;
    Moviles: InformeMovilModel[];
    Usuarios: InformeUsuarioModel[];
    // Sólo para llamadas a la API
    movilesId: number[];
    usuariosId: number[];

    constructor(id: number, nombre: string, tipo: number, diario: boolean, semanal: boolean, mensual: boolean, observaciones: string) {
        this.Id = id;
        this.Nombre = nombre;
        this.Tipo = tipo;
        this.Diario = diario;
        this.Semanal = semanal;
        this.Mensual = mensual;
        this.Observaciones = observaciones;
        this.Moviles = [];
        this.Usuarios = [];
    }
}

export class InformeMovilModel {
    Id: number;
    InformeId: number;
    MovilId: number;

    constructor(informeId: number, movilId: number) {
        this.InformeId = informeId;
        this.MovilId = movilId;
    }
}

export class InformeUsuarioModel {
    Id: number;
    InformeId: number;
    UsuarioId: number;

    constructor(informeId: number, usuarioId: number) {
        this.InformeId = informeId;
        this.UsuarioId = usuarioId;
    }
}
