import { PosicionModel } from '../../posiciones/modelos/posicion-model';
import { BaseMarker } from './base-marker';
import { Utils } from '../../utils/utils';
import { SensoresService } from '../../sensores/sensores.service';
import { EstadoMovilModel } from 'src/app/recursos/modelos/estado-movil-model';
import { MapsService } from '../maps.service';
import { MovilModel } from '../../recursos/modelos/movil-model';
import { GoogleMapSegComponent } from '../google-map-seg/google-map-seg.component';
import { environment } from '../../../environments/environment';
import { RecursosService } from 'src/app/recursos/recursos.service';
import { EstadoMovilGestionModel } from 'src/app/recursos/modelos/estado-movil-gestion-model';

declare var google: any;

export class MovilMarker extends BaseMarker {
    private static lastInfoWindow: any = null;
    private static lastInfoWindowSeguir: any = null;
    private infoWindow: any = null;
    private posicion: PosicionModel;
    private mapsService: MapsService;
    private seguir = false;
    public markerRumbo: any;

    // Creo el marcador para indicar el rumbo
    private svgMarker = {
        path: "m249.176 3.656-203.511 499.491c-2.15 5.277 3.719 10.215 8.551 7.195l198.3-123.961c1.268-.793 2.877-.796 4.148-.009l200.478 124.057c4.843 2.997 10.687-1.962 8.52-7.228l-205.618-499.564c-2-4.859-8.885-4.846-10.868.019z",
        fillColor: 'red',
        fillOpacity: 0.4,
        strokeWeight: 0.3,
        rotation: 0,
        scale: 0.04,
        anchor: new google.maps.Point(255, 255)
    };

    constructor(posicion: PosicionModel, map: any, mapService: MapsService, seguir: boolean, soloInfo = false) {
        super(posicion.movil.nombre, posicion.lat, posicion.lng,
            'data:image/jpg;base64,' + posicion.icono, 0, map, false);
        this.posicion = posicion;
        this.mapsService = mapService;
        this.seguir = seguir;
        // Creo el marcador para el rumbo
        this.svgMarker.rotation = posicion.rumbo;
        this.markerRumbo = new google.maps.Marker({
            position: new google.maps.LatLng(posicion.lat, posicion.lng),
            icon: this.svgMarker,
            zIndex: -1
        });
        this.markerRumbo.setMap(map);
        // Creo el listener para cuando se pincha sobre el marcador
        google.maps.event.addListener(this.getMarker(), 'click', (event => {
            if (this.infoWindow !== null) {
                this.infoWindow.close();
            }
            if (!seguir && MovilMarker.lastInfoWindow !== null) {
                MovilMarker.lastInfoWindow.close();
            }
            if (seguir && MovilMarker.lastInfoWindowSeguir !== null) {
                MovilMarker.lastInfoWindowSeguir.close();
            }
            this.setInfoWindowContent(posicion, soloInfo);
            if (!seguir) {
                MovilMarker.lastInfoWindow = this.infoWindow;
            } else {
                MovilMarker.lastInfoWindowSeguir = this.infoWindow;
            }
        }));
    }

    // Pone en seguimiento un móvil
    onSeguir(movil: MovilModel) {
        this.mapsService.addMovilSegimiento(movil);
        // Quito el botón y pongo otro que permite ver la posición 
        // en la ventana de deguimiento
        const btn = document.getElementById('btnSeguir');
        btn.outerHTML = '<div style="float: left;">En seguimiento...</div>' +
            '<button id="btnVer" style="float: left; margin-left: 5px;">Ver >></button>';
        const btnVer = document.getElementById('btnVer');
        btnVer.addEventListener('click', ($event) => this.onVerSeguimiento(movil));
    }

    // Quita de seguimiento un móvil
    onNoSeguir(movil: MovilModel) {
        this.markerRumbo.setMap(null);
        this.mapsService.removeMovilSegimiento(movil);
    }

    // Permite ver la posición de un móvil en la cartografía de seguimiento
    onVerSeguimiento(movil: MovilModel) {
        this.mapsService.verMovilSegimiento(movil);
    }

    getPosicion(): PosicionModel {
        return this.posicion;
    }

    setPosicion(posicion: PosicionModel) {
        this.posicion = posicion;
        super.updatePosition(posicion.lat, posicion.lng);
        // Actualizo el marcador del rumbo
        this.markerRumbo.setMap(null);
        this.svgMarker.rotation = posicion.rumbo;
        this.markerRumbo = new google.maps.Marker({
            position: new google.maps.LatLng(posicion.lat, posicion.lng),
            icon: this.svgMarker,
            zIndex: -1
        });
        this.markerRumbo.setMap(this.map);
        // Si está abierta la ventana de información actualizo el contenido
        if (this.infoWindow !== null && this.infoWindow.getMap() !== null && this.infoWindow.getMap() !== undefined) {
            this.setInfoWindowContent(posicion, false);
        }
    }

    setInfoWindowContent(posicion: PosicionModel, soloInfo: boolean) {
        // Obtengo el estado de los sensores
        let estado = SensoresService.estadoMovil.get(posicion.movil.id);
        if (estado === undefined) {
            estado = new EstadoMovilModel(posicion.movil.id, false, false, false, false, null);
        }
        let estadoGes = '';
        if (environment.tipoGes === 'TSNU') {
            posicion.movil.estadoGes = RecursosService.estadoGestion.get(posicion.movil.id);
            if (posicion.movil.estadoGes !== undefined) {
                estadoGes = "<br>Pacientes a bordo: " + posicion.movil.estadoGes.numPersonas
            }
        }
        let sirena = '';
        if (estado.sirena) {
            sirena = '<img width="15" height="15" style="float: left; margin-right: 2px;" ' +
                'src="assets/images/sirena-on.png"/>';
        } else {
            sirena = '<img width="15" height="15" style="float: left; margin-right: 2px;" ' +
                'src="assets/images/sirena-off.png"/>';
        }
        let luces = '';
        if (estado.puenteLuces) {
            luces = '<img width="15" height="15" style="float: left; margin-right: 2px;" ' +
                'src="assets/images/luces-on.png"/>';
        } else {
            luces = '<img width="15" height="15" style="float: left; margin-right: 2px;" ' +
                'src="assets/images/luces-off.png"/>';
        }
        if (!soloInfo) {
            let botones = '';
            if (!this.seguir) {
                if (GoogleMapSegComponent.movilesSeguimiento.get(posicion.movil.id) === undefined) {
                    botones = '<button id="btnSeguir" style="float: left;">Seguir >></button>';
                } else {
                    botones = '<div style="float: left;">En seguimiento...</div>' +
                        '<button id="btnVer" style="float: left; margin-left: 5px;">Ver >></button>';
                }
            } else {
                botones = '<button id="btnNoSeguir" style="float: left;"><< Dejar de seguir</button>';
            }
            if (this.infoWindow === null) {
                this.infoWindow = new google.maps.InfoWindow({
                    content:
                        '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
                        sirena + luces +
                        '<b>' + posicion.movil.nombre + '</b>' + '<hr style = "color: navy;" />' +
                        '<b>' + posicion.movil.tipoVehiculo + '</b><br>' +
                        Utils.formatDateTime(posicion.fecha, true) + '<br>' +
                        posicion.velocidad + ' km/h  Altitud: ' + posicion.altura + ' m.<br>' +
                        (estado.igni ? 'Con ignición' : 'Sin ignición') + estadoGes +
                        '</div><br>' + botones
                });
            } else {
                this.infoWindow.setContent(
                    '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
                    sirena + luces +
                    '<b>' + posicion.movil.nombre + '</b>' + '<hr style = "color: navy;" />' +
                    '<b>' + posicion.movil.tipoVehiculo + '</b><br>' +
                    Utils.formatDateTime(posicion.fecha, true) + '<br>' +
                    posicion.velocidad + ' km/h  Altitud: ' + posicion.altura + ' m.<br>' +
                    (estado.igni ? 'Con ignición' : 'Sin ignición') + estadoGes +
                    '</div><br>' + botones
                );
            }
            // Asigno el evento para los botones de seguimiento
            const listener = google.maps.event.addListener(this.infoWindow, 'domready', () => {
                google.maps.event.removeListener(listener);
                if (!this.seguir) {
                    if (GoogleMapSegComponent.movilesSeguimiento.get(posicion.movil.id) === undefined) {
                        const btnSeguir = document.getElementById('btnSeguir');
                        btnSeguir.addEventListener('click', ($event) => this.onSeguir(posicion.movil));
                    } else {
                        const btnVer = document.getElementById('btnVer');
                        btnVer.addEventListener('click', ($event) => this.onVerSeguimiento(posicion.movil));
                    }
                } else {
                    const btnNoSeguir = document.getElementById('btnNoSeguir');
                    btnNoSeguir.addEventListener('click', ($event) => this.onNoSeguir(posicion.movil));
                }
            });
        } else {
            if (this.infoWindow === null) {
                this.infoWindow = new google.maps.InfoWindow({
                    content:
                        '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
                        sirena + luces +
                        '<b>' + posicion.movil.nombre + '</b>' + '<hr style = "color: navy;" />' +
                        '<b>' + posicion.movil.tipoVehiculo + '</b><br>' +
                        Utils.formatDateTime(posicion.fecha, true) + '<br>' +
                        posicion.velocidad + ' km/h  Altitud: ' + posicion.altura + ' m.<br>' +
                        (estado.igni ? 'Con ignición' : 'Sin ignición') + estadoGes + '</div>'
                });
            } else {
                this.infoWindow.setContent(
                    '<div style="margin: 0px; text-align: center; font: 1em Trebuchet MS, Helvetica, Arial, sans-serif;">' +
                    sirena + luces +
                    '<b>' + posicion.movil.nombre + '</b>' + '<hr style = "color: navy;" />' +
                    '<b>' + posicion.movil.tipoVehiculo + '</b><br>' +
                    Utils.formatDateTime(posicion.fecha, true) + '<br>' +
                    posicion.velocidad + ' km/h  Altitud: ' + posicion.altura + ' m.<br>' +
                    (estado.igni ? 'Con ignición' : 'Sin ignición') + estadoGes + '</div>'
                );
            }
        }
        this.infoWindow.open(this.map, this.getMarker());
    }

    setVisible(visible: boolean) {
        // Si está abierta y oculto el marcador oculto también la ventana de información
        if (!visible && this.infoWindow !== null && this.infoWindow.getMap() !== null &&
            this.infoWindow.getMap() !== undefined) {
            this.infoWindow.close();
        }
        this.marker.setMap(visible ? this.map : null);
        this.markerRumbo.setMap(visible ? this.map : null);
    }

}
