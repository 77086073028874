import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { Idioma } from '../../utils/idioma';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { AreaModel } from '../modelos/area-model';
import { AreaTipoModel } from '../modelos/area-tipo-model';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { AreasService } from '../areas.service';
import { MapsService } from '../../maps/maps.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from '../../utils/confirm-dialog/confirm-dialog.service';
import { AreaZonaModel } from '../modelos/area-zona-model';
import { GoogleMapComponent } from '../../maps/google-map/google-map.component';
import { AreaZonaPuntosModel } from '../modelos/area-zona-puntos-model';
import { Permission } from '../../utils/permission';
import { LoginService } from '../../login/login.service';
import { environment } from '../../../environments/environment';

declare var google: any;

@Component({
  selector: 'app-areas-gestion',
  templateUrl: './areas-gestion.component.html',
  styleUrls: ['./areas-gestion.component.css']
})
export class AreasGestionComponent implements OnInit, AfterViewInit {
  @ViewChild('formAreas') formAreas: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('cbTiposFilter') cbTiposFilter: jqxDropDownListComponent;
  @ViewChild('cbTipos') cbTipos: jqxDropDownListComponent;
  @ViewChild('cbTiposImport') cbTiposImport: jqxDropDownListComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('ebNombre', { static: false }) ebNombre;
  @ViewChild('gridZonas') gridZonas: jqxGridComponent;
  @ViewChild('formZonas') formZonas: jqxWindowComponent;
  @ViewChild('formEditZona') formEditZona: jqxWindowComponent;
  @ViewChild('cbTiposZonas') cbTiposZonas: jqxDropDownListComponent;
  @ViewChild('fileUpload', { static: false }) fileUpload;

  // Subscripciones del componente
  private subscriptionClickMap: any = null;
  private subscriptionEscPress: any = null;

  // Variables para edición de áreas
  private areas: AreaModel[] = [];
  public areasTipos: AreaTipoModel[] = [];
  public areaSelec: AreaModel = null;
  public areaEdit: AreaModel = null;
  public areaTipo: AreaTipoModel;
  public areaTipoImport: AreaTipoModel = null;
  public canEdit = true;
  public tipoGes = environment.tipoGes;

  // Variables para edición de zonas
  private zonas: AreaZonaModel[] = [];
  public zonaSelec: AreaZonaModel = null;
  public zonaEdit: AreaZonaModel = null;
  private circle: any = null;
  private polygon: any = null;

  // Variables para mostrar u ocultar las ventanas
  public mostrarMainForm = true;
  public mostrarEditForm = false;
  public mostrarZonas = false;
  public mostrarEditZona = false;

  // Variables para el grid de áreas
  public sourceAreas: any = [];
  public dataAdapterAreas = new jqx.dataAdapter(this.sourceAreas);
  public columnsAreas: any[];

  // Variables para el grid de puntos de control
  public sourceZonas: any = [];
  public dataAdapterZonas = new jqx.dataAdapter(this.sourceAreas);
  public columnsZonas: any[];

  //  Esto es para que los textos en los controles del grid salgan en español
  public localization = Idioma.getLocalization('es');

  constructor(
    private loginService: LoginService,
    private areasService: AreasService,
    private mapService: MapsService,
    private toastrService: ToastrService,
    private confirmDialogService: ConfirmDialogService
  ) { }

  ngOnInit(): void {
    this.areaTipo = AreasService.areasTipos.get(1);
    this.canEdit = Permission.canEditAreasGestion(this.loginService.getUser());
  }

  ngAfterViewInit(): void {
    // Creo un área vacia para rellenar
    this.areaEdit = this.getEmptyArea();
    setTimeout(() => {
      // Recupero las áreas
      this.getAreas();
    }, 100);
  }

  open() {
    this.mostrarMainForm = true;
  }

  getAreas() {
    // Preparo el grid
    this.columnsAreas = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60 },
      { text: 'Nombre', columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 280 },
      { text: 'Tipo', columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo', width: 150 },
      { text: 'Observaciones', columntype: 'textbox', filtertype: 'textbox', datafield: 'observ', width: 250 },
      { text: 'Radio', columntype: 'textbox', filtertype: 'textbox', datafield: 'radio', width: 50 }
    ];
    this.areas = [];
    this.areaSelec = null;
    this.loader.open();
    // Recupero los tipos de área y las áreas;
    this.areasService.getAreasTipos().then(
      tipos => {
        this.areasTipos = tipos;
        this.cbTiposFilter.clear();
        this.cbTiposFilter.addItem({ label: 'Todas las áreas', value: 0 });
        this.cbTiposImport.clear();
        this.areasTipos.forEach(tipo => {
          this.cbTiposFilter.addItem({ label: tipo.nombre, value: tipo.id });
          this.cbTiposImport.addItem({ label: tipo.nombre, value: tipo.id });
        });
        this.cbTiposFilter.selectIndex(0);
        this.cbTiposImport.selectIndex(0);
        // Recupero las áreas de la caché
        const response = [];
        for (const [key, value] of AreasService.areas) {
          if (value.fBaja === null) {
            response.push(value);
          }
        }
        if (this.areas !== undefined && this.areas !== null) {
          this.sourceAreas = {
            datatype: 'json',
            datafields: [
              { name: 'id', type: 'number' },
              { name: 'nombre', type: 'string' },
              { name: 'tipo', map: 'tipo>nombre', type: 'string' },
              { name: 'observ', type: 'string' },
              { name: 'radio', type: 'number' }
            ],
            localdata: this.areas,
            sortcolumn: 'nombre',
            sortdirection: 'asc'
          };
          this.dataAdapterAreas = new jqx.dataAdapter(this.sourceAreas);
        }
        this.loader.close();
      },
      err => {
        this.loader.close();
        console.log(err);
      });
  }

  getEmptyArea(): AreaModel {
    const tipo = (this.areaTipo !== undefined && this.areaTipo.id > 0) ?
      AreasService.areasTipos.get(this.areaTipo.id) :
      AreasService.areasTipos.get(1);
    // Creo un área sin datos, por defecto del tipo que tengamos seleccionado en el grid
    tipo.visible = true;
    return new AreaModel(0, '', tipo, 0, 0, 30, '', new Date(), new Date(), null);
  }

  onSelectTiposAreas(event: any) {
    this.areaTipo = AreasService.areasTipos.get(event.args.item.value);
    this.loader.open();
    // Recupero las áreas de la caché
    const response = [];
    for (const [key, value] of AreasService.areas) {
      if (value.fBaja === null) {
        response.push(value);
      }
    }
    if (this.areaTipo !== undefined) {
      this.areas = [];
      response.forEach(area => {
        if (area.tipo.id === this.areaTipo.id) {
          this.areas.push(area);
        }
      });
    } else {
      this.areas = response;
    }
    this.sourceAreas = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'nombre', type: 'string' },
        { name: 'tipo', map: 'tipo>nombre', type: 'string' },
        { name: 'observ', type: 'string' },
        { name: 'radio', type: 'number' }
      ],
      localdata: this.areas,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };
    this.dataAdapterAreas = new jqx.dataAdapter(this.sourceAreas);
    this.loader.close();
  }

  onRowAreaSelect(event: any) {
    this.areaSelec = this.areas[event.args.rowindex];
  }

  onCrearAreaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    // Creo un área vacía para rellenar
    this.areaEdit = this.getEmptyArea();
    this.toastrService.info('Seleccione un punto sobre la cartografía', 'Por favor', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
    this.subscribeMapClick();
    this.formAreas.collapse();
  }

  onEditarAreaClick(event: any) {
    if (this.areaSelec !== null) {
      this.areaEdit = { ...this.areaSelec };
      this.openEditForm();
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onPosicionarAreaClick(event: any) {
    this.toastrService.info('Seleccione un punto sobre la cartografía', 'Por favor', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
    this.subscribeMapClick();
    this.formEdit.collapse();
  }

  // Muestra el formulario de edición de áreas
  openEditForm() {
    this.mostrarEditForm = true;
    if (this.formAreas !== undefined) {
      this.formAreas.collapse();
      this.formAreas.disable();
    }
  }

  onBorrarAreaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.areaSelec !== null) {
      this.deleteArea();
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onCentrarAreaClick(event: any) {
    if (this.areaSelec !== null) {
      if (this.areaSelec.lat !== 0 && this.areaSelec.lng !== 0) {
        this.mapService.centerArea(this.areaSelec.id);
        this.formAreas.collapse();
      } else {
        this.toastrService.error('El área seleccionada no tiene coordenadas. ' +
          'Pulse el botón "Editar" y posicionela sobre la cartografía', 'Atención', {
          timeOut: 4000,
          positionClass: 'toast-top-center'
        });
      }
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onRowZonaSelect(event: any) {
    this.zonaSelec = this.zonas[event.args.rowindex];
  }

  // Muestra el formulario de zonas de control
  onZonasDeControlClick(event: any) {
    if (this.areaSelec !== null) {
      this.mostrarZonas = true;
      if (this.formAreas !== undefined) {
        this.formAreas.collapse();
        this.formAreas.disable();
      }
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onCloseZonas() {
    if (this.subscriptionClickMap != null) {
      this.subscriptionClickMap.unsubscribe();
      this.subscriptionClickMap = null;
    }
    this.mostrarZonas = false;
    this.formAreas.enable();
    this.formAreas.expand();
  }

  onOpenZonas() {
    // Preparo el grid
    this.columnsZonas = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60 },
      { text: 'Nombre', columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 280 },
      { text: 'Tipo', columntype: 'textbox', filtertype: 'textbox', datafield: 'tipoNombre', width: 100 },
      { text: 'Observaciones', columntype: 'textbox', filtertype: 'textbox', datafield: 'observ', width: 280 }
    ];
    this.zonas = [];
    this.zonaSelec = null;
    this.loader.open();
    // Recupero las zonas
    this.areasService.getZonas(this.areaSelec.id).then(
      zonas => {
        if (zonas !== undefined && zonas !== null) {
          this.zonas = zonas;
        }
        this.sourceZonas = {
          datatype: 'json',
          datafields: [
            { name: 'id', type: 'number' },
            { name: 'nombre', type: 'string' },
            { name: 'tipoNombre', type: 'string' },
            { name: 'observ', type: 'string' }
          ],
          localdata: this.zonas,
          sortcolumn: 'nombre',
          sortdirection: 'asc'
        };
        this.dataAdapterZonas = new jqx.dataAdapter(this.sourceZonas);
        this.loader.close();
      },
      err => {
        this.loader.close();
        console.log(err);
      });
  }

  // Permite recibir las coordenadas al pinchar sobre el mapa
  subscribeMapClick(): void {
    if (this.subscriptionClickMap != null) {
      this.subscriptionClickMap.unsubscribe();
    }
    this.subscriptionClickMap = this.mapService.clickEmiter.subscribe(params => {
      if (!this.mostrarZonas) {
        if (!this.mostrarEditForm) {
          this.openCreateAreaForm(params);
        } else {
          this.areaEdit.lat = params.lat;
          this.areaEdit.lng = params.lng;
          this.formEdit.expand();
        }
      } else {
        this.addZonaPunto(params);
      }
    });
  }

  // Muestra el formulario para creación de áreas
  openCreateAreaForm(params: any) {
    this.areaEdit = this.getEmptyArea();
    this.areaEdit.lat = params.lat;
    this.areaEdit.lng = params.lng;
    this.mostrarEditForm = true;
    this.formAreas.disable();
  }

  onOpenEditArea() {
    setTimeout(() => {
      // Relleno el combo de tipos de área
      this.cbTipos.clear();
      let i = 0;
      AreasService.areasTipos.forEach(tipo => {
        this.cbTipos.addItem({ label: tipo.nombre, value: tipo.id });
        if (this.areaEdit.tipo !== undefined && this.areaEdit.tipo.id === tipo.id) {
          this.cbTipos.selectIndex(i);
        }
        i++;
      });
      if (this.areaEdit.tipo === undefined) {
        this.cbTipos.selectIndex(0);
      }
      this.ebNombre.nativeElement.focus();

      // Si no tiene tipo dejo editar para que se la pongan (-99=sin tipo de area)
      this.cbTipos.disabled(!this.canEdit ||
        (this.tipoGes === 'TSNU' && this.areaEdit.tipo.id !== -99));

      // Si no tiene radio pongo por defecto 30 metros
      if (this.areaEdit.radio < 1) {
        this.areaEdit.radio = 30;
      }

    }, 100);
  }

  deleteArea() {
    if (!this.canEdit) {
      return;
    }
    this.confirmDialogService.confirmThis(
      'Seguro que quiere borrar el área: ' + this.areaSelec.nombre, () => {
        this.areasService.deleteArea(this.areaSelec.id).then(
          response => {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.areas.length; i++) {
              if (this.areas[i].id === this.areaSelec.id) {
                this.areas.splice(i, 1);
                this.mapService.removeArea(this.areaSelec);
                break;
              }
            }
            this.areaSelec = null;
            this.grid.updatebounddata('cells');
            this.toastrService.info('Registro borrado', 'Información', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          },
          err => {
            this.toastrService.error('No se ha podido realizar la operación', 'Atención', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          });
      }, () => {
      });
  }

  onChangeTipoArea(event: any) {
    this.areaEdit.tipo = new AreaTipoModel(event.args.item.value, event.args.item.label,
      null, '', null, null, null);
  }

  onCloseEdit() {
    if (this.subscriptionClickMap != null) {
      this.subscriptionClickMap.unsubscribe();
      this.subscriptionClickMap = null;
    }
    this.mostrarEditForm = false;
    this.formAreas.enable();
    this.formAreas.expand();
  }

  onGuardarAreaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.areaEdit.nombre === null || this.areaEdit.nombre.length < 1) {
      this.toastrService.error('Introduzca un nombre', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.saveArea();
  }

  // Guardo el área
  saveArea() {
    if (!this.canEdit) {
      return;
    }
    if (this.areaEdit.tipo.id < 1) {
      this.toastrService.error('Seleccione el tipo de área', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.areaEdit.radio < 0) {
      this.toastrService.error('Introduzca el radio de acción', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.areasService.saveArea(this.areaEdit).then(
      response => {
        if (response !== undefined && response !== null) {
          if (this.areaEdit.id === 0) {
            this.areas.push(response);
            this.mapService.addArea(response);
          } else {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.areas.length; i++) {
              if (this.areas[i].id === this.areaEdit.id) {
                this.areas[i] = { ...response };
                break;
              }
            }
            this.mapService.updateArea(response);
          }
          // Añado el área a la cache
          AreasService.areas.set(response.id, response);
          this.areaEdit = { ...response };
          this.areaSelec = { ...this.areaEdit };
          if (this.grid !== undefined && this.grid !== null) {
            this.grid.updatebounddata('cells');
          }
          this.toastrService.info('Registro almacenado', 'Información', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        }
      },
      err => {
        this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
      });
    this.formEdit.close();
  }

  onGuardarZonaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.zonaEdit.nombre === null || this.zonaEdit.nombre.length < 1) {
      this.toastrService.error('Introduzca un nombre', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.zonaEdit.tipo === 0) {
      if (this.circle === undefined || this.circle === null) {
        this.toastrService.error('Seleccione el punto sobre la cartografía usando el botón \'Dibujar\'', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.circle.setRadius(this.zonaEdit.radio); // Por si se ha cambiado a mano
      this.zonaEdit.lat = this.circle.getCenter().lat();
      this.zonaEdit.lng = this.circle.getCenter().lng();
      this.zonaEdit.radio = Math.round(this.circle.getRadius());
      this.zonaEdit.puntos = null;
      if (this.zonaEdit.lat === 0 && this.zonaEdit.lng === 0) {
        this.toastrService.error('Seleccione el punto sobre la cartografía usando el botón \'Dibujar\'', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (this.zonaEdit.radio < 1) {
        this.toastrService.error('Introduzca el radio', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
        return;
      }
    }
    if (this.zonaEdit.tipo === 1) {
      if (this.polygon === undefined || this.polygon === null) {
        this.toastrService.error('Defina un polígono usando el botón \'Dibujar\'', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
        return;
      }
      const puntos = this.polygon.getPath();
      if (puntos === null || puntos === undefined || puntos.length < 3) {
        this.toastrService.error('El polígono que ha definido no tiene puntos suficientes', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.zonaEdit.lat = 0;
      this.zonaEdit.lng = 0;
      this.zonaEdit.radio = 0;
      this.zonaEdit.puntos = [];
      puntos.forEach(p => {
        this.zonaEdit.puntos.push(
          new AreaZonaPuntosModel(0, this.zonaEdit.id, this.zonaEdit.puntos.length + 1,
            p.lat(), p.lng())
        );
      });
    }
    this.saveZona();
  }

  // Guardo la zona
  saveZona() {
    if (!this.canEdit) {
      return;
    }
    this.zonaEdit = this.zonaEdit;
    this.areasService.saveZona(this.zonaEdit).then(
      response => {
        if (response !== undefined && response !== null) {
          if (this.zonaEdit.id === 0) {
            this.zonas.push(response);
            // this.mapService.addZona(response);
          } else {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.zonas.length; i++) {
              if (this.zonas[i].id === this.zonaEdit.id) {
                this.zonas[i] = { ...response };
                break;
              }
            }
            // this.mapService.updateZona(response);
          }
          this.zonaEdit = { ...response };
          this.zonaSelec = { ...this.zonaEdit };
          if (this.gridZonas !== undefined && this.gridZonas !== null) {
            this.gridZonas.updatebounddata('cells');
          }
          this.toastrService.info('Registro almacenado', 'Información', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        }
      },
      err => {
        this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
      });
    this.formEditZona.close();
  }

  onExpandZonas() {
    if (this.zonaEdit.tipo === 0) {
      if (this.circle !== undefined && this.circle !== null) {
        this.zonaEdit.lat = this.circle.getCenter().lat();
        this.zonaEdit.lng = this.circle.getCenter().lng();
        this.zonaEdit.radio = Math.round(this.circle.getRadius());
      }
    }
  }

  onBorrarZonaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.zonaSelec !== null) {
      this.deleteZona();
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  deleteZona() {
    if (!this.canEdit) {
      return;
    }
    this.confirmDialogService.confirmThis(
      'Seguro que quiere borrar la zona: ' + this.zonaSelec.nombre, () => {
        this.areasService.deleteZona(this.zonaSelec.id).then(
          response => {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.zonas.length; i++) {
              if (this.zonas[i].id === this.zonaSelec.id) {
                this.zonas.splice(i, 1);
                // this.mapService.removeZona(this.zonaSelec);
                break;
              }
            }
            this.zonaSelec = null;
            this.gridZonas.updatebounddata('cells');
            this.toastrService.info('Registro borrado', 'Información', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          },
          err => {
            this.toastrService.error('No se ha podido realizar la operación', 'Atención', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          });
      }, () => {
      });
  }

  onCentrarZonaClick(event: any) {
    if (this.zonaSelec !== null) {
      let center: any;
      if (this.circle !== null) {
        this.circle.setMap(null);
      }
      if (this.polygon !== null) {
        this.polygon.setMap(null);
      }
      if (this.zonaSelec.tipo === 0) {
        this.circle = new google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.2,
          strokeWeight: 0.8,
          fillColor: '#0000FF',
          fillOpacity: 0.08,
          map: GoogleMapComponent.map,
          center: new google.maps.LatLng(this.zonaSelec.lat, this.zonaSelec.lng),
          radius: this.zonaSelec.radio,
          visible: true
        });
        center = new google.maps.LatLng(this.zonaSelec.lat, this.zonaSelec.lng);
      } else {
        this.polygon = new google.maps.Polygon({
          strokeColor: '#FF0000',
          strokeOpacity: 0.2,
          strokeWeight: 0.8,
          fillColor: '#0000FF',
          fillOpacity: 0.2,
          clickable: true,
          geodesic: true,
          map: GoogleMapComponent.map
        });
        this.zonaSelec.puntos.forEach(element => {
          this.polygon.getPath().push(new google.maps.LatLng(element.lat, element.lng));
        });
        center = new google.maps.LatLng(this.zonaSelec.puntos[0].lat, this.zonaSelec.puntos[0].lng);
      }
      this.mapService.setCenter(center);
      this.mapService.setZoom(18);
      this.formZonas.collapse();
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  onCrearZonaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    this.zonaEdit = this.getEmptyZona();
    this.zonaSelec = this.zonaEdit;
    this.onEditarZonaClick(null);
  }

  onEditarZonaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.zonaSelec !== null) {
      this.zonaEdit = { ...this.zonaSelec };
      if (this.circle !== null) {
        this.circle.setMap(null);
      }
      if (this.polygon !== null) {
        this.polygon.setMap(null);
      }
      if (this.zonaEdit.tipo === 0) {
        this.circle = new google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.2,
          strokeWeight: 0.8,
          fillColor: '#0000FF',
          fillOpacity: 0.08,
          map: GoogleMapComponent.map,
          center: new google.maps.LatLng(this.zonaEdit.lat, this.zonaEdit.lng),
          radius: this.zonaEdit.radio,
          editable: true,
          visible: true
        });
        google.maps.event.addListener(this.circle, 'click', (ev => {
          const mev = {
            stop: null,
            latLng: ev.latLng
          };
          google.maps.event.trigger(GoogleMapComponent.map, 'click', mev);
        }));
      }
      if (this.zonaEdit.tipo === 1 && this.zonaEdit.puntos !== null &&
        this.zonaEdit.puntos.length > 0) {
        this.polygon = new google.maps.Polygon({
          strokeColor: '#FF0000',
          strokeOpacity: 0.2,
          strokeWeight: 0.8,
          fillColor: '#0000FF',
          fillOpacity: 0.2,
          clickable: true,
          geodesic: true,
          editable: true,
          map: GoogleMapComponent.map
        });
        this.zonaEdit.puntos.forEach(element => {
          this.polygon.getPath().push(new google.maps.LatLng(element.lat, element.lng));
        });
      }
      this.openEditZonaForm();
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  // Creo por defecto una zona circular de 30 metros de radio
  getEmptyZona(): AreaZonaModel {
    return new AreaZonaModel(0, '', this.areaSelec, 0, 0, 0, 30, '', null,
      new Date(), new Date(), null);
  }

  addZonaPunto(params: any) {
    switch (this.zonaEdit.tipo) {
      case 0: // Círculo
        this.zonaEdit.lat = params.lat;
        this.zonaEdit.lng = params.lng;
        if (this.circle !== null) {
          this.circle.setMap(null);
        }
        this.circle = new google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.2,
          strokeWeight: 0.8,
          fillColor: '#0000FF',
          fillOpacity: 0.08,
          map: GoogleMapComponent.map,
          center: new google.maps.LatLng(this.zonaEdit.lat, this.zonaEdit.lng),
          radius: this.zonaEdit.radio,
          editable: true,
          visible: true
        });
        google.maps.event.addListener(this.circle, 'click', (event => {
          const mev = {
            stop: null,
            latLng: event.latLng
          };
          google.maps.event.trigger(GoogleMapComponent.map, 'click', mev);
        }));
        break;
      case 1: // Polígono
        if (this.zonaEdit.puntos === null || this.zonaEdit.puntos.length < 1) {
          this.zonaEdit.puntos = [];
          if (this.polygon !== null) {
            this.polygon.setMap(null);
          }
          this.polygon = new google.maps.Polygon({
            strokeColor: '#FF0000',
            strokeOpacity: 0.2,
            strokeWeight: 0.8,
            fillColor: '#0000FF',
            fillOpacity: 0.2,
            clickable: true,
            geodesic: true,
            editable: true,
            map: GoogleMapComponent.map
          });
        }
        this.zonaEdit.puntos.push(
          new AreaZonaPuntosModel(0, this.zonaEdit.id, this.zonaEdit.puntos.length + 1,
            params.lat, params.lng)
        );
        this.polygon.getPath().push(new google.maps.LatLng(params.lat, params.lng));
        break;
    }
  }

  onOpenEditZona() {
    setTimeout(() => {
      // Relleno el combo de tipos de zonas
      this.cbTiposZonas.clear();
      this.cbTiposZonas.addItem({ label: 'Círculo', value: 0 });
      this.cbTiposZonas.addItem({ label: 'Polígono', value: 1 });
      this.cbTiposZonas.selectIndex(this.zonaEdit.tipo);
      if (this.zonaEdit.id > 0) {
        this.cbTiposZonas.disabled(true);
      }
    }, 100);
  }

  onCloseEditZona() {
    if (this.subscriptionClickMap != null) {
      this.subscriptionClickMap.unsubscribe();
      this.subscriptionClickMap = null;
    }
    if (this.subscriptionEscPress != null) {
      this.subscriptionEscPress.unsubscribe();
      this.subscriptionEscPress = null;
    }
    if (this.circle !== null) {
      this.circle.setMap(null);
      this.circle = null;
    }
    if (this.polygon !== null) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
    this.mostrarEditZona = false;
    this.formZonas.enable();
    this.formZonas.expand();
  }

  // Muestra el formulario de edición de zonas
  openEditZonaForm() {
    this.mostrarEditZona = true;
    if (this.formZonas !== undefined) {
      this.formZonas.collapse();
      this.formZonas.disable();
    }
    this.subscribeEscKeyPressed();
  }

  onChangeTipoZona(event: any) {
    this.zonaEdit.tipo = event.args.item.value;
    if (this.zonaEdit.tipo === 1 && this.circle !== null) {
      this.circle.setMap(null);
      this.circle = null;
    }
    if (this.zonaEdit.tipo === 0 && this.polygon !== null) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
  }

  onDibujarZonaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.zonaEdit.tipo === 0 && this.zonaEdit.radio < 1) {
      this.toastrService.error('Introduzca un radio', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.zonaEdit.tipo === 0) {
      this.toastrService.info('Seleccione un punto sobre la cartografía', 'Por favor', {
        timeOut: 3000,
        positionClass: 'toast-top-center'
      });
    } else {
      this.toastrService.info('Seleccione los puntos del polígono sobre la cartografía', 'Por favor', {
        timeOut: 3000,
        positionClass: 'toast-top-center'
      });
    }
    this.subscribeMapClick();
    this.formEditZona.collapse();
    this.mapService.setCenter(new google.maps.LatLng(this.areaSelec.lat, this.areaSelec.lng));
    this.mapService.setZoom(18);
    if (this.zonaEdit.tipo === 0) {
      this.circle.setRadius(this.zonaEdit.radio);
    }
  }

  // Cada vez que se pulsa la tecla ESC se borra el último punto del polígono
  subscribeEscKeyPressed() {
    this.subscriptionEscPress = this.mapService.escapeKeyPressEmitter.subscribe(() => {
      if (this.zonaEdit !== null && this.zonaEdit.puntos !== null &&
        this.zonaEdit.puntos.length > 0) {
        this.zonaEdit.puntos.pop();
        this.polygon.getPath().pop();
      }
    });
  }

  onSelectTiposAreaImport(event: any) {
    this.areaTipoImport = AreasService.areasTipos.get(event.args.item.value);
  }

  async onImportarClick(files: FileList) {
    if (this.areaTipoImport === null) {
      this.toastrService.error('Seleccione el tipo de área que va a importar', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    } else {
      if (files && files.length > 0) {
        const file: File = files.item(0);
        try {
          // El formato de las filas debe ser: Nombre;Latitug;Longitud
          if (file.name.toUpperCase().indexOf('.CSV') > -1) {
            const reader: FileReader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
              // Leo el archivo y lo descompongo en líneas
              const csv: string = reader.result as string;
              let lineas: string[] = csv.split('\n');
              let areas: AreaModel[] = [];
              // Recorro las líneas y recupero las áreas
              let i = 1;
              let problema = false;
              lineas.forEach(linea => {
                if (!problema) {
                  const reg: string[] = linea.split(';');
                  if (reg.length > 2 && reg[0].length > 0) {
                    areas.push(new AreaModel(0, reg[0], this.areaTipoImport, Number(reg[1]),
                      Number(reg[2]), 30, '', new Date(), new Date(), null));
                  } else {
                    if (reg.length > 0 && reg[0].length > 0) {
                      this.toastrService.error('En la línea ' + i + ' del archivo hay un problema, es necesario que lo resuelva ' +
                        'para que pueda realizar la importación. Recuerde que el formato de las filas debe ser: ' +
                        'Nombre; Latitud; Longitud', 'Atención', {
                        timeOut: 15000,
                        positionClass: 'toast-top-center'
                      });
                      problema = true;
                    }
                  }
                  i++;
                }
              });
              if (!problema) {
                areas.forEach(async area => {
                  const response = await this.areasService.saveArea(area);
                  // Añado el área al mapa
                  this.mapService.addArea(response);
                });
                this.getAreas();
              }
              this.fileUpload.nativeElement.value = '';
            }
          } else {
            this.toastrService.error('Sólo se permiten archivos con extensión CSV', 'Atención', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          }
        } finally {
          this.loader.close();
        }
      }
    }
  }
}


