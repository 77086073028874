import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { UsuarioModel } from '../login/modelos/usuario-model';
import { Idioma } from '../utils/idioma';
import { LoginService } from '../login/login.service';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from '../utils/confirm-dialog/confirm-dialog.service';
import { environment } from '../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { MapsService } from '../maps/maps.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('cbTipos') cbTipos: jqxDropDownListComponent;
  @ViewChild('ebNombre', { static: false }) ebNombre;
  @ViewChild('cbActivo', { static: false }) cbActivo;

  private subscriptionEditUsuario: any = null;

  // Variables para edición de usuarios
  private usuarios: UsuarioModel[] = [];
  public usuarioSelec: UsuarioModel = null;
  public usuarioEdit: UsuarioModel = null;
  public password: string;
  public mostrarMainForm = false;
  public mostrarEditForm = false;
  public imagenSelec: any = null;
  public timerImagen: any;
  public changePassw = false;

  // Preparo las columnas del grid de usuarios
  public columnsUsu: any[] = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public localization = Idioma.getLocalization('es');

  // Variables para el grid de usuarios
  public sourceUsu: any = [];
  public dataAdapterUsu = new jqx.dataAdapter(this.sourceUsu);

  constructor(
    private loginService: LoginService,
    private toastrService: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    private mapService: MapsService
  ) { }

  ngOnInit(): void {
    this.subscribeEditUsuario();
  }

  ngAfterViewInit(): void {
    // Creo un usuario vacio para rellenar
    this.usuarioEdit = this.getEmptyUser();
    setTimeout(() => {
      // Recupero los usuarios
      this.getUsuarios();
    }, 0);
  }

  open() {
    this.mostrarMainForm = true;
  }

  // Permite editar un usuario
  subscribeEditUsuario(): void {
    if (this.subscriptionEditUsuario != null) {
      this.subscriptionEditUsuario.unsubscribe();
    }
    this.subscriptionEditUsuario = this.mapService.editUsuarioEmiter.subscribe(usuario => {
      this.usuarioSelec = { ...LoginService.usuarios.get(usuario.id) };
      this.usuarioEdit = { ...LoginService.usuarios.get(usuario.id) };
      this.onEditarUsuarioClick(null);
    });
  }

  getUsuarios() {
    this.columnsUsu = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60 },
      { text: 'Nombre', columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 280 },
      { text: 'E-mail', columntype: 'textbox', filtertype: 'textbox', datafield: 'email', width: 150 },
      { text: 'Tipo', columntype: 'textbox', filtertype: 'textbox', datafield: 'rol', width: 150 },
      { text: 'Activo', columntype: 'checkbox', filtertype: 'textbox', datafield: 'activo', width: 50 }
    ];
    this.usuarios = [];
    this.usuarioSelec = null;
    this.loader.open();
    this.loginService.getRols().then(
      rols => {
        this.loginService.getUsuarios().then(
          response => {
            this.usuarios = response;
            if (this.usuarios !== undefined) {
              if (this.grid !== undefined) {
                this.grid.beginupdate();
              }
              this.sourceUsu = {
                datatype: 'json',
                datafields: [
                  { name: 'id', type: 'number' },
                  { name: 'nombre', type: 'string' },
                  { name: 'email', type: 'string' },
                  { name: 'rol', map: 'rol>nombre', type: 'string' },
                  { name: 'activo', type: 'boolean' }
                ],
                localdata: this.usuarios,
                sortcolumn: 'nombre',
                sortdirection: 'asc'
              };
              this.dataAdapterUsu = new jqx.dataAdapter(this.sourceUsu);
              if (this.grid !== undefined) {
                this.grid.endupdate();
              }
            }
            this.loader.close();
          },
          err => {
            this.loader.close();
            console.log(err);
          });
      },
      err => {
        this.loader.close();
        console.log(err);
      });
  }

  getEmptyUser(): UsuarioModel {
    // Creo un usuario sin datos, por defecto del tipo que tengamos seleccionado en el grid
    return new UsuarioModel(0, '', '', '', null, true, null, '', null, null, null);
  }

  onRowUsuarioSelect(event: any) {
    this.usuarioSelec = this.usuarios[event.args.rowindex];
  }

  // Crear un nuevo usuario
  onCrearUsuarioClick(event: any) {
    // Creo un usuario vacio para rellenar
    this.usuarioEdit = this.getEmptyUser();
    this.mostrarEditForm = true;
    this.form.collapse();
    this.form.disable();
  }

  // Editar el usuario seleccionado
  onEditarUsuarioClick(event: any) {
    if (this.usuarioSelec !== null) {
      if (this.usuarioSelec.id === 1 && this.loginService.getUser().id !== 1) {
        this.toastrService.error('Usted no tiene permisos para editar este usuario', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
      } else {
        this.usuarioEdit = { ...this.usuarioSelec };
        if (this.form !== undefined) {
          this.form.collapse();
          this.form.disable();
        }
        this.mostrarEditForm = true;
      }
    }
  }

  // Borrar el usuario seleccionado
  onBorrarUsuarioClick(event: any) {
    if (this.usuarioSelec !== null) {
      if (this.usuarioSelec.id === 1) {
        this.toastrService.error('Este usuario no se puede borrar por motivos de seguridad', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
      } else {
        this.deleteUsuario();
      }
    } else {
      this.toastrService.error('Seleccione un registro en la tabla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
    }
  }

  // Cargo el archivo de imagen seleccionado
  readUsuarioImage(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.name.indexOf('.png') < 0 && file.name.indexOf('.jpg') < 0) {
        this.toastrService.error('El arhivo seleccionado no es un arcchivo de imagen válido, sólo PNG o JPG', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
      }
      const reader = new FileReader();
      reader.onload = e => this.usuarioEdit.imagen = reader.result;
      reader.readAsDataURL(file);
    }
  }

  onGuardarClick(event: any) {
    if (this.usuarioEdit.nombre === null || this.usuarioEdit.nombre.length < 1) {
      this.toastrService.error('Introduzca un nombre', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.usuarioEdit.email.indexOf('@') < 1 || this.usuarioEdit.email.indexOf('.') < 1) {
      this.toastrService.error('Introduzca un e-mail válido', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.usuarioEdit.imagen !== null && this.usuarioEdit.imagen.startsWith('data:image/png;base64,')) {
      this.usuarioEdit.imagen = this.usuarioEdit.imagen.replace('data:image/png;base64,', '');
    } else {
      if (this.usuarioEdit.imagen !== null && this.usuarioEdit.imagen.startsWith('data:image/jpg;base64,')) {
        this.usuarioEdit.imagen = this.usuarioEdit.imagen.replace('data:image/jpg;base64,', '');
      } else {
        if (this.usuarioEdit.imagen !== null && this.usuarioEdit.imagen.startsWith('data:image/jpeg;base64,')) {
          this.usuarioEdit.imagen = this.usuarioEdit.imagen.replace('data:image/jpeg;base64,', '');
        } else {
          this.usuarioEdit.imagen = null;
        }
      }
    }
    if (this.usuarioEdit.password === null || this.usuarioEdit.password.length < 4) {
      this.toastrService.error('Introduzca una contraseña de al menos cuatro caracteres', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.password !== this.usuarioEdit.password) {
      this.toastrService.error('La contraseña no coincide, vuelva a teclearla', 'Atención', {
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.saveUsuario();
  }

  // Guardo el usuario
  saveUsuario() {
    // Codifico la contraseña antes de almacenar
    this.loginService.saveUsuario(this.usuarioEdit).then(
      response => {
        if (response !== undefined && response !== null) {
          if (this.usuarioEdit.id === 0) {
            this.usuarios.push(response);
          } else {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.usuarios.length; i++) {
              if (this.usuarios[i].id === this.usuarioEdit.id) {
                this.usuarios[i] = { ...response };
                break;
              }
            }
          }
          // Añado el usuario a la cache
          LoginService.usuarios.set(response.id, response);
          // Si se trata del usuario que ha iniciado sesión actualizo la información almacenada
          // en el local storage
          if (response.id === this.loginService.getUser().id) {
            const usu = { ...response };
            usu.password = ''; // Borro la contraseña para que no se guarde en el local storage
            this.loginService.setUser(usu);
          }
          this.usuarioEdit = { ...response };
          this.usuarioSelec = { ...this.usuarioEdit };
          if (this.grid !== undefined && this.grid !== null) {
            this.grid.updatebounddata('cells');
          }
          this.toastrService.info('Registro almacenado', 'Información', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
        }
      },
      err => {
        this.toastrService.error('No se ha podido almacenar la información', 'Atención', {
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
      });
    this.formEdit.close();
  }

  deleteUsuario() {
    this.confirmDialogService.confirmThis(
      'Seguro que quiere borrar el usuario: ' + this.usuarioSelec.nombre, () => {
        this.loginService.deleteUsuario(this.usuarioSelec.id).then(
          response => {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.usuarios.length; i++) {
              if (this.usuarios[i].id === this.usuarioSelec.id) {
                this.usuarios.splice(i, 1);
                break;
              }
            }
            this.usuarioSelec = null;
            this.grid.updatebounddata('cells');
            this.toastrService.info('Registro borrado', 'Información', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          },
          err => {
            this.toastrService.error('No se ha podido realizar la operación', 'Atención', {
              timeOut: 2000,
              positionClass: 'toast-top-center'
            });
          });
      }, () => {
      });
  }

  onOpenEditUsuario() {
    setTimeout(() => {
      // Relleno el combo de tipos de usuario
      this.cbTipos.clear();
      let i = 0;
      LoginService.rols.forEach(rol => {
        this.cbTipos.addItem({ label: rol.nombre, value: rol.id });
        if (this.usuarioEdit.rol !== null && this.usuarioEdit.rol.id === rol.id) {
          this.cbTipos.selectIndex(i);
        }
        i++;
      });
      if (this.usuarioEdit.id > 0) {
        this.password = this.usuarioEdit.password;
      } else {
        this.password = '';
        this.cbTipos.selectedIndex(0);
      }
      this.ebNombre.nativeElement.focus();
      if (this.usuarioEdit.imagen !== null && this.usuarioEdit.imagen.length > 50) {
        this.usuarioEdit.imagen = 'data:image/jpg;base64,' + this.usuarioEdit.imagen;
      }
      if (this.usuarioEdit.id === 1) {
        this.usuarioEdit.activo = true;
        this.cbTipos.disabled(true);
        this.cbActivo.nativeElement.disabled = true;
      } else {
        if (this.loginService.getUser().rol.id !== 1) {
          this.cbTipos.disabled(true);
          this.cbActivo.nativeElement.disabled = true;
        }
      }
    }, 0);
  }

  onChangePassw(event: any) {
    this.changePassw = true;
  }

  onKillFocusPassw(event: any) {
    this.usuarioEdit.password = this.encodePassword(this.usuarioEdit.password);
    this.changePassw = false;
  }

  onKillFocusPassw2(event: any) {
    this.password = this.encodePassword(this.password);
    this.changePassw = false;
  }

  encodePassword(password: string): string {
    const md5 = new Md5();
    return md5.appendStr(password + environment.md5Key).end().toString();
  }

  onChangeTipoUsuario(event: any) {
    this.usuarioEdit.rol = LoginService.rols.get(event.args.item.value);
  }

  onCloseEdit() {
    this.mostrarEditForm = false;
    if (this.form !== undefined) {
      this.form.enable();
      this.form.expand();
    }
  }

  onClickImagen() {
    this.imagenSelec = this.usuarioEdit.imagen;
    if (this.imagenSelec !== null && this.imagenSelec.length > 50) {
      if (this.imagenSelec.startsWith('data:image/png;base64,')) {
        this.imagenSelec = this.imagenSelec.replace('data:image/png;base64,', '');
      } else {
        if (this.imagenSelec.startsWith('data:image/jpg;base64,')) {
          this.imagenSelec = this.imagenSelec.replace('data:image/jpg;base64,', '');
        } else {
          if (this.imagenSelec.startsWith('data:image/jpeg;base64,')) {
            this.imagenSelec = this.imagenSelec.replace('data:image/jpeg;base64,', '');
          } else {
            this.imagenSelec = null;
          }
        }
      }
      if (this.timerImagen !== null) {
        clearTimeout(this.timerImagen);
      }
      this.timerImagen = setTimeout(() => {
        this.imagenSelec = null;
      }, 2000);
    }
  }

}
