<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="'Procesando...'">
</jqxLoader>
<jqxWindow #reproductor [width]="245" [showCloseButton]="true" [height]="180" [resizable]=" false " [position]="getReproPos() " (onClose)="onClose() ">
    <div style="overflow-x: hidden; ">
        Reproductor de ruta
    </div>
    <div style=" float:left; overflow-x: hidden; background-color:lightgrey; ">
        <div>
            <div style="float: left; width: 205px; ">
                <div style="overflow: hidden; ">
                    {{movil.nombre.substring(0,25)}}
                </div>
                <jqxDateTimeInput #from [width]="200" [height]="30" [dropDownVerticalAlignment]="'top'" [formatString]="'dd-MM-yyyy HH:mm:ss'" [showTimeButton]="true" [value]="fechaIni">
                </jqxDateTimeInput>
                <jqxDateTimeInput #to style="margin-top: 2px;" [width]="200" [height]="30" [dropDownVerticalAlignment]="'top'" [formatString]="'dd-MM-yyyy HH:mm:ss'" [showTimeButton]="true " [value]="fechaFin ">
                </jqxDateTimeInput>
                <div>
                    <input style="margin-top: 7px;" type=image src="assets/images/reproductor/play.png" width="35" height="30" title="Reproducir paso a paso" (click)="onPlay(true)">
                    <input style="margin-left: 3px;" type=image src="assets/images/reproductor/pause.png" width="35" height="30" title="Pausar la reproducción" (click)="onPause()">
                    <input style="margin-left: 3px;" type=image src="assets/images/reproductor/stop.png" width="35" height="30" title="Finalizar la reproducción" (click)="onStop()">
                    <input style="margin-left: 3px;" type=image src="assets/images/reproductor/play2.png" width="35" height="30" title="Reproducir completo" (click)="onPlay(false)">
                    <input style="margin-left: 14px;" type=image src="assets/images/reproductor/kml.png" width="35" height="30" title="Exportar a un archivo KML" (click)="onKml()">
                </div>
                <div #infoRuta style="float: left; ">
                    <sub></sub>
                </div>
            </div>
        </div>
        <div style="float: left; width: 30px; ">
            <jqxSlider #speed (onChange)='onChangeSpeed($event)' [orientation]=" 'vertical' " [width]='30' [height]='118' [min]='0' [max]='100' [value]='50' [step]='5' [mode]=" 'fixed' " [showTickLabels]='false' [tooltip]='false' [ticksFrequency]='5'>
            </jqxSlider>
        </div>
    </div>
</jqxWindow>