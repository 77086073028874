<div *ngIf="message" class="modal" tabindex="-1" zIndex="9999">
    <div class="modalDialog" zIndex="9999">
        <div class="modal-content">
            <div *ngIf="message?.type == 'confirm'">
                <div style="padding: 10px; font-weight: bold;">Atención</div>
                <div style="padding: 10px;">{{message.text}}</div>
                <div>
                    <p class="confirm-button">
                        <a (click)="message.siFn()">
                            <button style="margin-right: 8px; width: 80px; height: 30px;"><b>Si</b></button>
                        </a>
                        <a (click)="message.noFn()">
                            <button style="margin-right: 16px; width: 80px; height: 30px;"><b>No</b></button>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>