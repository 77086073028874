import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisorComponent } from './visor.component';
import { MapsModule } from '../maps/maps.module';

@NgModule({
  declarations: [VisorComponent],
  imports: [
    CommonModule,
    MapsModule
  ]
})
export class VisorModule { }
