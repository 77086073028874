import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { PeriodoSelectComponent, PeriodoSelectTipo } from '../../utils/periodo-select/periodo-select.component';
import { MovilModel } from '../../recursos/modelos/movil-model';
import { Idioma } from '../../utils/idioma';
import { Config } from '../../utils/config';
import { RecursosService } from '../../recursos/recursos.service';
import { LoginService } from '../../login/login.service';
import { Utils } from '../../utils/utils';
import { GrupoModel } from '../../recursos/modelos/grupo-model';
import { InformesService } from '../informes-service';
import { InformeMovilidadModel } from '../modelos/informe-movilidad-model';

@Component({
  selector: 'app-informe-movilidad',
  templateUrl: './informe-movilidad.component.html',
  styleUrls: ['./informe-movilidad.component.css']
})
export class InformeMovilidadComponent implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('cbMoviles') cbMoviles: jqxDropDownListComponent;
  @ViewChild('cbGrupos') cbGrupos: jqxDropDownListComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;

  // Variables para filtrado
  public fechaIni: Date;
  public fechaFin: Date;

  // Variables para generar el listado
  private informe: InformeMovilidadModel[] = [];
  private moviles: MovilModel[] = [];
  private movilesInforme: MovilModel[] = [];
  private grupos: GrupoModel[] = [];
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);

  // Preparo las columnas del grid
  public columns: any[];
  public columngroups = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public localization = Idioma.getLocalization('es');

  constructor(
    private loginService: LoginService,
    private recursosService: RecursosService,
    private informesService: InformesService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.periodoSelect.setPeriodo(PeriodoSelectTipo.DIA);
      this.fechaIni = this.periodoSelect.getFechaIni();
      this.fechaFin = this.periodoSelect.getFechaFin();
    }, 500);
    this.getGroups();
    this.getMoviles();
    this.preparaListado();
  }

  // Rellena la lista de grupos
  getGroups() {
    this.recursosService.getGrupos().then(
      response => {
        this.grupos = response;
        this.cbGrupos.clear();
        this.cbGrupos.addItem({ label: 'Todos', value: -1 });
        this.cbGrupos.selectIndex(0);
        if (this.grupos !== undefined && this.grupos != null) {
          const filter = Config.getGruposFilter([{ id: -1 }]);
          let all = false;
          if (filter.find(s => s.id === -1) !== undefined) {
            all = true;
          }
          this.grupos.forEach(grupo => {
            if (all || filter.find(s => s.id === grupo.id) !== undefined) {
              this.cbGrupos.addItem({
                label: grupo.nombre,
                value: grupo.id
              });
            }
          });
        }
      },
      err => {
        console.log(err);
      });
  }

  getMoviles() {
    // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
    const filter = Config.getMovilesFilter([{ id: -1 }]);
    let all = false;
    if (filter.find(s => s.id === -1)) {
      all = true;
    }
    const item = {
      id: 0,
      html: '<div style="height: 20px; float: left;">' +
        '<span style="float: left; font-size: 13px;">' + 'Todos' + '</span></div>'
    };
    this.cbMoviles.addItem(item);
    this.cbMoviles.selectIndex(0);
    this.recursosService.getMoviles(this.loginService.getEmpresa()).then(
      response => {
        // this.moviles = response;
        this.movilesInforme = [];
        if (response !== undefined && response != null) {
          response.forEach(movil => {
            if ((all || filter.find(s => s.id === movil.id)) && movil.gls !== 'AN') {
              this.moviles.push(movil);
              this.rellenaMovil(movil);
            }
          });
        }
      },
      err => {
        console.log(err);
      });
  }

  onChangeGrupos(event: any) {
    this.cbMoviles.clear();
    const item = {
      id: 0,
      html: '<div style="height: 20px; float: left;">' +
        '<span style="float: left; font-size: 13px;">' + 'Todos' + '</span></div>'
    };
    this.cbMoviles.addItem(item);
    this.cbMoviles.selectIndex(0);
    this.movilesInforme = [];
    if (event.args.item.value > -1) {
      this.recursosService.getGruposMoviles(event.args.item.value).then(
        movilesGrupo => {
          if (movilesGrupo !== undefined) {
            movilesGrupo.forEach(idMovil => {
              this.rellenaMovil(RecursosService.moviles.get(idMovil));
            });
          }
        },
        err => {
          console.log(err);
        });
    } else {
      this.moviles.forEach(movil => {
        this.rellenaMovil(movil);
      });
    }
  }

  rellenaMovil(movil: MovilModel) {
    this.movilesInforme.push(movil);
    const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
      'src="data:image/jpg;base64,' + movil.icono + '"/>';
    const item = {
      id: movil.id,
      html: '<div style="height: 20px; float: left;">' + icono +
        '<span style="float: left; font-size: 13px;">' + movil.nombre + '</span></div>'
    };
    this.cbMoviles.addItem(item);
  }

  preparaListado() {
    this.columngroups = [
      { text: 'Tiempo de ignición', align: 'center', name: 'ignicionGroup' },
      { text: 'Sirena', align: 'center', name: 'sirenaGroup' },
      { text: 'Puente de luces', align: 'center', name: 'pLucesGroup' },
    ];
    this.columns = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 40, },
      { text: 'Recurso', columntype: 'textbox', filtertype: 'textbox', datafield: 'movil', width: 180 },
      { text: 'Inicio', columntype: 'date', filtertype: 'date', datafield: 'fechaIni', cellsformat: 'dd/MM/yy HH:mm:ss', width: 140 },
      { text: 'Fin', columntype: 'date', filtertype: 'date', datafield: 'fechaFin', cellsformat: 'dd/MM/yy HH:mm:ss', width: 140 },
      {
        text: 'Total', columntype: 'textbox', filtertype: 'textbox', datafield: 'tiempoTotal',
        align: 'center', width: 100, cellsalign: 'center'
      },
      {
        text: 'Activa', columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'ignicionGroup', datafield: 'tiempoIgniOn', width: 100, cellsalign: 'center'
      },
      {
        text: 'Inactiva', columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'ignicionGroup', datafield: 'tiempoIgniOff', width: 100, cellsalign: 'center'
      },
      {
        text: 'Ralentí', columntype: 'textbox', filtertype: 'textbox', datafield: 'tiempoRalenti',
        align: 'center', width: 100, cellsalign: 'center'
      },
      {
        text: 'Distancia (km)', columntype: 'textbox', filtertype: 'textbox', datafield: 'distancia',
        align: 'center', width: 110, cellsalign: 'center', cellsformat: 'f3'
      },
      {
        text: 'V.Maxima', columntype: 'textbox', filtertype: 'textbox', datafield: 'velMaxima',
        width: 70, align: 'center', cellsalign: 'center'
      },
      {
        text: 'V.Media', columntype: 'textbox', filtertype: 'textbox', datafield: 'velMedia',
        width: 70, align: 'center', cellsalign: 'center'
      },
      {
        text: 'Activaciones', columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'sirenaGroup', datafield: 'sirenaActivaciones', width: 100, cellsalign: 'center'
      },
      {
        text: 'Distancia (km)', columntype: 'textbox', filtertype: 'textbox', align: 'center', cellsformat: 'f3',
        columngroup: 'sirenaGroup', datafield: 'sirenaDistancia', width: 110, cellsalign: 'center'
      },
      {
        text: 'Duración', columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'sirenaGroup', datafield: 'sirenaDuracion', width: 100, cellsalign: 'center'
      },
      {
        text: 'Activaciones', columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'pLucesGroup', datafield: 'pLucesActivaciones', width: 100, cellsalign: 'center'
      },
      {
        text: 'Distancia (km)', columntype: 'textbox', filtertype: 'textbox', align: 'center', cellsformat: 'f3',
        columngroup: 'pLucesGroup', datafield: 'pLucesDistancia', width: 110, cellsalign: 'center'
      },
      {
        text: 'Duración', columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'pLucesGroup', datafield: 'pLucesDuracion', width: 100, cellsalign: 'center'
      }
    ];
  }

  onAceptar(event: any) {
    this.fechaIni = this.periodoSelect.getFechaIni();
    this.fechaFin = this.periodoSelect.getFechaFin();
    const moviles: number[] = [];
    if (this.cbMoviles.getSelectedItem().value.id < 1) {
      this.movilesInforme.forEach(movil => {
        moviles.push(movil.id);
      });
    } else {
      moviles.push(this.cbMoviles.getSelectedItem().value.id);
    }
    let periodo = 0;
    switch (this.periodoSelect.getPeriodo()) {
      case 0:
        periodo = 1; // Dia
        break;
      case 1:
        periodo = 2; // Semana
        break;
      case 2:
        periodo = 3; // Mes
        break;
      case 5:
        periodo = 4; // Año
        break;
    }
    if (moviles.length > 0) {
      this.loader.open();
      this.informesService.getInformeMovilidad(periodo, moviles, this.fechaIni, this.fechaFin).then(
        response => {
          this.informe = response;
          this.generaInforme();
        },
        err => {
          this.loader.close();
          console.log(err);
        });
    } else {
      this.informe = [];
      this.generaInforme();
    }
  }

  // Genera el informe con los datos obtenidos
  generaInforme() {
    if (this.informe !== undefined && this.informe !== null) {
      this.preparaListado();
      this.source = {
        datatype: 'json',
        datafields: [
          { name: 'id', map: 'movil>id', type: 'int' },
          { name: 'movil', map: 'movil>nombre', type: 'string' },
          { name: 'fechaIni', type: 'date' },
          { name: 'fechaFin', type: 'date' },
          { name: 'tiempoTotal', type: 'string' },
          { name: 'tiempoIgniOn', type: 'string' },
          { name: 'tiempoIgniOff', type: 'string' },
          { name: 'tiempoRalenti', type: 'string' },
          { name: 'distancia', type: 'float' },
          { name: 'velMaxima', type: 'int' },
          { name: 'velMedia', type: 'int' },
          { name: 'sirenaActivaciones', map: 'sirena>activaciones', type: 'int' },
          { name: 'sirenaDistancia', map: 'sirena>distancia', type: 'float' },
          { name: 'sirenaDuracion', map: 'sirena>duracion', type: 'string' },
          { name: 'pLucesActivaciones', map: 'pLuces>activaciones', type: 'int' },
          { name: 'pLucesDistancia', map: 'pLuces>distancia', type: 'float' },
          { name: 'pLucesDuracion', map: 'pLuces>duracion', type: 'string' }
        ],
        localdata: this.informe
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);
    }
    this.loader.close();
  }

  onExportar(event: any) {
    this.grid.exportdata('xls', Utils.formatDateAMDhms(new Date()) + '_Informe_Movilidad');
  }

}
