import {
  Component, OnInit, ViewChild, NgZone, AfterViewInit
} from '@angular/core';
import { AgmMap } from '@agm/core';
import { LoginService } from '../../login/login.service';
import { Config } from '../../utils/config';
import { UsuarioModel } from '../../login/modelos/usuario-model';
import { MovilModel } from '../../recursos/modelos/movil-model';
import { environment } from '../../../environments/environment';
import { MapsService } from '../maps.service';

declare var google: any;

@Component({
  selector: 'app-google-map-visor',
  templateUrl: './google-map-visor.component.html',
  styleUrls: ['./google-map-visor.component.css']
})
export class GoogleMapVisorComponent implements OnInit, AfterViewInit {
  @ViewChild('AgmMap') AgmMap: AgmMap;
  @ViewChild('search', { static: false }) search;

  public static map: any; // El mapa
  protected geoCoder: any; // Para busquedas sobre el mapa

  // Usuario actual
  private usuActual: UsuarioModel;


  // Configuración por defecto del mapa
  private mapType = 'roadmap';
  private zoom = environment.zoom;
  private center = {
    lat: environment.lat,
    lng: environment.lng
  };

  constructor(
    private ngZone: NgZone,
    private loginService: LoginService,
    private mapsService: MapsService
  ) {
  }

  ngOnInit(): void {
    this.usuActual = this.loginService.getUser();
    // Obtengo el último encuadre, para ello recupero el último centro y el zoom
    this.center = Config.getCenterMap(this.center);
    this.zoom = Config.getZoomMap(this.zoom);
  }

  ngAfterViewInit(): void {
    this.AgmMap.mapReady.subscribe(map => {
      // Obtengo el manejador del mapa
      GoogleMapVisorComponent.map = map;
      // Quito los puntos de interes del mapa
      GoogleMapVisorComponent.map.setOptions({
        zoomControlOptions: {
          style: google.maps.ControlPosition.small,
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        styles: [ // Oculto los puntos de interes
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      });
      // Preparo la búsqueda de direcciones en el mapa
      this.geoCoder = new google.maps.Geocoder();
      // Restrinjo el área de busqueda preferido
      const sw = new google.maps.LatLng(environment.latSW, environment.lngSW);
      const ne = new google.maps.LatLng(environment.latNE, environment.lngNE);
      const searchBounds = new google.maps.LatLngBounds(sw, ne);
      const options = {
        bounds: searchBounds,
        types: ['geocode'],
        componentRestrictions: { country: 'es' }
      };
      const autocomplete = new google.maps.places.Autocomplete(
        this.search.nativeElement, options);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          if (place.geometry !== undefined && place.geometry !== null) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            this.setCenter(lat, lng);
            this.search.nativeElement.value = '';
          }
        });
      });
      // Eventos a los que se subscribe el mapa
      this.subscribeSetCenter();
    });
  }

  // Permite centrar el mapa en una posición
  subscribeSetCenter(): void {
    this.mapsService.setCenterEmiter.subscribe(pos => {
      this.setCenter(pos.lat(), pos.lng());
    });
  }

  // Establece el centro del mapa
  setCenter(lat: number, lng: number) {
    this.center.lat = lat;
    this.center.lng = lng;
    if (GoogleMapVisorComponent.map !== undefined && GoogleMapVisorComponent.map !== null) {
      GoogleMapVisorComponent.map.panTo(new google.maps.LatLng(lat, lng));
      GoogleMapVisorComponent.map.setZoom(14);
    }
  }

  getCenter() {
    return this.center;
  }

  // Establece el nivel de zoom
  setZoom(zoom: number) {
    this.zoom = zoom;
  }

  getZoom() {
    return this.zoom;
  }

  setMapType(type: string) {
    this.mapType = type;
  }

  getType() {
    return this.mapType;
  }

}


