import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public static hayToken = false;
  private timer = null;

  constructor(private http: HttpClient) {
    // Si pide un token al mismo empezar y mientras no se reciba
    // se pide uno cada 3 segundos
    this.getApiToken();
    this.setTimer(3);
  }

  setTimer(interval: number) {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.getApiToken();
    }, interval * 1000);
  }

  getApiToken(): Promise<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    };
    const body = new URLSearchParams();
    body.set('grant_type', environment.grant_type);
    body.set('client_id', environment.client_id);
    body.set('client_secret', environment.client_secret);
    body.set('username', environment.username);
    body.set('password', environment.password);
    const s = environment.urlApi;
    return this.http.post<string>(environment.urlApi + '/token', body.toString(), httpOptions)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        // Guardo el nuevo token y anpunto que ya se puede empezar la comunicación con la API
        ApiService.hayToken = true;
        sessionStorage.setItem('tokenGSC', data.access_token);
        // Activo un temporizador para pedir un nuevo token 2 minutos antes que caduque
        if (data.expires_in > 120) {
          this.setTimer(data.expires_in - 120);
        } else {
          this.setTimer(60);
        }
        return data.access_token;
      }, error => {
        // console.log('recursos::getAreasTipos()' + error);
      });
  }
}
