import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Utils } from '../utils/utils';
import { environment } from 'src/environments/environment';
import { SensorModel } from './modelos/sensor-model';
import { EstadoMovilModel } from '../recursos/modelos/estado-movil-model';
import { PosicionesService } from '../posiciones/posiciones.service';

@Injectable({
  providedIn: 'root'
})
export class SensoresService {
  public static estadoMovil = new Map<number, EstadoMovilModel>();

  constructor(private http: HttpClient) {
  }

  getSensoresMovil(movil: number, desde: Date, hasta: Date): Promise<SensorModel[]> {
    const result: any = [];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    };
    const body = new URLSearchParams();
    body.set('movil', '' + movil);
    body.set('desde', Utils.formatDateTime(desde, false));
    body.set('hasta', Utils.formatDateTime(hasta, false));
    return this.http.post(environment.urlApi + '/api/sensores', body.toString(), httpOptions)
      .toPromise()
      .then(response => {
        let data = JSON.parse(JSON.stringify(response, ['Movil', 'Sensor', 'Fecha',
          'Descripcion', 'Lectura', 'Icono', 'Lat', 'Lng', 'X', 'Y']));
        // Ordeno el array por fecha ascendente ya que viene desordenado
        data = data.sort((a, b) => {
          if (a.Fecha < b.Fecha) {
            return -1;
          }
          if (a.Fecha > b.Fecha) {
            return 1;
          }
          return 0;
        });
        data.forEach(sensor => {
          result.push(new SensorModel(sensor.Movil, sensor.Sensor, sensor.Descripcion,
            new Date(sensor.Fecha), sensor.Lectura, sensor.Icono, sensor.Lat, sensor.Lng));
        });
        return result;
      }, error => {
        if (error.status !== 404) {
          console.log('getSensoresMovil()' + error);
        } else {
          return result;
        }
      });
  }

  getUltimosSensoresMovil(idMovil: number): Promise<SensorModel[]> {
    const result: any = [];
    return this.http.get(environment.urlApi + '/api/sensores/' + idMovil)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        let estado = SensoresService.estadoMovil.get(idMovil);
        if (estado === undefined) {
          estado = new EstadoMovilModel(idMovil, false, false, false, false, PosicionesService.lastPos.get(idMovil));
        }
        data.forEach(reg => {
          result.push(new SensorModel(reg.Movil, reg.Sensor, reg.Descripcion,
            new Date(reg.Fecha), reg.Lectura, reg.Icono, reg.Lat, reg.Lng));
          switch (reg.Sensor) {
            case 1:
              estado.sirena = reg.Lectura;
              break;
            case 2:
              estado.puenteLuces = reg.Lectura;
              break;
            case 16:
              estado.igni = reg.Lectura;
              break;
            case 91:
              estado.conex = reg.Lectura;
              break;
          }
        });
        SensoresService.estadoMovil.set(idMovil, estado);
        return result;
      }, error => {
        if (error.status !== 404) {
          console.log('getUltimosSensoresMovil()' + error);
        } else {
          return result;
        }
      });
  }

}
