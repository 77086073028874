export class Utils {

    static formatDateTime(date: Date, humanReadable: boolean): string {
        let res = '';
        if (!humanReadable) {
            res = date.getFullYear().toString().padStart(4, '0') + '-';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '-';
            res = res + date.getDate().toString().padStart(2, '0') + ' ';
        } else {
            res = date.getDate().toString().padStart(2, '0') + '/';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '/';
            res = res + date.getFullYear().toString().padStart(4, '0') + ' ';
        }
        res = res + date.getHours().toString().padStart(2, '0') + ':';
        res = res + date.getMinutes().toString().padStart(2, '0') + ':';
        res = res + date.getSeconds().toString().padStart(2, '0');
        return res;
    }

    static formatDate(date: Date, humanReadable: boolean): string {
        let res = '';
        if (!humanReadable) {
            res = date.getFullYear().toString().padStart(4, '0') + '-';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '-';
            res = res + date.getDate().toString().padStart(2, '0') + ' ';
        } else {
            res = date.getDate().toString().padStart(2, '0') + '/';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '/';
            res = res + date.getFullYear().toString().padStart(4, '0') + ' ';
        }
        return res;
    }

    static formatDateTimeShort(date: Date, humanReadable: boolean): string {
        let res = '';
        if (!humanReadable) {
            res = (date.getFullYear() - 2000).toString().padStart(2, '0') + '-';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '-';
            res = res + date.getDate().toString().padStart(2, '0') + ' ';
        } else {
            res = date.getDate().toString().padStart(2, '0') + '/';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '/';
            res = res + (date.getFullYear() - 2000).toString().padStart(2, '0') + ' ';
        }
        res = res + date.getHours().toString().padStart(2, '0') + ':';
        res = res + date.getMinutes().toString().padStart(2, '0') + ':';
        res = res + date.getSeconds().toString().padStart(2, '0');
        return res;
    }

    static formatTime(date: Date): string {
        let res = date.getHours().toString().padStart(2, '0') + ':';
        res = res + date.getMinutes().toString().padStart(2, '0') + ':';
        res = res + date.getSeconds().toString().padStart(2, '0');
        return res;
    }

    static formatDateAMDhms(date: Date): string {
        let res = '';
        res = (date.getFullYear() - 2000).toString().padStart(2, '0');
        res = res + (date.getMonth() + 1).toString().padStart(2, '0');
        res = res + date.getDate().toString().padStart(2, '0');
        res = res + date.getHours().toString().padStart(2, '0');
        res = res + date.getMinutes().toString().padStart(2, '0');
        res = res + date.getSeconds().toString().padStart(2, '0');
        return res;
    }

    static capitalizeJSON(json: string): string {
        let res = '';
        let j = 0;
        const fields = json.split(',');
        fields.forEach(field => {
            const i = field.indexOf('"');
            const c1 = '"' + field.charAt(i + 1);
            field = field.replace(c1, c1.toUpperCase());
            res += (j > 0 ? ',' : '') + field;
            j++;
        });
        return res;
    }

    static getTime(): number {
        return new Date().getTime();
    }


}
