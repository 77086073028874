import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioModel } from './modelos/usuario-model';
import { RolModel } from './modelos/rol-model';
import { environment } from '../../environments/environment';
import { ApiService } from '../api/api-service';
import { Utils } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public static usuarios = new Map<number, UsuarioModel>();
  public static rols = new Map<number, RolModel>();

  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  async getUsuarios(): Promise<UsuarioModel[]> {
    const result: any = [];
    const response = await this.http.get(environment.urlApi + '/api/ambusat/usuarios').toPromise();
    if (response) {
      const data = JSON.parse(JSON.stringify(response));
      if (data !== undefined) {
        data.forEach(usu => {
          const newUsu = new UsuarioModel(usu.Id, usu.Nombre, usu.Email,
            usu.Password, LoginService.rols.get(usu.IdRol), usu.Activo, usu.Imagen, usu.Observ,
            usu.FAlta, usu.FMod, usu.FBaja);
          LoginService.usuarios.set(newUsu.id, newUsu);
          if (newUsu.fBaja === null) {
            result.push(newUsu);
          }
        });
      }
      return result;
    }
  }

  async getRols(): Promise<RolModel[]> {
    const result: any = [];
    const response = await this.http.get(environment.urlApi + '/api/ambusat/roles').toPromise();
    if (response) {
      const data = JSON.parse(JSON.stringify(response));
      if (data !== undefined) {
        data.forEach(usu => {
          const newRol = new RolModel(usu.Id, usu.Nombre, usu.FAlta, usu.FMod, usu.FBaja);
          LoginService.rols.set(newRol.id, newRol);
          if (newRol.fBaja === null) {
            result.push(newRol);
          }
        });
      }
      return result;
    }
  }

  // Almacena un usuario
  saveUsuario(usuario: UsuarioModel): Promise<UsuarioModel> {
    let newUsu: any = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    const s = Utils.capitalizeJSON(JSON.stringify(usuario));
    usuario.IdRol = usuario.rol.id;
    return this.http.post(environment.urlApi + '/api/ambusat/usuarios', Utils.capitalizeJSON(JSON.stringify(usuario)), httpOptions)
      .toPromise()
      .then(response => {
        const usu = JSON.parse(JSON.stringify(response));
        newUsu = new UsuarioModel(usu.Id, usu.Nombre, usu.Email,
          usu.Password, LoginService.rols.get(usu.IdRol), usu.Activo,
          usu.Imagen, usu.Observ, usu.FAlta, usu.FMod, usu.FBaja
        );
        // Actualizo los datos en cache
        LoginService.usuarios.set(newUsu.id, newUsu);
        return newUsu;
      }, error => {
        console.log(error);
        return newUsu;
      });
  }

  // Borra un usuario
  deleteUsuario(idUsuario: number): Promise<void> {
    return this.http.delete(environment.urlApi + '/api/ambusat/usuarios/' + idUsuario)
      .toPromise()
      .then(response => {
        // Actualizo los datos en cache
        const usu: UsuarioModel = LoginService.usuarios.get(idUsuario);
        usu.fBaja = new Date();
        usu.activo = false;
        LoginService.usuarios.set(idUsuario, usu);
        return;
      }, error => {
        console.log(error);
      });
  }

  // Permite recuperar la contraseña
  forgotPassw(email0: string): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    const usuario = {
      Email: email0,
      ApiToken: environment.apiToken
    };
    return this.http.post(environment.urlApiGes + '/api/usuarios/forgotpassw',
      Utils.capitalizeJSON(JSON.stringify(usuario)), httpOptions)
      .toPromise()
      .then(response => {
        return true;
      }, error => {
        console.log(error);
        return false;
      });
  }

  setUser(user: UsuarioModel) {
    sessionStorage.setItem('userGSC', JSON.stringify(user));
  }

  getUser(): UsuarioModel {
    const usu: UsuarioModel = JSON.parse(sessionStorage.getItem('userGSC'));
    return usu;
  }

  removeUser() {
    sessionStorage.removeItem('userGSC');
  }

  getEmpresa(): number {
    return environment.idEmpresa;
  }

}
