<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="'Procesando...'">
</jqxLoader>
<jqxWindow #form [width]=410 [height]="483" [zIndex]="99" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false">
    <div class="formHeader" style="overflow-x: hidden;">
        Asignación de recursos
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="float:left; margin-top: 6px; width: 100%;">
            <jqxGrid #grid [width]="'100%'" [source]="dataAdapter" [pagesize]="10" [columnsresize]="true" [localization]="localization" [filterable]="true" [showfilterrow]="true" [columns]="columns" [pageable]="true" [autoheight]="true" [sortable]="true" [altrows]="true"
                [enabletooltips]="true" [editable]="false" [scrollmode]="'deferred'" [editable]="true" (onCellendedit)="onCellEdit($event)">
            </jqxGrid>
        </div>
    </div>
</jqxWindow>