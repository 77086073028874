import { Injectable } from '@angular/core';
import { AlarmaModel } from './modelos/alarma-model';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RecursosService } from '../recursos/recursos.service';
import { AreasService } from '../areas/areas.service';
import { AreaZonaModel } from '../areas/modelos/area-zona-model';
import { Utils } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AlarmasService {
  public static alarmas = new Map<number, AlarmaModel>();

  constructor(private http: HttpClient) { }

  getAlarmas(movil: number): Promise<AlarmaModel[]> {
    const result: any = [];
    return this.http.get(environment.urlApi + '/api/ambusat/alarmas/movil/' + movil)
      .toPromise()
      .then(response => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach(elem => {
          let area = null;
          if (elem.IdArea > 0) {
            area = AreasService.areas.get(elem.IdArea);
          }
          // Si en el momento de cargar las alarmas las zonas de las áreas no
          // están en caché, ceo de momento una zona con el id de zona para
          // luego más tarde poder recuperarlo
          let zona = null;
          if (area !== null && elem.IdAreaZona > 0) {
            zona = AreasService.zonas.get(elem.IdAreaZona);
            if (zona === undefined) {
              zona = new AreaZonaModel(elem.IdAreaZona, '', null, 0, 0, 0, 0, '',
                null, null, null, null);
            }
          }
          const newAlarma = new AlarmaModel(elem.Id, elem.Nombre,
            RecursosService.moviles.get(elem.IdMovil), elem.Entrada,
            elem.Salida, elem.HoraEntrada !== null ? new Date('1900-01-01 ' + elem.HoraEntrada) : null,
            elem.HoraSalida !== null ? new Date('1900-01-01 ' + elem.HoraSalida) : null,
            elem.Repetir, elem.Dias, area, zona, elem.Radio, elem.Lat, elem.Lng,
            elem.Observ, elem.Emails, elem.FAlta, elem.FMod, elem.FBaja);
          AlarmasService.alarmas.set(newAlarma.id, newAlarma);
          result.push(newAlarma);
        });
        return result;
      }, error => {
        console.log('recursos::getAlarmas()' + error);
      });
  }

  // Almacena una alarma
  async saveAlarma(alarma: AlarmaModel): Promise<AlarmaModel> {
    let newAlarma: any = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    if (alarma.area !== null) {
      alarma.IdArea = alarma.area.id;
      if (alarma.areaZona !== null) {
        alarma.IdAreaZona = alarma.areaZona.id;
      } else {
        alarma.IdAreaZona = 0;
      }
    } else {
      alarma.IdArea = 0;
      alarma.IdAreaZona = 0;
    }
    alarma.IdMovil = alarma.movil.id;
    const response = await this.http.post(environment.urlApi + '/api/ambusat/alarmas',
      Utils.capitalizeJSON(JSON.stringify(alarma)), httpOptions).toPromise();
    if (response) {
      const alarma2 = JSON.parse(JSON.stringify(response));
      let area = null;
      if (alarma2.IdArea > 0) {
        area = AreasService.areas.get(alarma2.IdArea);
      }
      // Si en el momento de cargar las alarmas las zonas de las áreas no
      // están en caché, creo de momento una zona con el id de zona para
      // luego más tarde poder recuperarlo
      let zona = null;
      if (area !== null && alarma2.IdAreaZona > 0) {
        zona = AreasService.zonas.get(alarma2.IdAreaZona);
        if (zona === undefined) {
          zona = new AreaZonaModel(alarma2.IdAreaZona, '', null, 0, 0, 0, 0, '',
            null, null, null, null);
        }
      }
      newAlarma = new AlarmaModel(alarma2.Id, alarma2.Nombre,
        RecursosService.moviles.get(alarma2.IdMovil), alarma2.Entrada,
        alarma2.Salida, alarma2.HoraEntrada !== null ? new Date('1900-01-01 ' + alarma2.HoraEntrada) : null,
        alarma2.HoraSalida !== null ? new Date('1900-01-01 ' + alarma2.HoraSalida) : null,
        alarma2.Repetir, alarma2.Dias, area, zona, alarma2.Radio, alarma2.Lat, alarma2.Lng,
        alarma2.Observ, alarma2.Emails, alarma2.FAlta, alarma2.FMod, alarma2.FBaja);
      // Actualizo los datos en cache
      AlarmasService.alarmas.set(newAlarma.id, newAlarma);
      return newAlarma;
    }
  }

  // Borra una alarma
  deleteAlarma(alarma: number): Promise<void> {
    return this.http.delete(environment.urlApi + '/api/ambusat/alarmas/' + alarma)
      .toPromise()
      .then(response => {
        // Modifico la fecha de baja del registro en la cache
        const reg = AlarmasService.alarmas.get(alarma);
        if (reg !== undefined) {
          reg.fBaja = new Date();
        }
        AlarmasService.alarmas.set(alarma, reg);
        return;
      }, error => {
        console.log(error);
      });
  }


}
