export class Config {
  private static getUserData(key: string): any[] {
    let res: any = null;
    const user = JSON.parse(sessionStorage.getItem('userGSC'));
    const storeData = localStorage.getItem(user.id + '-' + key);
    if (storeData !== undefined && storeData !== null) {
      res = JSON.parse(storeData);
    }
    return res;
  }

  private static setUserData(key: string, data: any) {
    const user = JSON.parse(sessionStorage.getItem('userGSC'));
    localStorage.setItem(user.id + '-' + key, JSON.stringify(data));
  }

  static getSensorFilter(defVal: any): any[] {
    const res = this.getUserData('sensores-filtro');
    return res !== null ? res : defVal;
  }

  static storeSensorFilter(data: any) {
    this.setUserData('sensores-filtro', data);
  }

  static getCenterMap(defVal: any): any {
    const res = this.getUserData('mapa-centro');
    return res !== null ? res : defVal;
  }

  static storeCenterMap(data: any) {
    this.setUserData('mapa-centro', data);
  }

  static getZoomMap(defVal: any) {
    const res = this.getUserData('mapa-zoom');
    return res !== null ? res : defVal;
  }

  static storeZoomMap(data: any) {
    this.setUserData('mapa-zoom', data);
  }

  static getAreasFilter(defVal: any) {
    const res = this.getUserData('areas-filtro');
    return res !== null ? res : defVal;
  }

  static storeAreasFilter(data: any) {
    this.setUserData('areas-filtro', data);
  }

  static getMovilesFilter(defVal: any) {
    const res = this.getUserData('moviles-filtro');
    return res !== null ? res : defVal;
  }

  static storeMovilesFilter(data: any) {
    this.setUserData('moviles-filtro', data);
  }

  static getGruposFilter(defVal: any) {
    const res = this.getUserData('grupos-filtro');
    return res !== null ? res : defVal;
  }

  static storeGruposFilter(data: any) {
    this.setUserData('grupos-filtro', data);
  }

  static getMovilesSubFilter(defVal: any) {
    const res = this.getUserData('moviles-sub-filtro');
    return res !== null ? res : defVal;
  }

  static storeMovilesSubFilter(data: any) {
    this.setUserData('moviles-sub-filtro', data);
  }

  static getGrupoSelec(defVal: any) {
    const res = this.getUserData('grupo-selec');
    return res !== null ? res : defVal;
  }

  static storeGrupoSelec(data: any) {
    this.setUserData('grupo-selec', data);
  }

  static getMovilesSeguimiento(defVal: any) {
    const res = this.getUserData('moviles-seg');
    return res !== null ? res : defVal;
  }

  static storeMovilesSeguimiento(data: any) {
    this.setUserData('moviles-seg', data);
  }

  static getTraficMap(defVal: any) {
    const res = this.getUserData('mapa-trafico');
    return res !== null ? res : defVal;
  }

  static storeTraficMap(data: any) {
    this.setUserData('mapa-trafico', data);
  }



}
