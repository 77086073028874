import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';

import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { LoginService } from './login.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from '../utils/confirm-dialog/confirm-dialog.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    jqxWindowModule,
    jqxButtonModule,
    ConfirmDialogModule,
    BrowserModule,
    FormsModule
  ],
  exports: [
    LoginComponent
  ],
  providers: [
    LoginService
  ]
})
export class LoginModule { }
