import { RolModel } from './rol-model';

export class UsuarioModel {
    public id: number;
    public nombre: string;
    public email: string;
    public password: string;
    public rol: RolModel;
    public activo: boolean;
    public imagen: any;
    public observ: string;
    public fAlta: Date;
    public fMod: Date;
    public fBaja: Date;
    // Estos campos son sólo a efectos de llamadas a la API
    public IdRol: number;

    constructor(id: number, nombre: string, email: string, password: string, rol: RolModel,
        activo: boolean, imagen: string, observ: string, fAlta: Date, fMod: Date, fBaja: Date) {
        this.id = id;
        this.nombre = nombre;
        this.email = email;
        this.password = password;
        this.rol = rol;
        this.activo = activo;
        this.imagen = imagen;
        this.observ = observ;
        this.fAlta = fAlta;
        this.fMod = fMod;
        this.fBaja = fBaja;
    }
}
