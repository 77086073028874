import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { GrupoModel } from '../../modelos/grupo-model';
import { RecursosService } from '../../recursos.service';
import { MapsService } from '../../../maps/maps.service';
import { LoginService } from '../../../login/login.service';
import { Config } from '../../../utils/config';

@Component({
  selector: 'app-movil-filter',
  templateUrl: './movil-filter.component.html',
  styleUrls: ['./movil-filter.component.css']
})
export class MovilFilterComponent implements OnInit, AfterViewInit {
  @ViewChild('lbGrupos', { static: false }) lbGrupos: jqxListBoxComponent;
  private grupos: GrupoModel[] = [];
  private oldFilter = '';

  constructor(
    private recursosService: RecursosService,
    private mapService: MapsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updateContent();
  }

  // Rellena la lista de móviles
  updateContent() {
    // Me quedo con el filtro actual para comprobar al salir
    // si ha cambiado algo
    this.oldFilter = JSON.stringify(Config.getGruposFilter([{}]));
    this.recursosService.getGrupos().then(
      response => {
        this.grupos = response;
        if (this.grupos !== undefined && this.grupos != null) {
          // Recupero la selección anterior
          const filter = Config.getGruposFilter([{ id: -1 }]);
          let all = false;
          if (filter.find(s => s.id === -1) !== undefined) {
            all = true;
          }
          this.lbGrupos.beginUpdate();
          this.grupos.forEach(elem => {
            const item = {
              id: elem.id,
              // tslint:disable-next-line: max-line-length
              html: '<div style="height: 20px; float: left;">' +
                '<span style="float: left; font-size: 13px;">' + elem.nombre + '</span></div>',
              checked: all || filter.find(s => s.id === elem.id) !== undefined
            };
            this.lbGrupos.addItem(item);
          });
          this.lbGrupos.endUpdate();
        }
      },
      err => {
        console.log(err);
      });
  }

  onTodosClick(event: any) {
    if (this.lbGrupos !== undefined) {
      this.lbGrupos.checkAll();
    }
  }

  onNingunoClick(event: any) {
    if (this.lbGrupos !== undefined) {
      this.lbGrupos.uncheckAll();
    }
  }

  onClose() {
    if (this.lbGrupos !== undefined) {
      let grupoSelecSigueActivo = false;
      // Me quedo con el grupo que hay seleccionado actualmente
      const grupoSelec = Config.getGrupoSelec({ id: -1 });
      // Almaceno los grupos seleccionados
      const grupos: any[] = [];
      const selection: any[] = this.lbGrupos.getCheckedItems();
      if (selection.length > 0 && selection.length === this.grupos.length) {
        grupos.push({ id: -1 }); // Todos
      } else {
        selection.forEach(grupo => {
          grupos.push({ id: grupo.value.id });
        });
      }
      Config.storeGruposFilter(grupos);
      // Obtengo todos los móviles de todos los grupos seleccionados
      // y los meto en una tabla hash
      const movilesAsig = new Map<number, number>();
      let numProc = 0;
      selection.forEach(grupo => {
        if (grupoSelec.id === grupo.value.id) {
          grupoSelecSigueActivo = true;
        }
        this.recursosService.getGruposMoviles(grupo.value.id).then(
          resAsig => {
            if (resAsig !== undefined) {
              resAsig.forEach(idMovil => {
                movilesAsig.set(idMovil, idMovil);
              });
            }
            numProc++;
          },
          err => {
            numProc++;
            console.log(err);
          });
      });
      // Espero a recibir todos los móviles de todos los grupos seleccionados
      const timer = setInterval(() => {
        if (numProc >= selection.length) {
          clearInterval(timer);
          // Comparo el filtro actual con el anterior
          const filter = JSON.stringify(grupos);
          if (filter !== this.oldFilter) {
            // Guardo los códigos de móvil seleccionados
            const moviles: any[] = [];
            movilesAsig.forEach(idMovil => {
              moviles.push({ id: idMovil });
            });
            if (grupos.length > 0 && grupos[0].id === -1) {
              Config.storeMovilesFilter([{ id: -1 }]);
            } else {
              Config.storeMovilesFilter(moviles);
            }
            // Compruebo si el grupo que había seleccionado se ha quitado del filtro
            if (!grupoSelecSigueActivo) {
              // Reseteo el subfiltro a todos los móviles
              Config.storeMovilesSubFilter([{ id: -1 }]);
              // Pongo el grupo seleccionado a "todos"
              Config.storeGrupoSelec({ id: -1 });
            }
            // Si ha cambiado el filtro mando pintar de nuevo las posiciones de los móviles
            // y refresco la lista de móviles
            this.mapService.showMoviles(true);
            this.recursosService.refreshMoviles();
          }
        }
      }, 1000);
    }
  }

}
