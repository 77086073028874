import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { HttpClientModule } from '@angular/common/http';

import { RecursosService } from './recursos.service';
import { MovilesComponent } from './moviles/moviles.component';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';

import { MovilFilterComponent } from './moviles/movil-filter/movil-filter.component';
import { MovilesGroupComponent } from './moviles/moviles-group/moviles-grupo.component';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TabletsComponent } from './moviles/tablets/tablets.component';
import { FlotaComponent } from './moviles/flota/flota/flota.component';
import { jqxDragDropModule } from 'jqwidgets-ng/jqxdragdrop';

@NgModule({
  declarations: [MovilesComponent, MovilFilterComponent, MovilesGroupComponent, TabletsComponent, FlotaComponent],
  imports: [
    CommonModule,
    jqxListBoxModule,
    jqxButtonModule,
    jqxWindowModule,
    jqxLoaderModule,
    jqxGridModule,
    jqxDropDownListModule,
    jqxDragDropModule,
    HttpClientModule,
    BrowserModule,
    FormsModule
  ],
  exports: [
    MovilesComponent
  ],
  providers: [
    RecursosService
  ]
})
export class RecursosModule { }
