import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { environment } from 'src/environments/environment';
import { RecursosService } from '../../recursos.service';
import { MovilModel } from '../../modelos/movil-model';
import { ConfirmDialogService } from 'src/app/utils/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-tablets',
  templateUrl: './tablets.component.html',
  styleUrls: ['./tablets.component.css']
})
export class TabletsComponent implements OnInit, AfterViewInit {
  @ViewChild('lbTablets', { static: false }) lbTablets: jqxListBoxComponent;
  @ViewChild('lbMoviles', { static: false }) lbMoviles: jqxListBoxComponent;

  private tabletSelect: MovilModel = null;
  private notifyChange = true;

  constructor(
    private recursosService: RecursosService,
    private confirmDialogService: ConfirmDialogService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updateContent();
  }

  // Rellena la lista de móviles
  updateContent() {
    // Relleno la lista de tablets
    this.recursosService.getMoviles(environment.idEmpresa).then(
      response => {
        if (response !== undefined) {
          this.lbTablets.beginUpdate();
          response.forEach(elem => {
            if (elem.gls === 'AN') {
              const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
                'src="assets/images/tablet.png"/>';
              const item = {
                id: elem.id,
                html: '<div style="height: 20px; float: left;">' + icono +
                  '<span style="float: left; font-size: 13px;">' + elem.nombre + '</span></div>',
                checked: false
              };
              this.lbTablets.addItem(item);
            }
          });
          this.lbTablets.endUpdate();
        }
      },
      err => {
        console.log(err);
      });
    // Relleno la lista de moviles 
    this.recursosService.getMoviles(environment.idEmpresa).then(
      response => {
        if (response !== undefined) {
          this.lbMoviles.beginUpdate();
          response.forEach(elem => {
            if (elem.gls !== 'AN') {
              const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
                'src="data:image/jpg;base64,' + elem.icono + '"/>';
              const item = {
                id: elem.id,
                html: '<div style="height: 20px; float: left;">' + icono +
                  '<span style="float: left; font-size: 13px;">' + elem.nombre + '</span></div>',
                checked: false
              };
              this.lbMoviles.addItem(item);
            }
          });
          this.lbMoviles.endUpdate();
        }
      },
      err => {
        console.log(err);
      });
  }

  // Cada vez que se selecciona una tablet
  onSelectTablet(event: any): void {
    const item = event.args.item;
    if (item !== null) {
      this.tabletSelect = RecursosService.moviles.get(item.value.id);
      this.showMovilesTablet();
    }
  }

  // Muestra el movil seleccionado para la tableta en cuestión
  showMovilesTablet() {
    const movilTablet = parseInt(this.tabletSelect.codigoExt);
    const moviles: any[] = this.lbMoviles.getItems();
    if (moviles.length > 0) {
      this.notifyChange = false;
      let itemSelec = -1;
      let i = 0;
      moviles.forEach(item => {
        if (item.value.id === movilTablet) {
          this.lbMoviles.checkItem(item);
          itemSelec = i;
        } else {
          this.lbMoviles.uncheckItem(item);
        }
        i++;
      });
      if(itemSelec > -1) {
        this.lbMoviles.ensureVisible(itemSelec);
        this.lbMoviles.selectIndex(itemSelec);
      }
      this.notifyChange = true;
    }
  }

  // Cada vez que se selecciona un móvil
  onSelectMovil(event: any): void {
    if (this.notifyChange) {
      const item = event.args.item;
      if (item !== null && this.tabletSelect !== null) {
        if (item.checked) {
          let asociar: boolean = true;
          for (let movil of RecursosService.moviles.values()) {
            if (movil.codigoExt === '' + item.value.id) {
              asociar = false;
              this.confirmDialogService.confirmThis(
                'Este móvil ya lo tiene asignado la tableta ' + movil.nombre +
                ". Seguro que quiere reasignarlo?", () => {
                  this.asociaMovil('' + item.value.id);
                }, () => {
                  this.notifyChange = false;
                  this.lbMoviles.uncheckItem(item);
                  this.notifyChange = true;
                });
            }
          }
          if (asociar) {
            this.asociaMovil('' + item.value.id);
          }

          // if (this.tabletSelect.codigoExt !== ('' + item.value.id)) {
          //   this.recursosService.setMovilAsociado(this.tabletSelect.id, '' + item.value.id);
          //   for (let movil of RecursosService.moviles.values()) {
          //     if (movil.codigoExt === '' + item.value.id) {
          //       movil.codigoExt = '';
          //       RecursosService.moviles.set(movil.id, movil);
          //     }
          //   }
          //   this.tabletSelect.codigoExt = '' + item.value.id;
          //   RecursosService.moviles.set(this.tabletSelect.id, this.tabletSelect);
          //   this.showMovilesTablet();
          // }
        } else {
          if (this.tabletSelect.codigoExt.length > 0) {
            this.recursosService.setMovilAsociado(this.tabletSelect.id, '');
            this.tabletSelect.codigoExt = '';
            RecursosService.moviles.set(this.tabletSelect.id, this.tabletSelect);
          }
        }
      }
    }
  }

  private asociaMovil(movilId: string) {
    if (this.tabletSelect.codigoExt !== movilId) {
      this.recursosService.setMovilAsociado(this.tabletSelect.id, movilId);
      for (let movil of RecursosService.moviles.values()) {
        if (movil.codigoExt === movilId) {
          movil.codigoExt = '';
          RecursosService.moviles.set(movil.id, movil);
        }
      }
      this.tabletSelect.codigoExt = movilId;
      RecursosService.moviles.set(this.tabletSelect.id, this.tabletSelect);
      this.showMovilesTablet();
    }
  }

}
