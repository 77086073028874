import { AreaZonaModel } from './area-zona-model';

export class AreaZonaPuntosModel {
    id: number;
    idZona: number;
    orden: number;
    lat: number;
    lng: number;

    constructor(id: number, idZona: number, orden: number, lat: number, lng: number) {
        this.id = id;
        this.idZona = idZona;
        this.orden = orden;
        this.lat = lat;
        this.lng = lng;
    }
}
