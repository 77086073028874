import {
  Component, OnInit, ViewChild, ViewContainerRef, ComponentRef,
  ComponentFactoryResolver,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { RecursosService } from '../recursos.service';
import { MovilModel } from '../modelos/movil-model';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { MapsService } from '../../maps/maps.service';
import { LoginService } from '../../login/login.service';
import { ReproductorComponent } from '../../reproductor/reproductor.component';
import { Config } from '../../utils/config';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { GrupoModel } from '../modelos/grupo-model';
import { SensoresService } from '../../sensores/sensores.service';
import { EstadoMovilModel } from '../modelos/estado-movil-model';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-moviles',
  templateUrl: './moviles.component.html',
  styleUrls: ['./moviles.component.css']
})

export class MovilesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('jqxListBox', { static: false }) lbMoviles: jqxListBoxComponent;
  @ViewChild('movilesContainer', { read: ViewContainerRef }) movilesContainer;
  @ViewChild('cbGrupos') cbGrupos: jqxDropDownListComponent;

  private INTERVAL_MOVILES = 60000; // Intervalo de refresco
  private moviles: MovilModel[] = [];
  private timerMoviles = null;
  private grupos: GrupoModel[] = [];
  private updateGroups = false;
  public cartoActiva = 0;
  private lastItem: any = null;
  public tipoGes = environment.tipoGes;
  private oldTab = 0;
  private timeCheck = 0;
  public static movilSelec = 0;

  constructor(
    private recursosService: RecursosService,
    private sensoresService: SensoresService,
    private mapService: MapsService,
    private loginService: LoginService,
    private resolver: ComponentFactoryResolver,
    private mapsService: MapsService,
    private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    // Eventos a los que se subscrive
    this.subscribeRefreshMoviles();
    this.subscribeChangeTabCarto();
  }

  ngAfterViewInit(): void {
    // Timer que atualiza la lista de móviles
    this.getGroups();
    this.getMoviles();
    this.timerMoviles = setInterval(() => {
      this.getGroups();
      this.getMoviles();
    }, this.INTERVAL_MOVILES);
  }

  ngOnDestroy(): void {
    if (this.timerMoviles !== undefined && this.timerMoviles != null) {
      clearInterval(this.timerMoviles);
    }
  }

  // Obtiene los móviles de la empresa
  getMoviles() {
    this.moviles = [];
    this.recursosService.getMoviles(this.loginService.getEmpresa()).then(
      response => {
        this.moviles = response;
        // Recupero el estado de los sensores y luego se actualiza la lista de móviles
        this.getSensors();
      },
      err => {
        console.log(err);
      });
  }

  // Actualiza la lista de móviles
  updateList() {
    let index = 0;
    let numItems = 0;
    if (this.oldTab === 1) {
      this.lbMoviles.clear();
    }
    if (this.lbMoviles.getItems() !== undefined) {
      numItems = this.lbMoviles.getItems().length;
    }
    if (numItems < 1) {
      this.lbMoviles.beginUpdate();
    }
    // Recupero el filtro de móviles (-1 = todos mientras no se establece un filtro)
    const filter = Config.getMovilesFilter([{ id: -1 }]);
    let all = false;
    if (filter.find(s => s.id === -1)) {
      all = true;
    }
    // Recupero el subfiltro de móviles (-1 = todos mientras no se establece un subfiltro)
    const subFilter = Config.getMovilesSubFilter([{ id: -1 }]);
    let allSub = false;
    if (subFilter.find(s => s.id === -1)) {
      allSub = true;
    }
    let movilesSeg = null;
    if (this.cartoActiva === 1) {
      movilesSeg = Config.getMovilesSeguimiento([{}]);
      this.lbMoviles.clear();
      numItems = 0;
    }
    this.oldTab = this.cartoActiva;
    this.moviles.forEach(movil => {
      if ((all || filter.find(s => s.id === movil.id)) && movil.gls !== 'AN') {
        if (allSub || subFilter.find(s => s.id === movil.id)) {
          if (this.cartoActiva !== 1 || movilesSeg.find(s => s.id === movil.id)) {
            const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
              'src="data:image/jpg;base64,' + movil.icono + '"/>';
            let estado = SensoresService.estadoMovil.get(movil.id);
            if (estado === undefined) {
              estado = new EstadoMovilModel(movil.id, false, false, false, false, null);
            }
            let conectado = '';
            if (movil.conectado) {
              conectado = '<img width="8" height="8" style="float: left; margin-right: 0px;" ' +
                'src="assets/images/recursos/moviles/conectado.png"/>';
            } else {
              conectado = '<img width="8" height="8" style="float: left; margin-right: 0px;" ' +
                'src="assets/images/recursos/moviles/desconectado.png"/>';
            }
            let sirena = '';
            if (estado.sirena) {
              sirena = '<img width="15" height="15" style="float: left; margin-right: 2px;" ' +
                'src="assets/images/sirena-on.png"/>';
            } else {
              sirena = '<img width="15" height="15" style="float: left; margin-right: 2px;" ' +
                'src="assets/images/sirena-off.png"/>';
            }
            let luces = '';
            if (estado.puenteLuces) {
              luces = '<img width="15" height="15" style="float: left; margin-right: 2px;" ' +
                'src="assets/images/luces-on.png"/>';
            } else {
              luces = '<img width="15" height="15" style="float: left; margin-right: 2px;" ' +
                'src="assets/images/luces-off.png"/>';
            }
            const item = {
              id: movil.id,
              html: '<div style="height: 20px; float: left;">' + icono + conectado + sirena + luces +
                '<span style="float: left; font-size: 13px;">' + movil.nombre + '</span></div>'
            };
            if (numItems < 1 || index >= numItems) {
              this.lbMoviles.addItem(item);
              const items = this.lbMoviles.getItems();
              items[items.length - 1].checked = true;
            } else {
              if (this.lbMoviles.getItem(index).html !== item.html) {
                this.lbMoviles.updateAt(item, index);
              }
            }
            index++;
          }
        }
      }
    });
    if (numItems < 1) {
      this.lbMoviles.endUpdate();
    }
  }

  // Obtiene los últimos valores de los sensores de cada móvil
  getSensors() {
    if (this.moviles !== undefined && this.moviles !== null) {
      this.moviles.forEach(movil => {
        this.sensoresService.getUltimosSensoresMovil(movil.id).then(
          response => {
            // Recupero los últimos datos de gestión (estado, número de personas, etc...)
            // Sólo en caso de TSNU
            if (environment.tipoGes == 'TSNU') {
              // TODO: Esto es muy lento y se carga la página!!!
              //this.getGestionState(); 
              this.updateList();
            } else {
              // Actualizo el estado de conexión de todos los móviles
              this.updateList();
            }
          },
          err => {
            this.updateList();
            console.log(err);
          });
      });
    }
  }

  // Obtiene los últimos datos de gestión (estados, numero de pensonas, etc...)
  getGestionState() {
    if (this.moviles !== undefined && this.moviles !== null) {
      this.moviles.forEach(movil => {
        this.recursosService.getEstadoMovilesGestion().then(
          response => {
            this.updateList();
          },
          err => {
            console.log(err);
          });
      });
    }
  }

  // Permite mostrar u ocultar las posiciones de los moviles
  subscribeRefreshMoviles(): void {
    this.recursosService.refreshMovilesEmiter.subscribe(event => {
      clearInterval(this.timerMoviles);
      this.getGroups();
      this.lbMoviles.source([]);
      this.getMoviles();
      this.timerMoviles = setInterval(() => {
        this.getGroups();
        this.getMoviles();
      }, this.INTERVAL_MOVILES);
    });
  }

  subscribeChangeTabCarto(): void {
    this.mapsService.changeTabsEmiter.subscribe(tab => {
      this.cartoActiva = tab;
      this.updateList();
    });
  }

  // Cada vez que se selecciona un móvil en la lista
  onSelect(event: any): void {
    const item = event.args.item;
    if (item !== null) {
      if (item !== this.lastItem) {
        item.checked = !item.checked;
      }
      this.lastItem = item;
      this.mapService.centerMovil(item.value.id);
      MovilesComponent.movilSelec = item.value.id;
    }
  }

  onCheck(event: any): void {
    const item = event.args.item;
    if (item !== null && Utils.getTime() - this.timeCheck > 2000) {
      this.mapService.centerMovil(item.value.id);
    }
  }

  // Reproducir una ruta
  onRutaClick(event: any) {
    if (this.lbMoviles !== undefined) {
      const item = this.lbMoviles.getSelectedItem();
      if (item !== undefined && item !== null) {
        const component: ComponentRef<ReproductorComponent> = this.movilesContainer.createComponent(
          this.resolver.resolveComponentFactory(ReproductorComponent));
        this.moviles.forEach(movil => {
          if (movil.id === item.value.id) {
            component.instance.init(movil);
          }
        });
      }
    }
  }

  // Rellena la lista de grupos
  getGroups() {
    this.recursosService.getGrupos().then(
      response => {
        this.grupos = response;
        this.updateGroups = true;
        this.cbGrupos.clear();
        this.cbGrupos.addItem({ label: 'Todos los vehículos', value: -1 });
        if (this.grupos !== undefined && this.grupos != null) {
          // Recupero la selección anterior
          const filter = Config.getGruposFilter([{ id: -1 }]);
          let all = false;
          if (filter.find(s => s.id === -1) !== undefined) {
            all = true;
          }
          // Recupero el último grupo seleccionado
          const grupoSelec = Config.getGrupoSelec({ id: -1 });
          let i = 1;
          this.grupos.forEach(grupo => {
            if (all || filter.find(s => s.id === grupo.id) !== undefined) {
              this.cbGrupos.addItem({
                label: grupo.nombre,
                value: grupo.id
              });
              if (grupoSelec.id === grupo.id) {
                this.cbGrupos.selectIndex(i);
              }
              i++;
            }
          });
          if (this.cbGrupos.getSelectedIndex() < 0) {
            this.cbGrupos.selectIndex(0);
            Config.storeMovilesSubFilter([{ id: -1 }]);
          }
        } else {
          this.cbGrupos.selectIndex(0);
          Config.storeMovilesSubFilter([{ id: -1 }]);
        }
      },
      err => {
        console.log(err);
      });
  }

  onChangeGrupos(event: any) {
    if (this.updateGroups) {
      this.updateGroups = false;
    } else {
      if (event.args.item.value > -1) {
        this.recursosService.getGruposMoviles(event.args.item.value).then(
          resAsig => {
            // Guardo los códigos de móvil del grupo seleccionado
            const moviles: any[] = [];
            if (resAsig !== undefined) {
              resAsig.forEach(idMovil => {
                moviles.push({ id: idMovil });
              });
            }
            Config.storeMovilesSubFilter(moviles);
            Config.storeGrupoSelec({ id: event.args.item.value });
            // Mando pintar de nuevo las posiciones de los móviles
            // y refresco la lista de móviles
            this.mapService.showMoviles(true);
            this.recursosService.refreshMoviles();
          },
          err => {
            console.log(err);
          });
      } else {
        Config.storeGrupoSelec({ id: -1 });
        // Todos los móviles
        Config.storeMovilesSubFilter([{ id: -1 }]);
        // Mando pintar de nuevo las posiciones de los móviles
        // y refresco la lista de móviles
        this.mapService.showMoviles(true);
        this.recursosService.refreshMoviles();
      }
    }
  }

  onSeguirClick(event: any) {
    if (this.lbMoviles !== undefined) {
      const items = this.lbMoviles.getCheckedItems();
      if (items !== undefined && items !== null) {
        items.forEach(item => {
          this.mapService.addMovilSegimiento(RecursosService.moviles.get(item.value.id));
        });
      }
    }
  }

  onNoSeguirClick(event: any) {
    if (this.lbMoviles !== undefined) {
      const items = this.lbMoviles.getCheckedItems();
      if (items !== undefined && items !== null) {
        items.forEach(item => {
          this.mapService.removeMovilSegimiento(RecursosService.moviles.get(item.value.id));
        });
        this.updateList();
      }
    }
  }

  onNavegarClick(event: any) {
    if (this.lbMoviles !== undefined) {
      const item = this.lbMoviles.getSelectedItem();
      if (item !== undefined && item !== null) {
        this.mapService.navegarMovil(item.value.id);
      } else {
        this.toastrService.warning('Tiene que seleccionar un móvil de la lista', 'ATENCION');
      }
    }
  }

  onLimpiarClick(event: any) {
    this.mapService.borrarItinerario();
  }

  onNavegarCercanoClick(event: any) {
    if (this.lbMoviles !== undefined) {
      const items = this.lbMoviles.getCheckedItems();
      const moviles: number[] = [];
      if (items !== undefined && items !== null) {
        items.forEach(item => {
          moviles.push(item.value.id);
        });
      }
      if (moviles.length > 0) {
        this.mapsService.navegarMovilCercano(moviles);
      } else {
        this.toastrService.warning('Tiene que chequear al menos un móvil de la lista', 'ATENCION');
      }
    }
  }

  onCentrarClick(event: any) {
    if (this.lbMoviles !== undefined) {
      const items = this.lbMoviles.getCheckedItems();
      if (items !== undefined && items !== null) {
        const moviles: number[] = [];
        items.forEach(item => {
          moviles.push(item.value.id);
        });
        this.mapService.centerMoviles(moviles);
      }
    }
  }

  onTodosClick(event: any) {
    if (this.lbMoviles !== undefined) {
      this.timeCheck = Utils.getTime();
      this.lbMoviles.checkAll();
    }
  }

  onNingunoClick(event: any) {
    if (this.lbMoviles !== undefined) {
      this.timeCheck = Utils.getTime();
      this.lbMoviles.uncheckAll();
    }
  }

  onMouseDown(event: any) {
  }

  onMouseUp(event: any) {
  }

  onMouseOut(event: any) {
  }

  onMouseEnter(event: any) {
  }

  onDragStart(event: any): void {
  }

  dragStart(item: any): boolean {
    return true;
  }

}
