import { MovilModel } from '../../recursos/modelos/movil-model';
import { SensorMovilidadModel } from './sensor-movilidad-model';

export class InformeMovilidadModel {
    periodo: number; // 1=diario, 2=semanal, 3=mensual, 4=anual
    movil: MovilModel;
    fechaIni: Date;
    fechaFin: Date;
    distancia: number;
    tiempoIgniOn: string;
    tiempoIgniOff: string;
    tiempoTotal: string;
    tiempoRalenti: string;
    velMaxima: number;
    velMedia: number;
    sirena: SensorMovilidadModel;
    pLuces: SensorMovilidadModel;

    constructor(periodo: number, movil: MovilModel, fechaIni: Date, fechaFin: Date,
        distancia: number, tiempoIgniOn: string, tiempoIgniOff: string, tiempoTotal: string,
        tiempoRalenti: string, velMaxima: number, velMedia: number,
        sirena: SensorMovilidadModel, pLuces: SensorMovilidadModel) {
        this.periodo = periodo;
        this.movil = movil;
        this.fechaIni = fechaIni;
        this.fechaFin = fechaFin;
        this.distancia = distancia / 1000.0;
        this.tiempoIgniOn = tiempoIgniOn.replace('.', 'd ');
        this.tiempoIgniOff = tiempoIgniOff.replace('.', 'd ');
        this.tiempoTotal = tiempoTotal.replace('.', 'd ');
        this.tiempoRalenti = tiempoRalenti.replace('.', 'd ');
        this.velMaxima = velMaxima;
        this.velMedia = velMedia;
        this.sirena = sirena;
        this.pLuces = pLuces;
    }
}
