<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="'Procesando...'">
</jqxLoader>
<div *ngIf="mostrarMainForm">
    <jqxWindow #formAreas [width]="800" [height]="590" [zIndex]="50" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false">
        <div class="formHeader" style="overflow-x: hidden;">
            Áreas de gestión
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="float: left; margin-top: 2px; clear: both;">
                <div style="float:left; clear: both;">
                    <jqxDropDownList #cbTiposFilter style="float:left;" [width]="343" [height]="26" [selectedIndex]="0" (onSelect)="onSelectTiposAreas($event)">
                    </jqxDropDownList>
                </div>
            </div>
            <div style="float:left; margin-top: 6px; width: 100%;">
                <jqxGrid #grid [width]="'100%'" [source]="dataAdapterAreas" [pagesize]="10" [columnsresize]="true" [localization]="localization" [filterable]="true" [showfilterrow]="true" [columns]="columnsAreas" [pageable]="true" [autoheight]="true" [sortable]="true"
                    [altrows]="true" [enabletooltips]="true" [editable]="false" [scrollmode]="'deferred'" (onRowclick)="onRowAreaSelect($event)">
                </jqxGrid>
            </div>
            <div style="float: left; margin-top: 6px;">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mapa/ver.png"' (onClick)="onCentrarAreaClick($event)">
                    Centrar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"' (onClick)="onCrearAreaClick($event)" [hidden]="!canEdit || tipoGes==='TSNU'">
                    Crear
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"' (onClick)="onEditarAreaClick($event)">
                    Editar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"' (onClick)="onBorrarAreaClick($event)" [hidden]="!canEdit || tipoGes==='TSNU'">
                    Borrar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 40px;" [width]='160' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/area.png"' (onClick)="onZonasDeControlClick($event)">
                    Zonas de control
                </jqxButton>
            </div>
            <div style="float: left; margin-top: 4px;" [hidden]="!canEdit || tipoGes==='TSNU'">
                Importar CSV<br>
                <jqxDropDownList #cbTiposImport style="float:left; margin-right: 10px;" [width]="343" [height]="26" [selectedIndex]="0" [enableBrowserBoundsDetection]="'true'" (onSelect)="onSelectTiposAreaImport($event)">
                </jqxDropDownList>
                <input #fileUpload type="file" class="upload" (change)="onImportarClick($event.target.files)">
            </div>
        </div>
    </jqxWindow>
</div>

<div *ngIf="mostrarEditForm">
    <jqxWindow #formEdit [width]="460" [height]="280" [zIndex]="100" [autoOpen]="true" [isModal]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onCloseEdit()" (onOpen)="onOpenEditArea()">
        <div class="formHeader" style="overflow-x: hidden;">
            Edición de áreas
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="margin-top: 5px; clear: both;">
                Tipo
                <jqxDropDownList #cbTipos [width]="250" [height]="25" [selectedIndex]="0" (onSelect)="onChangeTipoArea($event)">
                </jqxDropDownList>
            </div>
            <div style="margin-top: 5px; float:left; clear:both;">
                Nombre
            </div>
            <div style="float:left; clear:both;">
                <input #ebNombre [(ngModel)]="areaEdit.nombre" type='text' style="width: 430px; " [disabled]="!canEdit || tipoGes==='TSNU'" />
            </div>
            <div style="margin-top: 5px; float:left; clear:both;">
                Observaciones
            </div>
            <div style="float:left; clear: both;">
                <input [(ngModel)]="areaEdit.observ" type='text' style="width: 430px;" [disabled]="!canEdit || tipoGes==='TSNU'" />
            </div>
            <div style="margin-top: 5px; float:left; clear:both;">
                Radio de acción (metros)
            </div>
            <div style="float:left; clear: both;">
                <input [(ngModel)]="areaEdit.radio" type='number' style="width: 180px;" min="0" max="50000" [disabled]="!canEdit" />
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both; ">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarAreaClick($event)" [disabled]="!canEdit">
                    Guardar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/centrar.png"' (onClick)="onPosicionarAreaClick($event)" [hidden]="!canEdit || tipoGes!=='TSNU'">
                    Posicionar
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>

<div *ngIf="mostrarZonas">
    <jqxWindow #formZonas [width]="712" [height]="515" [zIndex]="50" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onCloseZonas()" (onOpen)="onOpenZonas()">
        <div class="formHeader" style="overflow-x: hidden;">
            Zonas de control
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="float:left; margin-top: 6px; width: 100%;">
                <jqxGrid #gridZonas [width]="'100%'" [source]="dataAdapterZonas" [pagesize]="10" [columnsresize]="true" [localization]="localization" [filterable]="true" [showfilterrow]="true" [columns]="columnsZonas" [pageable]="true" [autoheight]="true" [sortable]="true"
                    [altrows]="true" [enabletooltips]="true" [editable]="false" [scrollmode]="'deferred'" (onRowclick)="onRowZonaSelect($event)">
                </jqxGrid>
            </div>
            <div style="float: left; margin-top: 6px;">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mapa/ver.png"' (onClick)="onCentrarZonaClick($event)">
                    Ver
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"' (onClick)="onCrearZonaClick($event)" [hidden]="!canEdit">
                    Crear
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"' (onClick)="onEditarZonaClick($event)" [hidden]="!canEdit">
                    Editar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"' (onClick)="onBorrarZonaClick($event)" [hidden]="!canEdit">
                    Borrar
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>

<div *ngIf="mostrarEditZona">
    <jqxWindow #formEditZona [width]="460" [height]="280" [zIndex]="100" [autoOpen]="true" [isModal]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onCloseEditZona()" (onOpen)="onOpenEditZona()" (onExpand)="onExpandZonas()">
        <div class="formHeader" style="overflow-x: hidden;">
            Edición de zonas de control
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="margin-top: 5px; clear: both;">
                Tipo
                <jqxDropDownList #cbTiposZonas [width]="250" [height]="25" [selectedIndex]="0" (onSelect)="onChangeTipoZona($event)">
                </jqxDropDownList>
            </div>
            <div style="margin-top: 5px; float:left; clear:both;">
                Nombre
            </div>
            <div style="float:left; clear:both;">
                <input #ebNombre [(ngModel)]="zonaEdit.nombre" type='text' style="width: 430px; " />
            </div>
            <div style="margin-top: 5px; float:left; clear:both;">
                Observaciones
            </div>
            <div style="float:left; clear: both;">
                <input [(ngModel)]="zonaEdit.observ" type='text' style="width: 430px;" />
            </div>
            <div *ngIf="zonaEdit.tipo==0">
                <div style="margin-top: 5px; float:left; clear:both;">
                    Radio de acción (metros)
                </div>
                <div style="float:left; clear: both;">
                    <input [(ngModel)]="zonaEdit.radio" type='number' style="width: 180px;" min="0" max="50000" />
                </div>
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both; ">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarZonaClick($event)" [disabled]="!canEdit">
                    Guardar
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/zonas.png"' (onClick)="onDibujarZonaClick($event)" [disabled]="!canEdit">
                    Dibujar
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>