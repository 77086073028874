import { PosicionModel } from "src/app/posiciones/modelos/posicion-model";

export class EstadoMovilModel {
    idMovil: number;
    conex: boolean;
    igni: boolean;
    sirena: boolean;
    puenteLuces: boolean;
    lastPos: PosicionModel;

    constructor(idMovil: number, conex: boolean, igni: boolean, sirena: boolean, puenteLuces: boolean, lastPos: PosicionModel) {
        this.idMovil = idMovil;
        this.conex = conex;
        this.sirena = sirena;
        this.puenteLuces = puenteLuces;
        this.lastPos = lastPos;
    }
}
