import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { AreaTipoModel } from '../modelos/area-tipo-model';
import { AreasService } from '../areas.service';
import { Config } from '../../utils/config';
import { LoginService } from '../../login/login.service';
import { MapsService } from '../../maps/maps.service';

@Component({
  selector: 'app-areas-filter',
  templateUrl: './areas-filter.component.html',
  styleUrls: ['./areas-filter.component.css']
})
export class AreasFilterComponent implements OnInit, AfterViewInit {
  @ViewChild('lbTipos', { static: false }) lbTipos: jqxListBoxComponent;
  private tipos: AreaTipoModel[] = [];
  private oldFilter = '';

  constructor(
    private areasService: AreasService,
    private loginService: LoginService,
    private mapService: MapsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updateContent();
  }

  getPos(): any {
    const footer = document.getElementById('headerLayout').clientHeight;
    return {
      x: 0,
      y: document.getElementById('headerLayout').clientHeight
    };
  }

  // Rellena la lista de móviles
  updateContent() {
    // Me quedo con el filtro actual para comprobar al salir
    // si ha cambiado algo
    this.oldFilter = JSON.stringify(Config.getAreasFilter([{}]));
    this.areasService.getAreasTipos().then(
      response => {
        this.tipos = response;
        if (this.tipos !== undefined && this.tipos != null) {
          this.lbTipos.beginUpdate();
          // Recupero el filtro
          const select = Config.getAreasFilter([{}]);
          this.lbTipos.beginUpdate();
          this.tipos.forEach(elem => {
            const icono = '<img width="20" height="20" style="float: left; margin-right: 2px;" ' +
              'src="data:image/jpg;base64,' + elem.imagen + '"/>';
            const item = {
              id: elem.id,
              html: '<div style="height: 20px; float: left;">' +
                icono + '<span style="float: left; font-size: 13px;">' +
                elem.nombre + '</span></div>',
              checked: select.find(s => s.id === elem.id) !== undefined
            };
            this.lbTipos.addItem(item);
          });
          this.lbTipos.endUpdate();
        }
      },
      err => {
        console.log(err);
      });
  }

  onTodosClick(event: any) {
    if (this.lbTipos !== undefined) {
      this.lbTipos.checkAll();
    }
  }

  onNingunoClick(event: any) {
    if (this.lbTipos !== undefined) {
      this.lbTipos.uncheckAll();
    }
  }

  onClose() {
    if (this.lbTipos !== undefined) {
      // Almaceno los sensores seleccionados
      const tipos: any[] = [];
      const selection: any[] = this.lbTipos.getCheckedItems();
      selection.forEach(elem => {
        tipos.push({ id: elem.value.id });
      });
      Config.storeAreasFilter(tipos);
      // Comparo el filtro actual con el anterior
      const filter = JSON.stringify(tipos);
      if (filter !== this.oldFilter) {
        // Si ha cambiado el filtro mando pintar de nuevo las areas
        this.mapService.showAreas(true);
      }
    }
  }

}
