import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformeFlotaComponent } from './informe-flota/informe-flota.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { InformeMovilidadComponent } from './informe-movilidad/informe-movilidad.component';
import { PeriodoSelectModule } from '../utils/periodo-select/periodo-select.module';
import { InformesService } from './informes-service';
import { HttpClientModule } from '@angular/common/http';
import { InformeDetalleComponent } from './informe-detalle/informe-detalle.component';
import { InformeProgComponent } from './informe-prog/informe-prog.component';
import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

@NgModule({
  declarations: [InformeFlotaComponent, InformeMovilidadComponent, InformeDetalleComponent, InformeProgComponent],
  imports: [
    jqxButtonModule,
    jqxLoaderModule,
    jqxWindowModule,
    jqxGridModule,
    jqxDropDownListModule,
    jqxListBoxModule,
    PeriodoSelectModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    BrowserModule
  ],
  exports: [
    InformeFlotaComponent,
    InformeMovilidadComponent
  ],
  providers: [
    InformesService
  ]
})
export class InformesModule { }
